import React from "react";
import classes from "./SearchBar.module.scss";
import { useState } from "react";
import { useEffect } from "react";

const SearchBar = ({ onChange = () => { }, debounceTime = 300, theme='light' }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const debouncedChange = setTimeout(() => {
      onChange(inputValue)
    }, debounceTime);
    return () => clearTimeout(debouncedChange);
  }, [inputValue, onChange, debounceTime]);

  const iconClick = (event) => {
    event.stopPropagation();
    if (inputValue !== '') {
      setInputValue('');
    }
  }

  return (
    <div className={classes.searchBar}>
      <input
        className={`${classes.customInput} ${classes[theme]}`}
        placeholder="Search"
        value={inputValue}
        onChange={(event)=>setInputValue(event.target.value)}
        autoComplete="off" 
        />
      <span onClick={iconClick} className={(inputValue === '') ? `icon-search  ${classes.searchIcon}` : `icon-close  ${classes.clearIcon}`}></span>
    </div>
  )
}


export default SearchBar;