import { useState } from "react";
import classes from './Menu.module.scss';
import ClickOutsideComponent from "../UI/ClickOutsideComponent";

const Menu = ({ buttonElement, children }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const toggleMenu = (e) => {
    setAnchorElement(e?.currentTarget);
  }

  const open = Boolean(anchorElement);

  const clickOutside = () => {
    toggleMenu(null)
  }

  return (
    <div className={classes.wrapper}>
      <ClickOutsideComponent handleClickOutside={clickOutside}>
        <div onClick={toggleMenu}>
          {buttonElement}
        </div>
        <div className={`${classes.menuContent} ${open ? classes.open : ''}`}>
          {children}
        </div>
      </ClickOutsideComponent>

      {/* {children} */}
    </div>
  )

}

export default Menu;