import React from "react";
import classes from './CrewCard.module.scss'
import OverflowList2 from "../UI/OverflowList2";

const CrewCard = ({ crew, selectCrew, isSelected, deleteCrew, editCrew, populatedCrewWarning }) => {
  return (
    <div onClick={() => selectCrew(crew)} className={`${classes.crew} ${isSelected ? classes.selectedCrew : ''}`} >
      <span className={`icon-move ${classes.dragIcon}`}></span>
      <span className={`icon-users ${classes.crewIcon}`}></span>
      <div className={classes.crewInfo} id={`crewInfo${crew.id}`}>
        <span className={classes.crewName}>{crew.id === null ? 'Unassigned' : `${crew.groupName}${crew.site ? ` - ${crew.site}` : ''}`}</span>
        <OverflowList2 items={crew.employees.map(emp => emp.employee)} containerId={`crewInfo${crew.id}`} />
        {/* <OverflowList items={crew.employees.map(emp => emp.employee)} containerId={`crewInfo${crew.id}`}/> */}
      </div>
      {crew.id !== null && <>
        <span className={`icon-draft ${classes.deleteIcon} ${classes.deleteButton}`} onClick={(event) => editCrew(event, crew)}></span>
        <span className={`icon-delete ${classes.deleteIcon} ${classes.deleteButton}`} onClick={(event) => crew.employees?.length > 0 ? populatedCrewWarning(event) : deleteCrew(event, crew)}></span>
      </>}
    </div>
  );
}

export default CrewCard;