import React, { useEffect, useState } from "react";
import classes from "./CrewConfiguration.module.scss";
import Header from "../standard/Header";
import { useRef } from "react";
import { Checkbox, colors } from "@mui/material";
import { getOpenCategoriesCrewOptions } from "../services/filterService";
import { currentLocationIdState, selectedLocationState } from "../../features/currentDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { checkAccess, getEmployeesScheduleFromMonthYear } from "../services/userRegisterService";
import "../../global/colors.scss";
import UserAvatar from "../UI/UserAvatar";
import OverflowList from "../UI/OverflowList";
import { alphabetSort, arraysHaveSameNumbers, defaultRoleColor, findChangedElements, getContrastColor, groupByProperty, numberSort, replaceFalsyInArray, searchBy, setSearchValues } from "../../utils/utils";
import SearchBar from "../UI/SearchBar";
import { useCallback } from "react";
import OverflowList2 from "../UI/OverflowList2";
import CreationDialog from "../UI/CreationDialog";
import { deleteGroup, fetchGroups, saveCrew } from "../services/crewService";
import { showToast } from "../../features/toastNotificationSlice";
import DeleteConfirmationDialog from "../UI/DeleteConfirmationDialog";
import MultiOptionDialog from "../UI/MultiOptionDialog";
import { CREW_CONFIGURATION_ID } from "../services/global";
import LocationDropDown from "../standard/LocationDropdown";
import { fetchResourcePermissions } from "../../features/resourcePepermissionsSlice";
import Placeholder from "../UI/Placeholder";
import { setLoading } from "../../features/loadingSlice";
import CrewCard from "./CrewCard";
import SortableList from "../UI/SortableList";
import useFetchAirCrafts from "../hooks/useFetchAirCrafts";

const updateCrewsOrder = async (previousCrews, currentCrews) => {
  let reorderedCrews = currentCrews.map((crew, index) => ({ ...crew, groupOrder: index }));
  let changedCrews = findChangedElements({
    previousVersion: previousCrews,
    updatedVersion: reorderedCrews,
    property: 'groupOrder',
    ignoreWhen: ((element) => element.id === null)
  });
  let successCount = 0;
  for (let crew of changedCrews) {
    try {
      let response = await saveCrew(crew);
      if (response != null) successCount++;
    } catch (error) {
      return { success: false, message: "Couldn't update crews order" }
    }
  }
  if (successCount === changedCrews.length) return { success: true }
}

const CrewConfiguration = () => {

  const [groups, setGroups] = useState({ crews: [], autoSelect: false });
  const [editableGroups, setEditableGroups] = useState([]);
  const [employees, setEmployees] = useState([]);
  const selectedLocation = useSelector(selectedLocationState);
  const [employeeSelection, setEmployeeSelection] = useState(new Set());
  const [selectedCrew, setSelectedCrew] = useState({ name: '', id: null, employees: [], site: null });
  const [crewEmployees, setCrewEmployees] = useState(new Set([]));
  const [draggedEmployee, setDraggedEmployee] = useState(-1);
  const [employeesBackup, setEmployeesBackup] = useState([]);

  const dispatch = useDispatch();

  useFetchAirCrafts();
  // permissions
  useEffect(() => {
    dispatch(fetchResourcePermissions('crew-configuration'));
  }, [dispatch]);

  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    async function checkPageAccess() {
      let access = await checkAccess('crew-configuration', true);
      setHasAccess(access);
      if (!access) window.location.href = "/home";
    }
    checkPageAccess();
  }, []);


  useEffect(() => {
    getGroups({ autoSelect: true, showLoading: true });
  }, [selectedLocation])

  const getGroups = useCallback(({ locationId = null, autoSelect = true, showLoading = false }) => {
    (async function () {
      if ((typeof(locationId) === 'number') || (typeof(selectedLocation?.id) === 'number') ) {if (showLoading) dispatch(setLoading(true));
      let response = await fetchGroups(locationId ?? selectedLocation?.id);
      if (response !== null) {
        setGroups({
          crews: setSearchValues(numberSort({
            array: response.map(group => ({
              ...group,
              employees: alphabetSort({
                array: replaceFalsyInArray(group.employees, 'employee.userName', 'employee.employeeName'),
                properties: ['employee.userName']
              })
            })),
            properties: ['groupOrder'],
            keepLast: (group) => group.id === null
          }), ['groupName', 'site']),
          autoSelect: autoSelect
        });
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        throw new Error('Something went wrong retrieving groups');
      }}
    })();
  }, [selectedLocation]);

  useEffect(() => {
    setEditableGroups(groups.crews);
    if (groups.autoSelect && groups.crews.length > 0) selectCrew(groups.crews[0]);
  }, [groups]);

  useEffect(() => {
    let flatEmployeeList = setSearchValues(editableGroups.flatMap(crew =>
      crew.employees.map(employee => ({
        ...employee.employee,
        userId: employee.id,
        crewName: crew.groupName,
        crewSite: crew.site,
        crewId: crew.id,
      }))
    ), ['userName', 'relationCode', 'license', 'crewName']);
    setEmployees(flatEmployeeList);
    setEmployeesBackup(flatEmployeeList);
  }, [editableGroups]);

  useEffect(() => {
    setCrewEmployees(new Set(employees.filter(employee =>
      employee.crewId === selectedCrew.id
    ).map(employee => employee.id)));
  }, [employees, selectedCrew]);

  const selectEmployee = (checkValue, employeeId) => {
    setEmployeeSelection(prev => {
      let updated = new Set(prev);
      checkValue ? updated.add(employeeId) : updated.delete(employeeId);
      return updated;
    });
  }

  const crewEmployeesArray = () => {
    return Array.from(crewEmployees).map(id => employees.find(employee => employee.id === id));
  }

  const clearSelection = () => {
    setEmployeeSelection(new Set());
  }

  const addToCrew = () => {
    setEmployees(prev => prev.map(employee => (
      employeeSelection.has(employee.id) ?
        { ...employee, crewId: selectedCrew.id, crewName: selectedCrew.name, crewSite: selectedCrew.site }
        : employee
    )));
    clearSelection();
  }

  const dragEmployee = (event, employeeId) => {
    event.preventDefault();
    setDraggedEmployee(employeeId);
  }

  const dropEmployee = (event) => {
    setEmployees(prev => prev.map(employee => (
      draggedEmployee === employee.id ?
        { ...employee, crewId: selectedCrew.id, crewName: selectedCrew.name, crewSite: selectedCrew.site }
        : employee
    )));
  }

  const removeEmployee = (removedEmployee) => {
    setEmployees(prev => prev.map(employee => (
      removedEmployee.id === employee.id ?
        { ...employee, crewId: null, crewName: 'Not assigned employees', crewSite: null }
        : employee
    )));
  }

  const [crewQuery, setCrewQuery] = useState('');
  const searchCrews = useCallback((value) => {
    setCrewQuery(value.toLowerCase());
  }, [setCrewQuery]);
  const [filteredCrews, setFilteredCrews] = useState([]);
  useEffect(() => {
    setFilteredCrews(searchBy({ array: groups.crews, query: crewQuery }));
  }, [groups, crewQuery])

  const [employeeQuery, setEmployeeQuery] = useState('');
  const searchEmployees = useCallback((value) => {
    setEmployeeQuery(value.toLowerCase());
  }, [setEmployeeQuery]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  useEffect(() => {
    setFilteredEmployees(searchBy({ array: employees, query: employeeQuery }))
  }, [employees, employeeQuery])

  // CreationDialog
  const [openCreate, setOpenCreate] = useState(false);
  const [editElement, setEditElement] = useState(null);

  const closeCreate = (event, create, updatedCrew) => {
    event.stopPropagation();
    if (create) handleCrewSave({
      crew: { ...updatedCrew, locationId: selectedLocation.id, groupOrder: typeof(updatedCrew.groupOrder) === 'number' ? updatedCrew.groupOrder: groups.crews.filter(c => typeof(c.id) === 'number').length},
      notify: true,
      successMsg: updatedCrew.id ? 'Crew updated' : 'Crew created successfully' 
    });
    setEditElement(null);
    setOpenCreate(false)
  }

  const handleCrewSave = useCallback(({ crew, notify = false, successMsg = 'Crew saved', refresh = true }) => {
    if (notify) dispatch(showToast({ type: 'info', message: 'Saving...' }));
    return saveCrew(crew).then(data => {
      if (data !== null) {
        if (notify) dispatch(showToast({ type: 'success', message: successMsg }));
        if (refresh) {
          getGroups({ locationId: crew.locationId, autoSelect: false });
        }
        return data;
      } else {
        if (notify) dispatch(showToast({ type: 'warning', message: "An error occurred while saving the crew." }));
      }
    }, rejection => {
      if (notify) dispatch(showToast({ type: 'warning', message: "An error occurred while saving the crew." }));
      return Promise.reject(rejection);
    }).catch(error => {
      console.log(error);
      if (notify) dispatch(showToast({ type: 'warning', message: "An error occurred while saving the crew." }));
      return Promise.reject(error);
    })

  }, [dispatch]);

  const unsavedChanges = useCallback(() => {
    let newGroups = groups.crews.map(crew => ({ ...crew, employees: employees.filter(emp => crew.id === emp.crewId).map(emp => ({ id: emp.userId, employee: emp })) }));
    let previousGroups = groups.crews.map(crew => ({ ...crew, employees: employeesBackup.filter(emp => crew.id === emp.crewId).map(emp => ({ id: emp.userId, employee: emp })) }));
    let changedGroups = newGroups.filter(newGroup =>
      !arraysHaveSameNumbers(
        newGroup.employees.map(emp => emp.employee.id),
        previousGroups.find(prevGroup => prevGroup.id === newGroup.id)?.employees.map(emp => emp.employee.id) ?? []
      )
    );
    return changedGroups.filter(group => group.id !== null);
  }, [groups, employees, employeesBackup]);

  const handleSaveChanges = () => {
    let changedGroups = unsavedChanges();
    dispatch(showToast({ type: 'info', message: 'Saving changes...' }));

    (async function () {
      let sucessCount = 0;
      for (let crew of changedGroups) {
        try {
          let response = await handleCrewSave({ crew, refresh: false });
          sucessCount++;
        } catch (error) {
          console.log(error);
          dispatch(showToast({ type: 'warning', message: error }));
        }
      }
      if (sucessCount === changedGroups.length) {
        dispatch(showToast({ type: 'success', message: "Modified crews updated successfully." }));
        getGroups({ locationId: changedGroups[0].locationId, autoSelect: false });
      }
    })();
  }

  // crew deletion
  const [crewDelete, setCrewDelete] = useState({ id: -1, name: '', site: null });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteCrew = (event, crew) => {
    event.stopPropagation();
    setCrewDelete({ id: crew.id, name: crew.groupName, site: crew.site });
  }

  const onConfirmDelete = () => {
    (async function () {
      let data = await deleteGroup(crewDelete.id);
      if (data.success) {
        getGroups({ locationId: null, autoSelect: false });
        dispatch(showToast({ type: 'success', message: `"${`${crewDelete.name}${crewDelete.site ? ` - ${crewDelete.site}` : ''}`}" deleted successfully` }));
      } else {
        getGroups({ locationId: null, autoSelect: false });
        dispatch(showToast({ type: 'warning', message: data.message ?? `Unable to delete "${crewDelete.name}"`, duration: 4500 }));
      }
    })();
    setCrewDelete({ id: -1, name: '', site: null });
    setShowDeleteDialog(false);
  }

  const onCancelDelete = () => {
    setCrewDelete({ id: -1, name: '', site: null });
    setShowDeleteDialog(false);
  }

  useEffect(() => {
    if (crewDelete.id !== -1) {
      setShowDeleteDialog(true);
    }
  }, [crewDelete]);

  const deleteCurrentGroup = useCallback((event) => {
    (selectedCrew.employees?.length) ? populatedCrewWarning(event) : setCrewDelete(selectedCrew);
  }, [selectedCrew, setCrewDelete]);

  const populatedCrewWarning = (event) => {
    event.stopPropagation();
    dispatch(showToast({ type: 'warning', message: `Unable to delete non-empty groups.` }));
  }

  const [showSaveChangesDialog, setShowSaveChangesDialog] = useState(false);
  const [crewSelectionAttempt, setCrewSelectionAttempt] = useState(null);

  const selectCrew = (crew) => {
    let changedGroups = unsavedChanges();
    if (changedGroups.length > 0) {
      setCrewSelectionAttempt({ name: crew.groupName, id: crew.id, employees: crew.employees, site: crew.site });
      setShowSaveChangesDialog(true);
    } else {
      setSelectedCrew({ name: crew.groupName, id: crew.id, employees: crew.employees, site: crew.site });
    }
  }

  const handleDialogSave = () => {
    handleSaveChanges();
    setSelectedCrew(crewSelectionAttempt);
    setCrewSelectionAttempt(null);
    setShowSaveChangesDialog(false);
  }

  const handleDialogDiscard = () => {
    getGroups({ locationId: null, autoSelect: false });
    setSelectedCrew(crewSelectionAttempt);
    setCrewSelectionAttempt(null);
    setShowSaveChangesDialog(false);
  }

  const handleDialogCancel = () => {
    setCrewSelectionAttempt(null);
    setShowSaveChangesDialog(false);
  }

  const editCrew = (event, crew) => {
    event.stopPropagation();
    setEditElement(crew);
  }

  useEffect(() => {
    (editElement === null) ? setOpenCreate(false) : setOpenCreate(true);
  }, [editElement]);

  const endDragEmployee = (event) => {
    setDraggedEmployee(null);
  }

  return (hasAccess && <>
    <Header title={'Crew Configuration'} showPublishButton={false} showRole={true} showCalendarState={false} />
    <div className={classes.container}>
      <div className={`${classes.crewsContainer} ${classes.card}`}>
        <div className={classes.dropdown}>
          <LocationDropDown pageId={CREW_CONFIGURATION_ID} global={true} />
        </div>
        <div className={classes.crewsContainerHeader}>
          <SearchBar onChange={searchCrews} />
          <button className={classes.headerButton} onClick={() => { setOpenCreate(true) }}>New Crew</button>
        </div>
        <div className={classes.crewList}>
          <Placeholder list={filteredCrews} placeholder="No crews were found">
            <SortableList
              items={filteredCrews}
              render={(crewElement) =>
                <CrewCard
                  crew={crewElement}
                  selectCrew={selectCrew}
                  isSelected={selectedCrew.id === crewElement.id}
                  deleteCrew={deleteCrew} editCrew={editCrew}
                  populatedCrewWarning={populatedCrewWarning}
                />
              }
              updateService={updateCrewsOrder}
            />

          </Placeholder>
        </div>
      </div>
      <div className={`${classes.employeesContainer} ${classes.card}`}>
        <div className={classes.employeesHeader}>
          <span className={`icon-users ${classes.employeeIcon}`}></span>
          <div className={classes.headerInfo}>
            <span className={classes.employeeTitle}>Employees</span>
            <span className={classes.employeeDescription}>Select and then click on "Add Selected" or drag and drop the users you want to add to crew "{selectedCrew.name}"</span>
          </div>
          <div className={classes.headerButtons}>
            <button className={classes.headerButton} onClick={deleteCurrentGroup}>
              <span className={`icon-delete ${classes.deleteIcon}`}></span>
              Delete Crew
            </button>
            <button className={`${classes.headerButton} ${false ? classes.highlightButton : ''}`} onClick={handleSaveChanges}>
              <span className={`icon-save ${classes.deleteIcon}`}></span>
              Save
            </button>
          </div>
        </div>
        <div className={classes.employeesBody}>
          <div className={classes.employeeListContainer}>
            <div className={classes.employeeListHeader}>
              <SearchBar onChange={searchEmployees} />
              <button onClick={addToCrew} className={classes.headerButton}>Add Selected</button>
            </div>
            <div className={classes.employeeList}>
              <Placeholder list={filteredEmployees} placeholder={"No employees were found"}>
                {filteredEmployees.map((employee, m) =>
                  <div
                    key={m}
                    className={`${classes.employeeCard} ${employeeSelection.has(employee.id) ? classes.highlightEmployee : ''}`}
                    draggable
                    onDrag={(event) => dragEmployee(event, employee.id)}
                    onDragEnd={(event) => endDragEmployee(event)}
                  >
                    <span className={`icon-move ${classes.dragIcon}`}></span>
                    <Checkbox
                      sx={{
                        color: '#cccccc',
                        '&.Mui-checked': {
                          color: '#00ca9a',
                        },
                        '& .MuiSvgIcon-root': { fontSize: '1.5em' }
                      }}
                      checked={employeeSelection.has(employee.id)}
                      onChange={(event) => selectEmployee(event.target.checked, employee.id)}
                    />
                    <div className={classes.employeeInfo}>
                      <UserAvatar employee={employee} hideSkills={false} />
                      <div className={classes.nameContainer}>
                        <span className={classes.employeeName}>{`${employee.userName}`}</span>
                        <div className={classes.employeeAdditionalInfo}>
                          <span className={classes.employeeCode}>
                            {employee?.relationCode ?? ''}<b>{employee?.license ? ` - ${employee.license}` : ''}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`${classes.employeeCrew} ${selectedCrew.id === employee.crewId ? classes.crewHighlight : ''}`}>
                      <span>{employee.crewId === null ? 'Unassigned' : employee.crewName}</span>
                      {employee.crewId !== null && employee.crewSite && <span className={classes.crewSite}>{employee.crewSite}</span>}
                    </div>
                  </div>
                )}
              </Placeholder>
            </div>

          </div>
          <div className={classes.selectedEmployees}>
            <div className={classes.selectedHeader}>
              <span className={`icon-user-shift-pattern ${classes.selectedEmployeesIcon}`}></span>
              <span className={classes.employeeTitle}>Selected Employees</span>
            </div>
            <div className={classes.membersBackground}>
              <div onDragOver={(e) => e.preventDefault()} onDrop={(event) => dropEmployee(event)} className={classes.members} >
                <div className={classes.memberContent}>
                  {crewEmployeesArray().map((employee, p) =>
                    <span key={p} className={classes.employeeChip} style={{ backgroundColor: employee?.roleColor ?? defaultRoleColor, color: getContrastColor(employee?.roleColor ?? defaultRoleColor) }}>
                      {`${employee?.userName}`}
                      {employee?.crewId !== null && <span className={`icon-close ${classes.removeUser}`} onClick={() => { removeEmployee(employee) }}></span>}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreationDialog element={editElement} open={openCreate} title={editElement ? "Edit Crew" : "New Crew"} onClose={closeCreate} icon={'icon-users'} defaultName={'New Crew'} />
    {showDeleteDialog && (
      <DeleteConfirmationDialog
        message={`Are you Sure you want to delete crew "${`${crewDelete.name}${crewDelete.site ? ` - ${crewDelete.site}` : ''}`}" ?`}
        onConfirm={onConfirmDelete}
        onCancel={onCancelDelete}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        zIndex={10000}
      />
    )}
    {showSaveChangesDialog &&
      <MultiOptionDialog
        message={'You have unsaved changes. Save before moving to another group?'}
        options={[
          { label: 'Save', callback: handleDialogSave },
          { label: 'Discard', callback: handleDialogDiscard },
          { label: 'Cancel', callback: handleDialogCancel }
        ]}
      />

    }
  </>)
}



export default CrewConfiguration;