import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./HomeContainer.module.scss";
import WeekViewContainer from "../weekview/WeekViewContainer";
import BottomBar from "../standard/BottomBar";
import Header from "../standard/Header";
import MainTopBar from "../standard/MainTopBar";
import {
  checkPermission,
  resourcePermissions,
} from "../../features/resourcePepermissionsSlice";
import useFetchAirCrafts from "../hooks/useFetchAirCrafts";
import UserRegisterContext from "../store/user-register-context";
import { selectPendingWeeks } from "../../features/publishSlice";

const HomeContainer = () => {
  const currentResourcePermissions = useSelector(
    resourcePermissions("week-view")
  );

  // adjust style if pending banner appears
  const userRegisterCtx = useContext(UserRegisterContext);
  const pendingWeeks = useSelector(selectPendingWeeks);
  const [isWeekPending, setIsWeekPending] = useState(false);
  useEffect(() => {
    let weekStart = userRegisterCtx?.selectedWeek?.startDate?.toISOString()?.split('T')?.[0] ?? 'no-week';
    setIsWeekPending(pendingWeeks.includes(weekStart));
  }, [pendingWeeks, userRegisterCtx]);
  
  let headerMargin = checkPermission(currentResourcePermissions, "day-summary")
    ? "headerTop"
    : "headerTopNoSummary";

  useFetchAirCrafts();
  return (
    <div className="headerMainContainer">
      <div className={`${headerMargin} ${isWeekPending ? 'pendingWeek': ''}`}>
        <WeekViewContainer />
        <BottomBar />
      </div>
      <Header />
      <MainTopBar />
    </div>
  );
};

export default HomeContainer;
