import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";
import { alphabetSort } from "../../utils/utils";

export const getToken = () => Cookies.get("token");

export async function getUsers() {
  const token = getToken();
  const url = API_BASE_URL + "/get-users";
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return alphabetSort({array: data.load, properties:['name', 'lastName']});
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function saveUsers(userData) {
  const token = getToken();
  const url = API_BASE_URL + "/save-user";
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCountries() {
  const token = getToken();
  const url = API_BASE_URL + "/get-countries";
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getStates(idCountry) {
  let params = new URLSearchParams();
  params.append("idCountry", idCountry);
  const token = getToken();
  const url = API_BASE_URL + "/get-states?" + params.toString();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCities(idState) {
  let params = new URLSearchParams();
  params.append("idState", idState);
  const token = getToken();
  const url = API_BASE_URL + "/get-cities?" + params.toString();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getEmployees() {
  const token = getToken();
  const url = API_BASE_URL + "/get-employees";
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getRoles() {
  const token = getToken();
  const url = API_BASE_URL + "/get-roles";
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return null;
  }
}