import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./WeekDaysMobile.module.scss";
import userRegisterContext from "../store/user-register-context";
import useWeekDaysState from "../hooks/useWeekDaysState";
import WorkShiftConfig from "../Users/WorkShiftConfig";
import {
  resourcePermissions,
  checkPermission,
} from "../../features/resourcePepermissionsSlice";
import { allLocations } from "../../features/currentDataSlice";
import InformationDialog from "../UI/InformationDialog";
import WorkShiftConfig2 from "../Users/WorkShiftConfig2";

const WeekDaysMobile = (props) => {
  const loadedLocations = useSelector(allLocations);
  const userRegisterCtx = useContext(userRegisterContext);
  const {
    isWorkShiftShown,
    userShift,
    user,
    showWorkShiftHandler,
    hideShowWorkShiftHandler,
  } = useWeekDaysState(props.shifts);
  const shift = props.shifts?.shifts;
  const [showInfo, setShowInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");

  const currentResourcePermissions = useSelector(
    resourcePermissions("week-view")
  );

  const openShifts = (shift) => {
    if (shift.transferTo) {
      const transferLocation = loadedLocations.find(
        (obj) => obj.id === shift.transferTo
      );
      if (
        transferLocation &&
        !shift.scheduleTime &&
        !shift.site &&
        !shift.leave
      ) {
        const message = `Relocated to ${transferLocation.label}`;
        setInfoMsg(message);
        setShowInfo(true);
      }
    }
    if (shift.leave) {
      let message = "";
      if (shift.leave.vacation) message = "Employee is on vacation leave!";
      if (shift.leave.leaveType?.type === "S")
        message = "Employee is on sick leave!";
      if (shift.leave.leaveType?.type === "M") message = "Employee is on leave!";
      if (shift.leave.leaveType?.type === "T")
        message = "Employee is on training leave!";
      setInfoMsg(message);
      setShowInfo(true);
    }
    if (props.isUserTransfer) {
      if (shift.avaibleInTransfer) {
        if (shift.id) {
          showWorkShiftHandler(shift);
        } else {
          if (checkPermission(currentResourcePermissions, "create-shift"))
            showWorkShiftHandler(shift);
        }
      } else if (!shift.avaibleInTransfer) {
        const message = "Employee is at origin airport!";
        setInfoMsg(message);
        setShowInfo(true);
      }
    } else if (!shift.leave && !shift.transferTo) {
      if (shift.id) {
        showWorkShiftHandler(shift);
      } else {
        if (checkPermission(currentResourcePermissions, "create-shift"))
          showWorkShiftHandler(shift);
      }
    }
  };

  return (
    <div className={classes.dateContainerInfo}>
      {userRegisterCtx.selectedWeek.week.map((date, index) => {
        const transferLocation = loadedLocations.find(
          (obj) => obj.id === shift[index]?.transferTo
        );
        return (
          <div key={date.id}>
            <span className={classes.dayName}>{date.name}</span>
            <div
              onClick={() => openShifts(shift[index])}
              className={classes.dayContainer}
              style={{
                backgroundColor: shift[index]?.scheduleTime?.color
                  ? shift[index].scheduleTime.color
                  : "white",
                color: shift[index]?.scheduleTime?.color ? "white" : "black",
              }}
            >
              <span>{date.day}</span>
              {shift[index]?.scheduleTime && shift[index]?.leave === null && (
                <div className={classes.userRegisterContainer}>
                  <i className={`icon-check ${classes.iconCheck}`} />
                  <span>{shift[index]?.shortName}</span>
                </div>
              )}

              {shift[index]?.transferTo &&
                transferLocation &&
                !shift[index]?.scheduleTime &&
                !shift[index]?.site &&
                !shift[index]?.leave && (
                  <div className={classes.transferContainer}>
                    <span
                      className={`icon-icon-relocated ${classes.iconRelocated}`}
                    ></span>
                  </div>
                )}
              {!shift[index]?.avaibleInTransfer && props.isUserTransfer && (
                <div className={classes.transferContainer}>
                  <span
                    className={`icon-icon-relocated ${classes.iconRelocated}`}
                  ></span>
                </div>
              )}
              {shift[index]?.leave && (
                <div className={classes.leaveContainer}>
                  {shift[index]?.leave.vacation && (
                    <span className="icon-vacation"></span>
                  )}
                  {shift[index]?.leave?.leaveType?.type === "S" && (
                    <span className="icon-sick"></span>
                  )}
                  {shift[index]?.leave?.leaveType?.type === "M" && (
                    <span className="icon-leave"></span>
                  )}
                  {shift[index]?.leave?.leaveType?.type === "T" && (
                    <span className="icon-training"></span>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
      {isWorkShiftShown && (
        <WorkShiftConfig2
          shift={userShift}
          user={user}
          onClose={hideShowWorkShiftHandler}
          showTransferUser={true}
          openFrom={"week-view"}
        />
      )}
      {showInfo && (
        <InformationDialog
          message={infoMsg}
          onCancel={() => setShowInfo(false)}
        />
      )}
    </div>
  );
};

export default WeekDaysMobile;