import { useContext, useRef } from "react";
import { useSelector } from "react-redux";
import classes from "./DayShiftsSummary.module.scss";
import userRegisterContext from "../store/user-register-context";
import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { selectedLocationState } from "../../features/currentDataSlice";
import { getContrastColor } from "../../utils/utils";

function formatDate(dateString) {
  const date = new Date(dateString);
  const formatter = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  });
  return formatter.format(date).replace(",", " ");
}

function getLeftPosition(element, index) {
  let left =
    element.getBoundingClientRect().left -
    32 -
    150 +
    element.getBoundingClientRect().width / 2;
  if (left + 332 > document.documentElement.getBoundingClientRect().width) {
    return document.documentElement.getBoundingClientRect().width - 332 + "px";
  } else {
    return (
      element.getBoundingClientRect().left -
      32 -
      150 +
      element.getBoundingClientRect().width / 2 +
      "px"
    );
  }
}

function getTopPosition(element, index) {
  return (
    element.getBoundingClientRect().top +
    element.getBoundingClientRect().height -
    32 +
    "px"
  );
}

function getDialogMaxHeight(element) {
  let topDialogPosition =
    element.getBoundingClientRect().top +
    element.getBoundingClientRect().height;
  let maxHeight = window.innerHeight - 43 - topDialogPosition;
  return maxHeight + "px";
}

const DayShiftsSummary = (props) => {
  const selectedLocation = useSelector(selectedLocationState);
  const userRegisterCtx = useContext(userRegisterContext);
  // Function for opening and closing the dialog
  const [open, setOpen] = React.useState(false);
  const elementRef = useRef(null);
  let leftDialogPosition = elementRef.current
    ? getLeftPosition(elementRef.current, props.index)
    : "";
  let topDialogPosition = elementRef.current
    ? getTopPosition(elementRef.current, props.index)
    : "";
  let dialogMaxHeight = elementRef.current
    ? getDialogMaxHeight(elementRef.current)
    : "400px";
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleArrow = `${open ? `icon-up` : `icon-down`} ${classes.iconArrow}`;
  const requiredCount = userRegisterCtx.allocatedShiftsData?.weekDays
    ? userRegisterCtx.allocatedShiftsData?.weekDays[props.index]
      ?.required ?? 0
    : 0;
  const allocatedCount = userRegisterCtx.allocatedShiftsData?.weekDays
    ? userRegisterCtx.allocatedShiftsData?.weekDays[props.index]
      ?.allocated ?? 0
    : 0;
  return (
    <div ref={elementRef}>
      <div onClick={handleOpen} className={classes.summaryAndDropdown}>
        <div className={classes.daySummary}>
          <div className={classes.dayShiftInfo}>
            <span className={classes.shiftsLabel}>Shifts</span>
            <span className={classes.shiftsCount}>
              {requiredCount}
            </span>
          </div>
          <div className={classes.dayShiftInfo}>
            <span className={classes.shiftsLabel}>Allocated</span>
            <span className={`${classes.allocatedCount} ${(allocatedCount < requiredCount) ? classes.incomplete : classes.complete}`}>
              {allocatedCount}
            </span>
          </div>
        </div>
        <span className={toggleArrow}></span>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionProps={{
          timeout: 1000,
          transitiontimingfunction: "ease",
        }}
        PaperProps={{
          style: {
            position: "absolute",
            top: topDialogPosition,
            left: leftDialogPosition,
            width: "300px",
            height: "min-content",
            maxHeight: dialogMaxHeight,
            background: "#1a1a1a",
          },
        }}
      >
        <DialogTitle
          style={{
            padding: 0,
            fontFamily: 'Poppins',
            fontWeight: 'normal'
          }}
        >
          <div className={classes.siteAndShiftDate}>
            {userRegisterCtx.allocatedShiftsData?.weekDays
              ? formatDate(
                userRegisterCtx.allocatedShiftsData?.weekDays[props.index]
                  ?.date ?? ''
              )
              : ""}
          </div>
          <hr className={classes.verticalLine}></hr>
        </DialogTitle>
        <DialogContent
          style={{
            padding: 0,
          }}
        >
          {userRegisterCtx.allocatedShiftsData?.weekDays
            ? userRegisterCtx.allocatedShiftsData?.weekDays[
              props.index
            ]?.sites?.map((item, index) => {
              let siteName =
                selectedLocation?.sites?.find(
                  (site) => site.id === item.site
                )?.label ?? "";
              let siteCode =
                selectedLocation?.sites?.find(
                  (site) => site.id === item.site
                )?.siteCode ?? "";
              let scheduleTime =
                userRegisterCtx.scheduleTimes?.find(
                  (time) => time.id === item.time
                )?.label ?? " ";
              let color = userRegisterCtx.scheduleTimes?.find(
                (time) => time.id === item.time
              )?.color;

              return (
                <div key={index} className={classes.siteAndShiftCard}>
                  <div className={classes.siteAndShiftCardLeftContainer}>
                    <div
                      className={`${classes.siteAndShiftLeftCard}`}
                      style={{ backgroundColor: color, "color": getContrastColor(color)}}
                    >
                      <div className={classes.siteAndShiftLeftCardHeader}>
                        <div className="icon-check" />
                        <div>{scheduleTime[0] + siteCode}</div>
                      </div>
                      <div className={`${classes.siteAndShiftCardText} ${classes.cardName}`}>
                        {scheduleTime + " " + siteName}
                      </div>
                    </div>

                    <div className={classes.shiftAllocatedText}>
                      <div className={classes.siteAndShiftCardText}>
                        Shifts
                      </div>
                      <div className={classes.siteAndShiftCardText}>
                        Allocated
                      </div>
                    </div>
                  </div>

                  <div className={classes.shiftAllocatedNumbers}>
                    <div className={classes.siteAndShiftCardText}>
                      {item.required}
                    </div>
                    <div
                      className={`${classes.siteAndShiftCardText} ${(item.allocated < item.required) ? classes.incomplete : classes.complete}`}
                    >
                      {item.allocated}
                    </div>
                  </div>
                </div>
              );
            })
            : ""
          }
          {userRegisterCtx.allocatedShiftsData?.weekDays
            ? (userRegisterCtx.allocatedShiftsData?.weekDays[
              props.index
            ]?.sites?.length < 1 ? <span className={classes.placeholder}>No information available</span> : '') : ""}

        </DialogContent>

      </Dialog>
    </div>
  );
};

export default DayShiftsSummary;