import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../UI/Dropdown";
import classes from "./MainTopBar.module.scss";
import userRegisterContext from "../store/user-register-context";
import { getCurrentWeekDates, getYears } from "../services/dateTimeService";
import WeekShiftSummary from "../shiftShortages/WeekShiftSummary";
import { resourcePermissions, checkPermission } from "../../features/resourcePepermissionsSlice";
import { fetchCrewOptions } from "../../features/filterSlice";
import {
  selectedLocationState,
  selectedMonthState,
  selectedYearState,
  allLocations,
  currentPageIdState
} from "../../features/currentDataSlice";
import { months } from "../services/dateTimeService";
import { selectPendingWeeks } from "../../features/publishSlice";


const MainTopBar = () => {
  const userRegisterCtx = useContext(userRegisterContext);
  const years = getYears();
  const dispatch = useDispatch();
  const pageId = useSelector(currentPageIdState);
  const locations = useSelector(allLocations);
  const selectedLocation = useSelector(selectedLocationState);
  const selectedMonth = useSelector(selectedMonthState);
  const selectedYear = useSelector(selectedYearState);
  const currentResourcePermissions = useSelector(resourcePermissions('week-view'));
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);

  // adjust style if pending banner appears
  const pendingWeeks = useSelector(selectPendingWeeks);
  const [isWeekPending, setIsWeekPending] = useState(false);
  useEffect(() => {
    let weekStart = userRegisterCtx?.selectedWeek?.startDate?.toISOString()?.split('T')?.[0] ?? 'no-week';
    setIsWeekPending(pendingWeeks.includes(weekStart));
  }, [pendingWeeks, userRegisterCtx]);

  const changeSelectedLocation = (location) => {
    userRegisterCtx.changeLocation(location, false, pageId);
    dispatch(fetchCrewOptions(location.id));
  };

  const changeSelectedMonth = (month) => {
    userRegisterCtx.changeMonth(month, false, pageId);
  };

  const changeSelectedYear = (year) => {
    userRegisterCtx.changeYear(year, false, pageId);
  };

  const goToCurrentWeek = () => {
    userRegisterCtx.changeWeek(getCurrentWeekDates());
  }

  useEffect(()=>{
    let foundMonth = months.find(m => m.id === userRegisterCtx?.selectedWeek?.startDate?.month() + 1);
    let foundYear = getYears().find(y => y.label === userRegisterCtx?.selectedWeek?.startDate?.year().toString());
    if (foundMonth) {
      setCurrentMonth(foundMonth);
    }
    if(foundYear){
      setCurrentYear(foundYear);
    }
  }, [userRegisterCtx.selectedWeek])

  return (
    <div className={`${classes.mainBarBody} ${isWeekPending ? classes.pendingWeek : ''}`}>
      <div className={classes.dropdownContainer}>
        <Dropdown
          icon={"icon-location"}
          iconClass={"location"}
          defaultValue={selectedLocation}
          options={locations}
          onClick={changeSelectedLocation}
          showUserInfo={false}
          showSearch={true}
          contentHeight={'50%'}
          displayProperty={'label'}
        ></Dropdown>
      </div>
      <div className={classes.infoContainer}>

        {checkPermission(currentResourcePermissions, 'week-summary') && <WeekShiftSummary />}
        <button onClick={goToCurrentWeek} className={classes.todayBtn}> Today </button>
        <Dropdown
          className="monthDropdown"
          options={months}
          defaultValue={currentMonth}
          onClick={changeSelectedMonth}
          showUserInfo={false}
          showSearch={false}
          contentHeight={'50%'}
          displayProperty={'label'}

        ></Dropdown>
        <Dropdown
          className="yearDropdown"
          options={years}
          defaultValue={currentYear}
          onClick={changeSelectedYear}
          showUserInfo={false}
          showSearch={false}
          contentHeight={'50%'}
          displayProperty={'label'}

        ></Dropdown>
      </div>
    </div>
  );
};

export default MainTopBar;