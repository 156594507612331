import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import "./App.scss";
import LoginForm from "./components/standard/LoginForm";
import LoadingComponent from "./components/UI/Loading";
import {
  login,
  logout,
  isAdminActive,
  activeUserState,
} from "./features/userSlice";
import Cookies from "js-cookie";
import { setLoading, loading } from "./features/loadingSlice";
import { getSites, validateToken } from "./components/services/userRegisterService";
import MonthViewContainer from "./components/monthview/MonthViewContainer";
import {
  fetchResourcePermissions,
  restartPermissions,
} from "./features/resourcePepermissionsSlice";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import HomeContainer from "./components/home/HomeContainer";
import ShiftPatternContainer from "./components/shiftPattern/ShiftPatternContainer";
import RecoverPassword from "./components/forgotPassword/RecoverPassword";
import UserAdministration from "./components/userAdmin/adminUsers";
import RoleManagement from "./components/roleManagement/RoleManagement";
import ToastNotification from "./components/UI/ToastNotification";
import {
  HOME,
  MONTH_VIEW,
  SHIFT_PATTERN,
  USER_ADMINISTRATION,
  ROLE_CREATIOIN,
  LOGIN,
  FORGOT_PASSWORD,
  RECOVER_PASSWORD,
  MONTHVIEW_ID,
  ERROR_PAGE,
  ADMIN_LOCATIONS,
  ADMIN_LEAVES,
  CREW_CONFIGURATION,
  MS_LOGIN,
  HOME_ID
} from "./components/services/global";
import {
  addUserToCosmosDB,
  connectUsersState,
} from "./features/cosmosdbSlice";
import {
  currentLocationIdState,
  currentMonthIdState,
  currentPageIdState,
  currentWeekIdState,
  currentYearIdState,
  setSelectedLocationState,
  setSelectedMonthState,
  setSelectedYearState,
  setloadedLocations,
  updateCurrentLocationId,
  updatecurrentMonthId,
  updatecurrentYearId,
  showErrorPageState,
  setSites
} from "./features/currentDataSlice";
import { getAllLocations } from "./components/services/dropdownService";
import {
  getCurrentMonth,
  getYears,
  loadYearMonth,
  months,
} from "./components/services/dateTimeService";
import UserRegisterContext from "./components/store/user-register-context";
import { fetchCurrentMonthView } from "./features/monthViewSlice";
import ErrorPage from "./components/UI/ErrorPage";
import AdminLocations from "./components/locationsAdmin/AdminLocations";
import ErrorBoundary from "./components/UI/ErrorBoundary";
import LeaveAdministrator from "./components/LeavesAdmin/LeaveAdministrator";
import CrewConfiguration from "./components/crewConfiguration/CrewConfiguration";
import MsLogin from "./components/standard/MsLogin";
import { msalConfig } from "./config/MsAuthConfig";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

function App() {
  const adminActive = useSelector(isAdminActive);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(loading);
  const location = useLocation();
  const activeUser = useSelector(activeUserState);
  const userRegisterCtx = useContext(UserRegisterContext);
  const viewPageId = useSelector(currentPageIdState);
  const locationId = useSelector(currentLocationIdState);
  const weekId = useSelector(currentWeekIdState);
  const monthId = useSelector(currentMonthIdState);
  const year = useSelector(currentYearIdState);
  const connectedUsersCosmos = useSelector(connectUsersState);
  const [path, setPath] = useState("");
  const showErrorPage = useSelector(showErrorPageState);

  useEffect(() => {
    if (showErrorPage) {
      navigate(ERROR_PAGE)
    }
  }, [navigate, showErrorPage])

  useEffect(() => {
    const token = Cookies.get("token");
    async function fetchToken() {
      if (token) {
        try {
          const response = await validateToken(token);
          if (response.active) {
            dispatch(
              login({
                admin: response.admin,
                name: response.name,
                token: token,
                userId: response.id,
                username: response.username,
                active: true,
                resources: response.resources,
                role: response.role,
              })
            );
            dispatch(fetchResourcePermissions("week-view"));
          } else {
            dispatch(logout());
            userRegisterCtx.resetData();
            dispatch(restartPermissions());
            navigate(LOGIN);
          }
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(logout());
          userRegisterCtx.resetData();
          dispatch(restartPermissions());
          dispatch(setLoading(false));
          navigate(LOGIN);
        }
      } else {
        if (
          location.pathname !== FORGOT_PASSWORD &&
          location.pathname !== RECOVER_PASSWORD &&
          location.pathname !== LOGIN
        ) {
          dispatch(logout());
          userRegisterCtx.resetData();
          dispatch(restartPermissions());
          dispatch(setLoading(false));
          navigate(LOGIN);
        }
      }
    }
    if (
      ((location.pathname !== FORGOT_PASSWORD &&
        location.pathname !== RECOVER_PASSWORD &&
        location.pathname !== LOGIN) ||
        location.pathname === "/") &&
      !token
    ) {
      fetchToken();
    } else if (location.pathname === "/" && token) {
      navigate(HOME);
    } else if (location.pathname === FORGOT_PASSWORD && !token) {
      navigate(FORGOT_PASSWORD);
    } else if (location.pathname === RECOVER_PASSWORD && !token) {
      navigate(RECOVER_PASSWORD);
      if (location.search) {
        const inputString = location.search;
        const indexT = inputString.indexOf("t=");
        const valueAfterT = inputString.substring(indexT + 2);
        Cookies.set("RecoverPasstoken", valueAfterT, {
          secure: true,
          expires: 20,
        });
      }
    }

    fetchToken();
  }, []);

  const updateCurrentDataSlice = (locations, month, year, locationId) => {
    dispatch(setloadedLocations(locations));
    dispatch(setSelectedLocationState(locations.find(l=>l.id===locationId) ?? locations[0]));
    dispatch(setSelectedMonthState(month));
    dispatch(setSelectedYearState(year));
    dispatch(updateCurrentLocationId(locations.find(l=>l.id===locationId)?.id ?? locations[0].id));
    dispatch(updatecurrentMonthId(month.id));
    dispatch(updatecurrentYearId(year.id));
  };

  const loadResourcer = async (isMonthView) => {
    //first load locations
    let locations = await getAllLocations();
    const sites = await getSites();

    
    const [year, month, startDate, endDate, locationId] = loadYearMonth();
    if (locations.length >= 0 && month && year) {
      updateCurrentDataSlice(locations, month, year, locationId);
      if (sites.load) {
        dispatch(setSites(sites.load));
      }
      if (isMonthView) {
        const yearFormatted = parseInt(year.label, 10);
        dispatch(
          fetchCurrentMonthView({
            monthId: month.id,
            locationId: locations.find(loc => loc.id === locationId)?.id ?? locations[0].id,
            year: yearFormatted,
          })
        );
      }else {
        userRegisterCtx.fetchWeekData(locations.find(loc => loc.id === locationId) ?? locations[0], startDate, endDate, year, month, HOME_ID);
        userRegisterCtx.fetchScheduleTimes();
      }
    }
  };

  //fetch data depending of location
  useEffect(() => {
    // check if admin active and token active
    const token = Cookies.get("token");
    if (token) {
      if (location.pathname === MONTH_VIEW) {
        //fetch month data
        loadResourcer(true);
      } else {
        //fetch week data
        loadResourcer(false);
      }
    }
  }, []);

  // Microsoft Login
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <ErrorBoundary>

      <MsalProvider instance={msalInstance}>

        <Routes>
          <Route path={LOGIN} element={<LoginForm />} />
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={RECOVER_PASSWORD} element={<RecoverPassword />} />
          <Route path={HOME} element={<HomeContainer />} />
          <Route path={SHIFT_PATTERN} element={<ShiftPatternContainer />} />
          <Route path={MONTH_VIEW} element={<MonthViewContainer />} />
          <Route path={USER_ADMINISTRATION} element={<UserAdministration />} />
          <Route path={ROLE_CREATIOIN} element={<RoleManagement />} />
          <Route path={ERROR_PAGE} element={<ErrorPage />} />
          <Route path={ADMIN_LOCATIONS} element={<AdminLocations />} />
          <Route path={ADMIN_LEAVES} element={<LeaveAdministrator />} />
          <Route path={CREW_CONFIGURATION} element={<CrewConfiguration />} />
          <Route path={MS_LOGIN} element={<MsLogin />} />
          <Route path="*" element={<Navigate to={"home"} />} />
        </Routes>
        <ToastNotification />
        {isLoading && adminActive === true && <LoadingComponent />}
      </MsalProvider>

    </ErrorBoundary>
  );
}

export default App;