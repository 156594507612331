import React, { useState } from "react";
import classes from "./LeaveAdministrator.module.scss";
import Header from "../standard/Header";
import List from "./List";
import LeaveReasonAdmin from "./LeaveReasonAdmin";
import useLeavesAdmin from "../hooks/useLeavesAdmin";

const LeaveAdministrator = () => {
  const [showLeaveReasonForm, setShowLeaveReasonForm] = useState(false);
  const { leaves, sickLeaves, trainingLeaves, travelLeaves, fetchLeaves } = useLeavesAdmin();
  const [leaveState, setLeaveState] = useState({});
  const leaveTypes = [
    {
      id: 0,
      name: "Leave Management",
      icon: "icon-leave",
      leaves: leaves,
      type: "M",
      newButtonLabel: 'New Reason Leaves',
      successSaveMessage: "Leave reason saved!"
    },
    {
      id: 1,
      name: "Sick Leave Tracker",
      icon: "icon-sick",
      leaves: sickLeaves,
      type: "S",
      newButtonLabel: 'New Reason Leaves',
      successSaveMessage: "Leave reason saved!"
    },
    {
      id: 2,
      name: "New Training",
      icon: "icon-training",
      leaves: trainingLeaves,
      type: "T",
      newButtonLabel: 'New Training Reason',
      successSaveMessage: "Training reason saved!"
    },
		{
      id: 3,
      name: "New Travel",
      icon: "icon-travel",
      leaves: travelLeaves,
      type: "A",
      newButtonLabel: 'New Travel Reason',
      successSaveMessage: "Travel reason saved!"
    },
  ];

  const onEditHandler = (itemId) => {
    setShowLeaveReasonForm(true);
    setLeaveState(leaveTypes[itemId]);
  };

  const setLeaveStateHandler = (newLeave) => {
    setLeaveState((prevState) => ({
      ...prevState,
      leaves: [...prevState.leaves, newLeave],
    }));
  };

  const updateLeaveState = (newState) => {
    setLeaveState(newState);
  }

  const fetchLeavesHandler = (newValue) => {
    setLeaveState(newValue)
  }

  return (
    <>
      <div className={classes.body}>
        <Header
          title={"Leaves Configuration"}
          showPublishButton={false}
          showRole={true}
          showCalendarState={false}
        />
        <div className={classes.containers}>
          <div className={classes.leftContainer}>
            <List leaveTypes={leaveTypes} onEditHandler={onEditHandler}></List>
          </div>
          <div className={classes.rightContainer}>
            {!showLeaveReasonForm ? (
              <div className={classes.noLeaveSelectedText}>
                Please select leave to see information
              </div>
            ) : (
              <LeaveReasonAdmin
                leaveState={leaveState}
                setLeaveStateHandler={setLeaveStateHandler}
                updateLeaveState={updateLeaveState}
                fetchLeavesHandler={fetchLeavesHandler}
              ></LeaveReasonAdmin>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveAdministrator;
