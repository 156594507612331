import React, { useState } from "react";
import classes from "./List.module.scss";

const List = ({ leaveTypes, onEditHandler }) => {
  const [selectedItem, setSelectedItem] = useState(-1);

  const editLeaveHandler = (item) => {
    onEditHandler(item.id);
    setSelectedItem(item.id);
  };

  return (
    <>
      <div className={classes.header}>Leave Configuration</div>
      <div className={classes.cardContainer}>
        {leaveTypes.map((category) => (
          <div
            className={`${
              selectedItem === category.id ? classes.selected : ""
            }`}
            key={category.id}
            onClick={() => editLeaveHandler(category)}
          >
            <span className={`${category.icon} ${classes.icon}`}></span>
            <span>{category.name}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default List;