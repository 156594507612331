import { Popover } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import classes from "./FormSelect.module.scss"
import SearchBar from "./SearchBar";
import { useCallback } from "react";
import { alphabetSort, setSearchValues } from "../../utils/utils";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";

const FormSelect = ({
  options,
  handleChange,
  optionLabel,
  optionValue,
  selectedOptionLabel,
  value,
  sortBy,
  searchBy,
  disabled,
  noneValue = '',
  askConfirmation = {when: false, message: '', onConfirm:()=>{}, onCancel:()=>{}}}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState(value || 'None');
  const [selection, setSelection] = useState(null);
  const [sortedOptions, setSortedOptions] = useState(setSearchValues(alphabetSort({array:options, properties:sortBy ?? []}) ?? [], searchBy));
  const [filteredOptions, setFilteredOptions] = useState(sortedOptions ?? []);
  const [filterQuery, setFilterQuery] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    setSortedOptions(setSearchValues(alphabetSort({array: options, properties: sortBy ?? []}) ?? [], searchBy));
  }, [options]);

  useEffect(()=>{
    if(selection === null) {
      setInputValue('None');
    } else {
      let updatedOption = options.find(opt => opt.id === selection.id);
      setInputValue(selectedOptionLabel ? selectedOptionLabel(updatedOption) : optionLabel(updatedOption));
    }
  },[options, selection])

  useEffect(() => {
    setFilteredOptions(sortedOptions.filter(option =>
      option.searchValues?.some(value => value.includes(filterQuery) ?? true)
    ));
  }, [sortedOptions, filterQuery])

  useEffect(() => {
    if (value === noneValue) {
      setSelection(null);
    }
    let option = options.find(option => optionValue(option) === value)
    if (option) {
      setSelection(option);
    }
  }, [value])

  const handleClick = (event) => {
    if (!disabled) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterQuery('');
  };


  const [optionAttempt, setOptionAttempt] = useState(null);

  const optionSelected = (option) => {
    if (askConfirmation.when) {
      setOptionAttempt(option);
      setShowConfirmationDialog(true);
    } else {
      setSelection(option);
      handleChange(optionValue(option));
      handleClose();
    }
  }

  const handleConfirm = () => {
    setSelection(optionAttempt);
    handleChange(optionValue(optionAttempt));
    askConfirmation.onConfirm(optionValue(optionAttempt));
    setShowConfirmationDialog(false);
    setOptionAttempt(null);
    handleClose();
  }

  const handleCancel = () => {
    setShowConfirmationDialog(false);
    handleClose();
  }

  const noneSelected = () => {
    setSelection(null);
    if (handleChange) handleChange(noneValue);
    handleClose();
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const search = useCallback((value) => {
    setFilterQuery(value.toLowerCase());
  }, [setFilterQuery]);


  return (
    <div className={classes.formSelect}>
      <div className={`${classes.inputContainer} ${disabled ? '' : classes.enabledSelect}`} onClick={handleClick}>
        <input value={''} aria-describedby={id} readOnly />
        <div className={`${classes.selection} ${inputValue === 'None' ? classes.italic : ''}`}>
          <div>{inputValue}</div>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.list}>
          <div className={classes.searchBar}>
            <SearchBar onChange={search} />
          </div>
          <span className={classes.italic} onClick={noneSelected} key={0}>None</span>
          {filteredOptions.map((option, index) =>
            <span key={index + 1} onClick={() => optionSelected(option)}>{optionLabel(option)}</span>
          )}
        </div>
      </Popover>
     {showConfirmationDialog && (
        <DeleteConfirmationDialog
          message={askConfirmation.message}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          zIndex={10000}
        />
      )}
    </div>
  );
}

export default FormSelect;