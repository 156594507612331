import React, { useEffect, useState } from "react";
import classes from "./RoleActions.module.scss";
import ResourceComponent from "./ResourceComponent";
import { useSelector, useDispatch } from "react-redux";
import {
  selectedRoleItem,
  globalRadioOption,
  fetchLocations,
  locations,
  switchLocation,
  functionalities,
  fetchFunctionalities,
  switchAction,
} from "../../features/rolesSlice";
import { resourcePermissions, checkPermission } from '../../features/resourcePepermissionsSlice';
import { role } from "../../features/userSlice";

const RoleActions = () => {
  const dispatch = useDispatch();
  const currentResourcePermissions = useSelector(resourcePermissions('role-administration'));
  const [selectedTab, setSelectedTab] = useState("Functionalities");
  const locationsArray = useSelector(locations);
  const functionalitiesArray = useSelector(functionalities);
  const selectedItem = useSelector(selectedRoleItem);

  const isFolder = selectedItem.isFolder;
  const globalOption = useSelector(globalRadioOption);
  const disabled = selectedItem.isFolder || globalOption === "admin" || (Object.keys(selectedItem.item).length === 0);

  const [disabledTabs, setDisabledTabs] = useState([]);

  const currentUserRole = useSelector(role);

  const isRoleHigher = ()=>{
    let currentType = currentUserRole?.type;
    let editType = selectedItem.item?.type;
    return (currentType === 'admin') || (currentType === 'lead' && editType !== 'admin') || (currentType === 'regular_user' && editType === 'regular_user');
  }

  const editPermission = (!isFolder && checkPermission(currentResourcePermissions, 'edit-role')) && isRoleHigher();


  useEffect(() => {
    let newDisabledTabs = globalOption === "lead" ? ["Functionalities"] : [];
    if (JSON.stringify(newDisabledTabs) !== JSON.stringify(disabledTabs)) setDisabledTabs(newDisabledTabs);
  }, [globalOption, disabledTabs]);

  useEffect(() =>{
    disabledTabs.includes("Functionalities")
      ? setSelectedTab("Locations")
      : setSelectedTab("Functionalities");
  }, [disabledTabs])

  const tabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchFunctionalities());
  }, [dispatch]);

  const locationOptionChange = (newValue, action) => {
    dispatch(switchLocation({ locationId: action.id, enabled: newValue }));
  };

  const actionChange = (newValue, action) => {
    dispatch(switchAction({ actionId: action.id, enabled: newValue }));
  };

  return (
    <div
      id="roleActionsContainer"
      className={`${classes.roleActionsContainer} ${disabled ? classes.lowOpacity : ""}`}
      onClick={(event)=>event.stopPropagation()}
    >
      <div className={classes.header}>
        {!disabledTabs.includes("Functionalities") && (
          <div
            onClick={(event) => {
              event.stopPropagation();
              tabClick("Functionalities");
            }}
            className={`${classes.tab} ${
              selectedTab === "Functionalities" && classes.activeTab
            }`}
          >
            Functionalities
          </div>
        )}
        {!disabledTabs.includes("Locations") && (
          <div
            onClick={() => {
              tabClick("Locations");
            }}
            className={`${classes.tab} ${
              selectedTab === "Locations" && classes.activeTab
            }`}
          >
            Locations
          </div>
        )}
      </div>
      <div className={classes.body}>
        {selectedTab === "Functionalities" &&
          functionalitiesArray.map((resource, index) => {
            return (
              <ResourceComponent
                resourceItem={resource}
                key={index}
                optionChange={actionChange}
                disabled = {!editPermission}
              />
            );
          })}
        {selectedTab === "Locations" && (
          <ResourceComponent
            hideResourceBar={true}
            resourceItem={{ label: "locations", actions: locationsArray }}
            actionsBorder={false}
            actionBorder={true}
            showSearchBar={true}
            optionChange={locationOptionChange}
            disabled = {!editPermission}
          />
        )}
      </div>
    </div>
  );
};

export default RoleActions;