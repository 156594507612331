import React from 'react';
import {useContext} from "react";
import classes from './ShiftsBar.module.scss';
import userRegisterContext from "../store/user-register-context";
import DayShiftsSummary from './DayShiftsSummary';


const ShiftsBar = () => {

  const userRegisterCtx = useContext(userRegisterContext);
  const weekRequired = userRegisterCtx.allocatedShiftsData?.required ?? 0;
  const weekAllocated = userRegisterCtx.allocatedShiftsData?.allocated ?? 0;
  return (
    <div id="weekSummary" className={classes.shiftsBar}>
      <div className={classes.totalSummary}>
        <div className={classes.totalLabel}>Total</div>
        <div className={classes.dayAndWeek}>
          <div className={classes.weekChip}>
            <span className={classes.dayLabel}>WEEK</span>
            <div className={classes.weekSummary}>
              <div className={classes.dayShiftInfo}>
                <span className={classes.shiftsLabel}>Shifts</span>
                <span className={classes.shiftsCount}>{weekRequired}</span>
              </div>
              <div className={classes.dayShiftInfo}>
                <span className={classes.shiftsLabel}>Allocated</span>
                <span className={`${classes.allocatedCount} ${weekAllocated<weekRequired ? classes.incomplete: classes.complete}`}>{weekAllocated}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className={classes.dailySummary}>
        {Array(7).fill(null).map((day, index) => {
          return (
            <DayShiftsSummary key={index} index={index}/>
            )
        })}
      </div>
    </div>
  )
}

export default ShiftsBar;