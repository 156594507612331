import { Popover } from "@mui/material";
import React from "react";
import classes from './PopOverDropdown.module.scss';

const PopOverDropdown = ({ options, defaultValue, icon, onClick, disabled, disabledPlaceholder, displayProperty }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickOption = (option) => {
		onClick(option);
		handleClose();
	}

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<div className={`${classes.toggle} ${disabled ? classes.disabled:''}`} aria-describedby={id} variant="contained" onClick={handleClick}>
				{defaultValue ? <div className={classes.selection}>
					{Boolean(icon) && <i className={icon}></i>}
					<span>
						{defaultValue?.[displayProperty] ?? defaultValue?.label ?? defaultValue?.name ?? defaultValue}
					</span>
				</div> :
					<span className={classes.placeholder}>{disabled ? disabledPlaceholder : 'Select'}</span>
				}
				<i className="icon-down"></i>

			</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div className={classes.options}>
					{options?.map((option, key) => <div onClick={()=>handleClickOption(option)} className={classes.option} key={key}>
						{option?.[displayProperty] ?? option?.label ?? option?.name ?? option}
					</div>)}
				</div>
			</Popover>
		</>
	);
}

export default PopOverDropdown;