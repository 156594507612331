import React, { useContext, useState } from "react";
import UserRegister from "../Users/UserRegister";
import classes from "./WeekDays.module.scss";
import WorkShiftConfig from "../Users/WorkShiftConfig";
import useWeekDaysState from "../hooks/useWeekDaysState";
import { useDispatch, useSelector } from "react-redux";
import {
  resourcePermissions,
  checkPermission,
} from "../../features/resourcePepermissionsSlice";
import { allLocations } from "../../features/currentDataSlice";
import LeaveSlot from "../standard/LeaveSlot";
import { HOME_ID } from "../services/global";
import MultiOptionDialog from "../UI/MultiOptionDialog";
import { Tooltip } from "@mui/material";
import { formatDate } from "../../utils/utils";
import { cancelEmployeeTransfer } from "../services/userRegisterService";
import { showToast } from "../../features/toastNotificationSlice";
import UserRegisterContext from "../store/user-register-context";
import WorkShiftConfig2 from "../Users/WorkShiftConfig2";

const WeekDays = (props) => {
  const loadedLocations = useSelector(allLocations);
  const {
    isWorkShiftShown,
    userShift,
    user,
    showWorkShiftHandler,
    hideShowWorkShiftHandler,
  } = useWeekDaysState(props.shifts);

  const currentResourcePermissions = useSelector(
    resourcePermissions("week-view")
  );

  const [openDialog, setOpenDialog] = useState(null);
  const dispatch = useDispatch();
  const userRegisterCtx = useContext(UserRegisterContext);

  const cancelTransferHandler = (info) => {
    setOpenDialog(info);
  }

  const onDeleteTransfer = async () => {
    const response = await cancelEmployeeTransfer(openDialog.transferId, openDialog.shiftDate);
    if (response?.responseCode === 200) {
      dispatch(showToast({ type: 'success', message: 'Transfer removed' }));
      userRegisterCtx.changeWeek(userRegisterCtx.selectedWeek);
    } else {
      dispatch(showToast({ type: 'warning', message: 'Something went wrong while removing the transfer' }))
    }
    setOpenDialog(null);
  }

  const openShifts = (shift) => {
    if (props.isUserTransfer) {
      if (shift.avaibleInTransfer) {
        if (shift.id) {
          showWorkShiftHandler(shift);
        } else {
          if (checkPermission(currentResourcePermissions, 'create-shift'))
            showWorkShiftHandler(shift);
        }
      }
    } else if (!shift.leave && !shift.transferTo) {
      if (shift.id) {
        showWorkShiftHandler(shift);
      } else {
        if (checkPermission(currentResourcePermissions, 'create-shift'))
          showWorkShiftHandler(shift);
      }
    }
  }

  return (
    <div className={classes.weekDaysContainer}>
      {props.shifts.shifts &&
        props.shifts.shifts.map((shift) => {
          const transferLocation = loadedLocations.find(
            (obj) => obj.id === shift?.transferTo
          );
          return (
            <div
              id={
                shift.scheduleTime && shift.site
                  ? "weekDaySlot"
                  : !shift.leave
                    ? "weekDayEmptySlot"
                    : "weekDayLeaveSlot"
              }
              key={shift.id || shift.shiftDate}
              onClick={() => { openShifts(shift) }}
              className={
                shift.scheduleTime && shift.site
                  ? classes.slot
                  : !shift.leave
                    ? classes.emptySlot
                    : classes.leaveSlot
              }
            >
              {shift.scheduleTime &&
                shift.site &&
                !shift.leave &&
                !shift.transferT && <UserRegister register={shift} />}
              {!shift.scheduleTime &&
                !shift.site &&
                !shift.leave &&
                !shift.transferTo && !props.isUserTransfer && (
                  <i className={`icon-options ${classes.iconOptions}`}></i>
                )}
              {props.isUserTransfer && !shift.scheduleTime &&
                !shift.site &&
                !shift.leave &&
                !shift.transferTo && shift.avaibleInTransfer && (
                  <i className={`icon-options ${classes.iconOptions}`}></i>
                )}
              {shift.transferTo &&
                transferLocation &&
                !shift.scheduleTime &&
                !shift.site &&
                !shift.leave && (
                  <div className={classes.transferContainer}>
                    <span
                      className={`icon-icon-relocated ${classes.iconRelocated}`}
                    ></span>
                    <span className={classes.label}>
                      Relocated to {transferLocation.label}
                    </span>
                    {checkPermission(currentResourcePermissions, 'delete-transfer-user') && <Tooltip title={"Cancel transfer"}>
                      <i onClick={() => cancelTransferHandler({ ...shift, userName: props.shifts.userName })} className="icon-close" />
                    </Tooltip>}
                  </div>
                )}
              {!shift.avaibleInTransfer && props.isUserTransfer && (
                <div className={classes.transferContainer}>
                  <span
                    className={`icon-icon-relocated ${classes.iconRelocated}`}
                  ></span>
                  <span className={classes.label}>
                    At Origin Airport
                  </span>
                </div>
              )}
              {shift.leave && <LeaveSlot leaveInfo={{ ...shift.leave, mainType: shift.leave.vacation === true ? "V" : shift.leave.leaveType.type }} page={HOME_ID} />}
            </div>
          );
        })}
      {isWorkShiftShown && (
        <WorkShiftConfig2
          shift={userShift}
          user={user}
          onClose={hideShowWorkShiftHandler}
          showTransferUser={true}
          openFrom={"week-view"}
        />
      )}
      {openDialog && <MultiOptionDialog
        message={`Are you sure you want to cancel the transfer for ${openDialog.userName} on ${formatDate(openDialog.shiftDate)}?`}
        options={[
          { label: 'Confirm', callback: onDeleteTransfer },
        ]}
        onClose={() => setOpenDialog(false)}
      />}
    </div>
  );
};

export default WeekDays;