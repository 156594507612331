import React, { useState } from "react";
import classes from "./ResourceComponent.module.scss";
import SwitchButton from "../UI/switchButton";

const ResourceComponent = (props) => {
  function getResourceImage(key) {
    switch (key) {
      case "week-view":
        return "icon-calendar";
      case "month-view":
        return "icon-user-calendar";
      case "pattern":
        return "icon-user-shift-pattern";
      case "user-administration":
        return "icon-user-admin";
      case "role-administration":
        return "icon-role";
      default:
        return "icon-options";
    }
  }
  const resource = props.resourceItem;
  const [searchFilter, setSearchFilter] = useState("");
  const hideResourceBar = props.hideResourceBar ?? false;
  const [open, setOpen] = useState(props.hideResourceBar ?? false);
  const actionsBorder = props.actionsBorder ?? true;
  const actionBorder = props.actionBorder ?? false;
  const showSearchBar = props.showSearchBar ?? false;
  const disabled = props.disabled ?? false;
  return (
    <div className={classes.content}>
      {!hideResourceBar && (
        <div
          onClick={(event) => {
            event.stopPropagation()
            setOpen(!open);
          }}
          className={`${classes.header} ${open && classes.openHeader}`}
        >
          <div className={classes.labelContainer}>
            <span
              className={` ${getResourceImage(resource.key)} ${
                classes.resourceImage
              }`}
            />
            <div>{resource.label}</div>
          </div>
          <span className={`${open ? classes.openArrow : classes.closedArrow}`}>
            ◥
          </span>
        </div>
      )}

      {showSearchBar && (
        <div className={classes.inputField}>
          <input
            className={classes.customInput}
            placeholder="Search"
            onChange={(event) => setSearchFilter(event.target.value)}
          />
          <span className={`icon-search ${classes.searchIcon}`}></span>
        </div>
      )}

      {open && (
        <div
          className={`${classes.actions} ${actionsBorder && classes.border}`}
        >
          {resource.actions
            .filter((action) =>
              action.label.toLowerCase().includes(searchFilter.toLowerCase())
            )
            .map((action, index) => {
              return (
                <div
                  className={`${classes.action} ${
                    actionBorder && classes.borderBottom
                  } ${false && classes.disabledOption}`}
                  key={index}
                >
                  <div className={classes.actionLabel}> {action.label}</div>
                  <SwitchButton
                    defaultChecked={action.enabled}
                    onChange={(newValue) => {
                      props.optionChange(newValue, action);
                    }}
                    disabled={disabled}
                    onClick={(event)=>event.stopPropagation()}
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default ResourceComponent;
