import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import classes from "./CreationDialog.module.scss";
import { MenuItem, Select } from "@mui/material";
import useSites from "../hooks/useSites";

export default function CreationDialog(props) {
  const title = props.title ?? "New";
  const [nameInput, setNameInput] = useState(props.element?.groupName ?? '');
  const [siteValue, setSiteValue] = useState(props.element?.site ?? '');
  const sites = useSites();

  useEffect(() => {
    setNameInput(props.element?.groupName ?? '');
    setSiteValue(props.element?.site ?? '');
  }, [props.element])

  const handleChangeSite = (event) => {
    setSiteValue(event.target.value);
  }

  const onClose = (event, create) => {
    event.stopPropagation();
    props.onClose(
      event,
      create,
      props.element ?
        { ...props.element, groupName: nameInput || props.defaultName || "New", site: siteValue } :
        { groupName: nameInput || props.defaultName || "New", site: siteValue, employees: [] });
    setNameInput("");
    setSiteValue("");
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={(event) => {
          onClose(event, false);
        }}
      >
        <DialogContent
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div
            className={classes.dialogContainer}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div
              className={classes.header}
              onClick={(event) => event.stopPropagation()}
            >
              <div className={classes.title}>
                <span className={`${props.icon ?? 'icon-more'}`}></span>
                {title}
              </div>
              <span
                className={classes.closeButton}
                onClick={(event) => {
                  onClose(event, false);
                }}
              >
                ✕
              </span>
            </div>
            <div className={classes.form}>
              <div
                className={classes.field}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <div className={classes.nameLabel}>
                  {props.nameLabel ?? "Name"}
                </div>
                <input
                  className={classes.nameInput}
                  value={nameInput}
                  onChange={(event) => setNameInput(event.target.value)}
                  placeholder={props.placeholder ?? ""}
                />
              </div>
              <div
                className={classes.field}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <div className={classes.nameLabel}>
                  {"Site"}
                </div>
                <Select value={siteValue} onChange={handleChangeSite} sx={{ fontFamily: 'Poppins', fontSize: '0.9rem', height: '3em' }}>
                  {sites.map((option, index) =>
                    <MenuItem value={option.name} key={index}>
                      {option.name}
                    </MenuItem>)}
                </Select>
              </div>
            </div>
            <div
              className={classes.buttonsBar}
              onClick={(event) => event.stopPropagation()}
            >
              <div
                className={classes.createButton}
                onClick={(event) => {
                  onClose(event, true, nameInput);
                }}
              >
                {'Save'}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
