import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classes from "./MonthViewContainer.module.scss";
import useFilteredUserGroups from "../hooks/useFilteredUserGroups";
import { currentMonthDataState } from "../../features/monthViewSlice";
import MonthViewHeader from "./MonthViewHeader";
import MonthView from "./MonthView";
import { fetchResourcePermissions } from "../../features/resourcePepermissionsSlice";
import { checkAccess } from "../services/userRegisterService";
import { alphabetSort } from "../../utils/utils";
import useFetchAirCrafts from "../hooks/useFetchAirCrafts";

const MonthViewContainer = () => {
  const currentMonthData = useSelector(currentMonthDataState);
  const [updatedMonth, setUpdatedMonth] = useState(null);
  const [updatedYear, setUpdatedYear] = useState(null);
  const dispatch = useDispatch();
  const {
    isFilterOpen,
    filterUsers,
    searchQuery,
    filteredUserGroups,
    filteredEmployeesBySearch,
    handleFilterToggle,
    handleSearchChange,
  } = useFilteredUserGroups(currentMonthData);

  useEffect(() => {
    dispatch(fetchResourcePermissions('month-view'));
  },[dispatch])

  const updateMonth = (monthName) => {
    setUpdatedMonth(monthName);
  }

  const updateYear = (year) => {
    setUpdatedYear(year);
  }

  const [hasAccess, setHasAccess] = useState(false);

  useEffect(()=>{
    async function checkPageAccess() {
      let access = await checkAccess('month-view', true);
      setHasAccess(access);
      if(!access) window.location.href = "/home";
    }
    checkPageAccess();
  }, []);

  useFetchAirCrafts();

  return (hasAccess &&
    <>
      <MonthViewHeader
        searchQuery={searchQuery}
        isFilterOpen={isFilterOpen}
        handleSearchChange={handleSearchChange}
        handleFilterToggle={handleFilterToggle}
        updatedMonth={updatedMonth}
        updatedYear={updatedYear}
      />
      <div className={classes.monthViewMargin}>
        <MonthView
          filterUsers={filterUsers}
          filteredUserGroups={filteredUserGroups}
          filteredEmployeesBySearch={filteredEmployeesBySearch}
          searchQuery={searchQuery}
          updateMonth={updateMonth}
          updateYear={updateYear}
        />
      </div>
    </>
  );
};

export default MonthViewContainer;