import React, { useState, useEffect } from 'react';
import classes from './SwitchButton.module.scss';

const SwitchButton = (props) => {

  const [checked, setChecked] = useState(props.defaultChecked ?? false);
  const disabled = props.disabled ?? false;
  const change = (event) => {
      props.onChange(event.target.checked);
      setChecked(event.target.checked);
  }

  useEffect(() => {
    setChecked(props.defaultChecked)
  }, [props.defaultChecked])

  return (
    <label className={`${classes.switch} ${disabled?classes.disabled:classes.clickable}`} onClick={props.onClick}>
      <input onChange={change} type="checkbox" checked={checked} disabled={disabled}/>
      <span className={`${classes.slider} ${classes.round}`} style={{backgroundColor: checked ? '#00ca9a': (props.disabledColor ?? '#ccc') }}></span>
    </label>
  )
}

export default SwitchButton;