import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import classes from "./userRole.module.scss";
import UserRoleCard from "./UserRoleCard";
import { useDrop } from "react-dnd";

const RoleUser = ({ addRolesToUser, selectedEmployee, assignedRoles, roleOptions, roleUsers }) => {
  const [open, setOpen] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [roles, setRoles] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);

  const handleClickOpen = async () => {
    setOpen(true);
    try {
      const response = roleOptions;
      if (response) {
        const rolesFormatted = assignedRoles.map((role) => role.role) || [];
        setDroppedItems(rolesFormatted);
        if (rolesFormatted.length > 0) {
          const filteredResponse = response.filter((role) => {
            return !rolesFormatted.some((item) => item.id === role.id);
          });
          setRoles(filteredResponse);
          setFilteredRoles(filteredResponse);
        } else {
          setRoles(response);
          setFilteredRoles(response);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleClose = () => {
    addRolesToUser(droppedItems);
    setOpen(false);
    setRoles([]);
    setDroppedItems([]);
    setFilteredRoles([]);
  };

  // Drag and Drop logic
  const [, drop] = useDrop({
    accept: "UserRoleCard",
    drop: (item) => {
      const updatedRole = roles.filter((role) => role.id !== item.id);
      setFilteredRoles(updatedRole);
      setRoles(updatedRole);
      setDroppedItems((prevItems) => [...prevItems, item]);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const removeRoleItem = (role) => {
    const updatedDroppedRole = droppedItems.filter(
      (item) => role.id !== item.id
    );
    setDroppedItems(updatedDroppedRole);
    setFilteredRoles((prevItems) => [role, ...prevItems]);
    setRoles((prevItems) => [role, ...prevItems]);
  };

  const handleSearchChange = (event) => {
    setSearchUser(event.target.value);
    const query = event.target.value.toLowerCase();

    if (query.length > 0) {
      const filterRoles = roles.filter((role) =>
        role.name.toLowerCase().includes(query)
      );
      setFilteredRoles(filterRoles);
    } else {
      setFilteredRoles(roles);
    }
  };

  return (
    <>
      <button className={classes.roleAndSaveButton} onClick={handleClickOpen}>
        <span className={`icon-role ${classes.roleIcon}`}></span>
        <span className={classes.buttonLabel}>User Role</span>
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionProps={{
          timeout: 700,
          transitiontimingfunction: "ease",
        }}
        PaperProps={{
          style: { maxWidth: "55rem", minWidth: "55rem" },
        }}
      >
        <DialogContent>
          <div className={classes.headerContainer}>
            <span className={classes.title}>
              <span className={`icon-assigned-role ${classes.icon}`}></span>
              Add/Remove Roles {(selectedEmployee?.name ? "-" : "") + (selectedEmployee?.name ?? "") + " " + (selectedEmployee?.lastName ?? "")}
            </span>
            <button onClick={handleClose} className={classes.confirmButton}>
              Ok
            </button>
          </div>
          <div className={classes.container}>
            <div className={classes.roleListContainer}>
              <div>
                <div className={classes.leftContainerHeader}>
                  <input
                    placeholder="Search Role"
                    className={`${classes.inputUser} ${classes.searchIcon}`}
                    value={searchUser}
                    onChange={handleSearchChange}
                  ></input>
                </div>
                <div className={classes.listContainer}>
                  {filteredRoles.length > 0 &&
                    filteredRoles.map((role) => (
                      <UserRoleCard key={role.id} role={role} roleUsers={roleUsers}/>
                    ))}
                </div>
              </div>
            </div>
            <div className={classes.dragAndDrop}>
              <span className={classes.header}>Assigned Roles</span>
              <div className={classes.listContainer}>
                <div ref={drop}>
                  <div className={classes.dragAndDropHeader}>
                    drop to assign role
                  </div>
                </div>
                {droppedItems.length > 0 &&
                  droppedItems.map((item) => (
                    <UserRoleCard
                      key={item.id}
                      role={item}
                      isDroppedItem={true}
                      removeRoleItem={removeRoleItem}
                      roleUsers={roleUsers}
                    />
                  ))}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RoleUser;