import React from "react";
import NoUsers from "../Users/NoUsers";
import InfiniteScroll from "./InfiniteScroll";

const MonthView = ({
  filterUsers,
  filteredUserGroups,
  filteredEmployeesBySearch,
  searchQuery,
  updateMonth,
  updateYear
}) => {


  let showNoUser = false;
  if (
    filterUsers?.length > 0 &&
    filteredUserGroups?.length > 0 &&
    !(searchQuery?.length > 0 && filteredEmployeesBySearch?.length === 0)
  ) {
    showNoUser = false;
  } else {
    showNoUser = true;
  }

  const infiniteScroll = () => {
    return (
      <InfiniteScroll
        users={filterUsers}
        updateMonth={updateMonth}
        updateYear={updateYear}
      />)
  }

  return (
    <>
      {!showNoUser && (
        <>
          {infiniteScroll()}
        </>
      )}
      {showNoUser && <NoUsers top={60} bottom={0} />}
    </>
  );
};

export default MonthView;