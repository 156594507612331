import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import UserRegisterProvider from "./components/store/userRegisterProvider";
import store from "./app/store";
import { BrowserRouter as Router } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Provider store={store}>
      <UserRegisterProvider>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </UserRegisterProvider>
    </Provider>
  </Router>
);
