import React, { useState } from "react";
import classes from "./LeaveReasonAdmin.module.scss";
import { saveLeaveReason } from "../services/leaveAdminService";
import InformationDialog from "../UI/InformationDialog";
import SwitchButton from "../UI/switchButton";

const LeaveReasonAdmin = ({
  leaveState,
  setLeaveStateHandler,
  updateLeaveState,
  fetchLeavesHandler,
}) => {
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [message, setMessage] = useState("");

  const handleReasonChange = (index, value) => {
    const updatedLeaveState = { ...leaveState };
    updatedLeaveState.leaves[index].name = value;
    updateLeaveState(updatedLeaveState);
  };

  const addNewReasonHandler = () => {
    const newLeave = {
      id: null,
      label: "",
      name: "",
      type: leaveState.type,
    };
    setLeaveStateHandler(newLeave);
  };

  const saveReasonHandler = async() => {
    try {
      const response = await saveLeaveReason(leaveState.leaves);
      if (response.responseCode === 200) {
        leaveState.leaves = response.load;
        setMessage(leaveState.successSaveMessage ?? "Leave reason saved!");
      } else {
        setMessage("Something went wrong!");
      }
      setOpenSaveDialog(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onCancelSaveDialog = () => {
    setOpenSaveDialog(false);
    fetchLeavesHandler(leaveState);
  };

  const activeSwitchHandler = (newValue, index) => { 
    leaveState.leaves[index].enabled = newValue;
  }

  return (
    <>
      <div className={classes.header}>
        <div className={classes.title}>
          <span className={`${leaveState.icon} ${classes.icon}`}></span>
          <span>{leaveState.name}</span>
          <button
            className={classes.newReasonBtn}
            onClick={() => addNewReasonHandler()}
          >
           {"+ " + (leaveState.newButtonLabel ?? "New")}
          </button>
        </div>
        <button className={classes.saveBtn} onClick={() => saveReasonHandler()}>
          Save
        </button>
      </div>
      <div className={classes.body}>
        {leaveState?.leaves.map((element, index) => (
          <div key={index} className={classes.card}>
            <span>Type</span>
            <input
              className={classes.inputForm}
              type="text"
              value={element.name}
              onChange={(e) => handleReasonChange(index, e.target.value)}
            />
            <div className={classes.switchContainer}>
              <SwitchButton
                onChange={(newValue) => {
                  activeSwitchHandler(newValue, index);
                }}
                defaultChecked={element.enabled}
                disabled={!element.id}
              />
            </div>
          </div>
        ))}
      </div>
      {openSaveDialog && (
        <InformationDialog
          message={message}
          onCancel={onCancelSaveDialog}
        />
      )}
    </>
  );
};

export default LeaveReasonAdmin;
