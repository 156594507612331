import React from "react";
import classes from "./AppliedPatterns.module.scss";
import SwitchButton from "../UI/switchButton";
import OverflowList from "../UI/OverflowList";
import { useDispatch, useSelector } from "react-redux";
import {
  appliedPatternState,
  approveAppliedPatterns,
  enableAppliedPattern,
  removeAppliedPattern,
} from "../../features/shiftPatternSlice";
const AppliedPatterns = (props) => {
  const dispatch = useDispatch();
  const patterns = useSelector(appliedPatternState);

  const enablePattern = (patternId, newValue) => {
    dispatch(enableAppliedPattern({ patternId, newValue }));
  };

  const approveEnabledPatterns = () => {
    let enabledIds = patterns.filter((p) => p.aproved).map((p) => p.id);
    dispatch(approveAppliedPatterns(enabledIds));
  };

  const deleteAppliedPattern = (pattern) => {
    dispatch(removeAppliedPattern(pattern));
  }

  return (
    <div className={classes.container}>
      <div className={classes.buttonsBar}>
        <button onClick={approveEnabledPatterns} className={classes.saveButton}>
          Save
        </button>
        <button className={classes.closeButton} onClick={props.onClose}>
          ╳
        </button>
      </div>
      <div className={classes.card}>Applied Patterns</div>
      <div className={classes.patternList}>
        {patterns.length > 0 ? (
          patterns.map((p, i) => (
            <div
              key={i}
              className={`${classes.card} ${classes.pattern} ${p.aproved ? classes.enabled : ""
                }`}
            >
              <div className={classes.patternTitle}>
                <span>
                  Pattern {i + 1}
                </span>
                <i onClick={() => deleteAppliedPattern(p)} className="icon-delete"></i>
              </div>
              <div className={classes.patternContent}>
                <div className={classes.patternInfo} id={`pattern${i}`}>
                  <div className={classes.datesContainer}>
                    <div className={classes.dateInfo}>
                      <span
                        className={`${classes.dateTitle} ${p.aproved ? classes.enabled : ""
                          }`}
                      >
                        Initial Date
                      </span>
                      <span className={classes.date}>
                        {formatDate(p.initialDate)}
                      </span>
                    </div>
                    <div className={classes.dateInfo}>
                      <span
                        className={`${classes.dateTitle} ${p.aproved ? classes.enabled : ""
                          }`}
                      >
                        Final Date
                      </span>
                      <span className={classes.date}>
                        {formatDate(p.finalDate)}
                      </span>
                    </div>
                  </div>
                  <OverflowList
                    items={p.employees}
                    containerId={`pattern${i}`}
                  />
                </div>
                <div className={classes.switchContainer}>
                  <SwitchButton
                    disabledColor={"black"}
                    onChange={(newValue) => {
                      enablePattern(p.id, newValue);
                    }}
                    defaultChecked={p.aproved}
                  />
                  <div className={classes.switchLabel}>ACTIVATE PATTERN</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={classes.placeholder}>
            No patterns pending for approval
          </div>
        )}
      </div>
    </div>
  );
};

function formatDate(isoString) {
  const dateParts = isoString.split('T')[0].split('-');
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
}

export default AppliedPatterns;
