import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import classes from "./CreationDialog.module.scss";

export default function CreationDialog(props) {
  const isFolder = props.isFolder ?? true;
  const title = isFolder ? "New Folder" : "New Role";
  const [nameInput, setNameInput] = useState("");

  const onClose = (event, create, nameInput = "") => {
    event.stopPropagation();
    props.onClose(event, create, nameInput, isFolder);
    setNameInput("");
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={(event) => {
          onClose(event, false);
        }}
      >
        <DialogContent
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div
            className={classes.dialogContainer}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div
              className={classes.header}
              onClick={(event) => event.stopPropagation()}
            >
              <div className={classes.title}>
                {isFolder ? (
                  <span className="icon-folder-plus"></span>
                ) : (
                  <span className={`icon-role-plus ${classes.roleIcon}`}></span>
                )}
                {title}
              </div>
              <span
                className={classes.closeButton}
                onClick={(event) => {
                  onClose(event, false);
                }}
              >
                ✕
              </span>
            </div>
            <div
              className={classes.field}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <div className={classes.nameLabel}>
                {isFolder ? "Folder Name" : "Role Name"}
              </div>
              <input
                className={classes.nameInput}
                value={nameInput}
                onChange={(event) => setNameInput(event.target.value)}
                placeholder={
                  isFolder ? "Enter folder name..." : "Enter role name..."
                }
              />
            </div>
            <div
              className={classes.buttonsBar}
              onClick={(event) => event.stopPropagation()}
            >
              <div
                className={classes.createButton}
                onClick={(event) => {
                  onClose(event, true, nameInput);
                }}
              >
                Create
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
