import React, { useCallback, useEffect, useState } from "react";
import classes from './LocationShifts.module.scss';
import Modal from "../UI/Modal";
import LocationDropDown from "../standard/LocationDropdown";
import { ADMIN_LOCATIONS_ID } from "../services/global";
import SiteShifts from "./SiteShifts";
import { Dialog, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allLocations } from "../../features/currentDataSlice";
import useSites from "../hooks/useSites";
import useRequiredShifts from "../hooks/useRequiredShifts";
import useShiftForm from "../hooks/useShiftForm";
import Placeholder from "../UI/Placeholder";
import { cleanAllSites, fetchRequiredShifts, saveRequiredShifts, selectRequiredShifts } from "../../features/requiredShiftsSlice";

const LocationShifts = ({ locationId, onClose }) => {
  const locations = useSelector(allLocations);
  const [currentLocation, setCurrentLocation] = useState(locations.find(l => l.id === locationId));
  const { locationSites, locationSchedules } = useShiftForm({ shift: null, currentLocation });
  const dispatch = useDispatch();
  // const requiredInfo = useSelector(selectRequiredShifts);
 
  useEffect(() => {
    setCurrentLocation(locations.find(l => l.id === locationId));
  }, [locationId]);

  const changeLocation = (newLocation) => {
    setCurrentLocation(locations.find(l => l.id === newLocation.id));
  }
  
  const cleanLocation = () => {
    dispatch(cleanAllSites());
  }

  useEffect(()=>{
    if (typeof(currentLocation?.id) === 'number' && Object.keys(locationSites).length > 0) dispatch(fetchRequiredShifts({locationId:currentLocation?.id, locationSites}));
  },[locationSites]);

  const saveChanges = () => {
    dispatch(saveRequiredShifts({locationId: currentLocation.id}));
  }

  return (typeof (locationId) === 'number' &&
    <Modal onClose={onClose} className="locationShifts" contentClassName={classes.modalContent}>
      {/* <Modal onClose={onClose} className={classes.modal}> */}

      <div className={classes.modal}>
        <div className={classes.dialogHeader}>
          <div className={classes.headerInfo}>
            <div className={classes.iconTitle}><i className="icon-required-shifts" />
              <h1>Definition of weekly schedules</h1></div>
            <h2>Set the number of available shifts per week according to the work schedule and distribute this value accross the days of the week</h2>
          </div>
          <div className={classes.headerButtons}>
            <button className={classes.save} onClick={saveChanges}>Save</button>
            <button className={classes.iconButton} onClick={cleanLocation}><i className="icon-clean" /></button>
            <button className={classes.iconButton} onClick={onClose}><i className="icon-close" /></button>
          </div>
        </div>
        <LocationDropDown
          outlined
          className={classes.dropdown}
          global={false}
          pageId={ADMIN_LOCATIONS_ID}
          defaultId={locationId}
          onChange={changeLocation}
        />
        <Placeholder list={locationSites} placeholder={'No active sites in location'}>
          <div className={classes.cardContent}>
            {locationSites.map((site, i) =>
              <SiteShifts
                key={i}
                site={{ ...site, schedules: locationSchedules?.[site?.id] ?? [] }}
                locationId={locationId}
                updateSchedule={()=>{}}
                updateDay={()=>{}}
                requiredShiftsInfo={{}}
              />
            )}
          </div>
        </Placeholder>

      </div>

    </Modal >

  );

}

export default LocationShifts;