import React, { useRef, useState } from "react";
import Cookies from "js-cookie";
import classes from "./RecoverPassword.module.scss";
import forgotPassImg from "../../assets/images/forgot_password.png";
import zeusLogo from "../../assets/images/zeus_logo.png";
import InvalidLogin from "../standard/InvalidLogin";
import InformationDialog from "../UI/InformationDialog";
import { recoverEmailPassword } from "../services/userRegisterService";

const RecoverPassword = () => {
  const passwordRef = useRef(null);
  const repeatPassRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [message, setMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = passwordRef.current.value;
    const repeatPassword = repeatPassRef.current.value;
    const token = Cookies.get("RecoverPasstoken");

    if (password === repeatPassword) {
      if (token) {
        const data = {
          token: token,
          password: password
        }
        const response = await recoverEmailPassword(data);
        if (response) {
          setMessage("Your password has been changed");
          setInfoDialogOpen(true);
        }
      } else {
        setMessage("There is no existing token")
        setInfoDialogOpen(true);
      }
    } else {
      setDialogOpen(true);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <span className={classes.title}>Recover Password</span>
        <img src={forgotPassImg} alt="forgotpassword" />
        <span className={classes.info}>
          We have sent an email to your box, please follow the instructions to
          recover your password
        </span>
      </div>
      <div className={classes.loginContainer}>
        <img src={zeusLogo} alt="zeusLogo" />
        <form className={classes.LoginForm} onSubmit={(e) => handleSubmit(e)}>
          <span className={classes.text}>Password</span>
          <input
            ref={passwordRef}
            id="passwordInput"
            className={`${classes.inputContainer} ${classes.passwordIcon}`}
            type="password"
            placeholder="Password"
            minLength="5"
            required
          />
          <span className={classes.text}>Confirm Password</span>
          <input
            ref={repeatPassRef}
            id="repeatPasswordInput"
            className={`${classes.inputContainer} ${classes.passwordIcon}`}
            type="password"
            placeholder="Password"
            minLength="5"
            required
          />
          <button
            type="submit"
            id="submitButton"
            className={`${classes.buttons} ${classes.submitBtn}`}
          >
            Confirm
          </button>
        </form>
      </div>
      {dialogOpen && <InvalidLogin onClose={() => setDialogOpen(false)} />}
      {infoDialogOpen && (
        <InformationDialog
          message={message}
          onCancel={() => setInfoDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default RecoverPassword;