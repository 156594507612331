import { Fragment, useContext, useEffect, useState } from "react";
import classes from "./UserList.module.scss";
import UserCard from "../Users/UserCard";
import WeekDays from "../calendar/WeekDays";
import userRegisterContext from "../store/user-register-context";
import NoUsers from "../Users/NoUsers";
import WeekDaysMobile from "../calendar/WeekDaysMobile";
import { useSelector } from "react-redux";
import {
  resourcePermissions,
  checkPermission,
} from "../../features/resourcePepermissionsSlice";
import { selectPendingWeeks } from "../../features/publishSlice";

const UserList = ({
  filterUsers,
  searchQuery,
  filteredUserGroups,
  filteredEmployeesBySearch,
}) => {
  const currentResourcePermissions = useSelector(
    resourcePermissions("week-view")
  );
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showRegisterShifts, setShowRegisterShifts] = useState(false);

  const userRegisterCtx = useContext(userRegisterContext);

  // Find the selected employee by id
  const mobileEmployee = userRegisterCtx.currentWeekData
    .flatMap((group) => group.employees)
    .find((emp) => emp.employee.id === selectedEmployee?.employee?.id);

  const mobileEmployeeTransfer = userRegisterCtx?.transferUsers.find(
    (emp) => emp.employee.id === selectedEmployee?.employee?.id
  );

  const openUserRegisterShifts = (employeeId, isTransfer = false) => {
    // Find the selected employee by id
    let employee = {};
    if (!isTransfer) {
      employee = userRegisterCtx.currentWeekData
        .flatMap((group) => group.employees)
        .find((emp) => emp.employee.id === employeeId);
    } else {
      employee = userRegisterCtx?.transferUsers.find(
        (emp) => emp.employee.id === employeeId
      );
    }

    // If the same employee is clicked, toggle the register shifts
    if (selectedEmployee && selectedEmployee.employee.id === employeeId) {
      setShowRegisterShifts(!showRegisterShifts);
    } else {
      // Otherwise, select the new employee and show their register shifts
      setSelectedEmployee(employee);
      setShowRegisterShifts(true);
    }
  };

  let showNoUser = false;
  if (userRegisterCtx.transferUsers.length === 0) {
    if ((searchQuery.length > 0 && filteredEmployeesBySearch.length === 0) ||
      (userRegisterCtx.currentWeekData.length === 0 ||
        filteredUserGroups.length === 0)) {
      showNoUser = true;
    } else {
      showNoUser = false;
    }
  } else {
    showNoUser = false;
  }

  
  // adjust style if pending banner appears
  const pendingWeeks = useSelector(selectPendingWeeks);
  const [isWeekPending, setIsWeekPending] = useState(false);
  useEffect(() => {
    let weekStart = userRegisterCtx?.selectedWeek?.startDate?.toISOString()?.split('T')?.[0] ?? 'no-week';
    setIsWeekPending(pendingWeeks.includes(weekStart));
  }, [pendingWeeks, userRegisterCtx]);
  

  return (
    <Fragment>
      {userRegisterCtx?.transferUsers?.length > 0 && (
        <Fragment>
          <div className={classes.transferContainer}>
            <span
              className={`icon-transfer-user ${classes.transferIcon}`}
            ></span>
            <span>Users Transfers</span>
          </div>
          {userRegisterCtx.transferUsers.map((transferUser, index) => {
            const isSelectedEmployeeTransfer =
              mobileEmployeeTransfer &&
              mobileEmployeeTransfer.employee.id === transferUser.employee.id;
            return (
              <div
                key={`transfer-${transferUser.employee.id}`}
                className={classes.contentRowContainer}
              >
                <UserCard
                  openUserRegisterShifts={openUserRegisterShifts}
                  key={transferUser.employee.id}
                  employee={transferUser.employee}
                  isTransfer={true}
                  userTransfers={transferUser}
                />
                <WeekDays
                  key={transferUser.id}
                  shifts={transferUser.employee}
                  isUserTransfer={true}
                />
                {isSelectedEmployeeTransfer && showRegisterShifts && (
                  <WeekDaysMobile
                    key={index + 1}
                    shifts={mobileEmployeeTransfer.employee}
                    isUserTransfer={true}
                  />
                )}
              </div>
            );
          })}
        </Fragment>
      )}
      {filterUsers?.map((userGroup) => {
        return (
          <Fragment key={userGroup.id}>
            <div
              className={`${
                checkPermission(currentResourcePermissions, "day-summary")
                  ? classes.groupContainer
                  : classes.groupContainerNoSummary
              } ${isWeekPending ? classes.pendingWeek: ''}`}
            >
              {userGroup.id === 'transfer' && <span
                className={`icon-transfer-user ${classes.transferIcon}`}
              ></span>}
              <span>{`${userGroup.groupName}${userGroup.site ? ` - ${userGroup.site}` : ''}`}</span>
            </div>
            {userGroup.employees.map((employee, index) => {
              const isSelectedEmployee =
                mobileEmployee &&
                mobileEmployee.employee.id === employee.employee.id;
              return (
                <div
                  key={employee.employee.id}
                  className={`${classes.contentRowContainer} ${!employee.employee.active ? classes.disabledRow:''}`}
                >
                  <UserCard
                    openUserRegisterShifts={openUserRegisterShifts}
                    employee={employee.employee}
                    isTransfer={userGroup.id === 'transfer'}
                    userTransfers={employee}
                  />
                  <WeekDays key={index} shifts={employee.employee} />
                  {isSelectedEmployee && showRegisterShifts && (
                    <WeekDaysMobile
                      key={index + 1}
                      shifts={mobileEmployee.employee}
                    />
                  )}
                </div>
              );
            })}
          </Fragment>
        );
      })}
      {showNoUser && <NoUsers></NoUsers>}
    </Fragment>
  );
};

export default UserList;