import { useContext, useEffect, useState } from "react";
import classes from "./BottomBar.module.scss";
import {
  getFiveWeeksBefore,
  getNextFiveWeeks,
} from "../services/dateTimeService";
import userRegisterContext from "../store/user-register-context";
import { isMobile } from "../services/global";

const BottomBar = () => {
  const userRegisterCtx = useContext(userRegisterContext);
  const [weeksBefore, setweeksBefore] = useState(
    getFiveWeeksBefore(userRegisterCtx.selectedWeek.startDate)
  );
  const [nextWeeks, setnextWeeks] = useState(
    getNextFiveWeeks(userRegisterCtx.selectedWeek.startDate)
  );
  const [isMob, setIsMob] = useState(false);

  const changeSelectedDay = (date) => {
    userRegisterCtx.changeWeek(date);
  };

  const getAllWeeks = (weeksBefore, weeksAfter) => {
    setweeksBefore(weeksBefore);
    setnextWeeks(weeksAfter);
  };

  useEffect(() => {
    getAllWeeks(
      getFiveWeeksBefore(userRegisterCtx.selectedWeek.startDate),
      getNextFiveWeeks(userRegisterCtx.selectedWeek.startDate)
    );
  }, [userRegisterCtx.selectedWeek.startDate]);

  // Function to check if the screen size is mobile
  function isMobileHandler() {
    return window.innerWidth <= isMobile; // Adjust this value as needed
  }

  // Function to handle resize events
  function handleResize() {
    if (isMobileHandler()) {
      setIsMob(true);
    } else {
      setIsMob(false);
    }
  }

  // Add a resize event listener
  window.addEventListener("resize", handleResize);

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className={classes.BottomBar}>
      {weeksBefore &&
        weeksBefore.map((date) => {
          return (
            <div
              className={`${classes.dateContainer} ${classes.hideCellsLeft}`}
              key={date?.id}
              onClick={() => changeSelectedDay(date)}
            >
              {isMob && (
                <span className={classes.month}>{date?.shortNameMonth}</span>
              )}
              {!isMob && <span className={classes.month}>{date?.month}</span>}
              <span className={classes.day}>{date?.day}</span>
            </div>
          );
        })}
      <div className={`${classes.dateContainer} ${classes.selectedDay}`}>
        {isMob && (
          <span className={classes.month}>
            {userRegisterCtx.selectedWeek?.shortNameMonth}
          </span>
        )}
        {!isMob && (
          <span className={classes.month}>
            {userRegisterCtx.selectedWeek?.monthString}
          </span>
        )}
        <span className={classes.day}>
          {userRegisterCtx.selectedWeek.dayString}
        </span>
      </div>
      {nextWeeks &&
        nextWeeks.map((date) => {
          return (
            <div
              className={`${classes.dateContainer} ${classes.hideCellsRight}`}
              key={date?.id}
              onClick={() => changeSelectedDay(date)}
            >
              {isMob && (
                <span className={classes.month}>{date?.shortNameMonth}</span>
              )}
              {!isMob && <span className={classes.month}>{date?.month}</span>}
              <span className={classes.day}>{date?.day}</span>
            </div>
          );
        })}
    </div>
  );
};

export default BottomBar;