import React, { useState, useEffect } from "react";
import classes from "./FolderComponent.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  selectedRoleItem,
  selectFolderItem,
  deleteItem,
  folderFilter,
  saveNewRole,
} from "../../features/rolesSlice";
import CreationDialog from "./CreationDialog";
import {
  resourcePermissions,
  checkPermission,
} from "../../features/resourcePepermissionsSlice";
import ConfirmDelete from "./ConfirmDelete";
import { searchInFolder } from "../../utils/utils";

const FolderComponent = (props) => {
  const currentResourcePermissions = useSelector(
    resourcePermissions("role-administration")
  );
  const idArray = props.idArray;
  const selectedItem = useSelector(selectedRoleItem);
  const active =
    selectedItem.idArray.slice(0, -1).includes(props.item.id) ||
    selectedItem.item.id === props.item.id;
  const dispatch = useDispatch();
  const folderName = props.item?.name ?? "Folder";
  const root = props.item.type === "root";
  const searchFilter = useSelector(folderFilter);
  const folders = props.item?.folders ?? [];
  const roles = props.item?.roles ?? [];
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({});

  const [hideFolder, setHideFolder] = useState(false);

  const [open, setOpen] = useState(false);

  const folderClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
    dispatch(
      selectFolderItem({
        item: props.item,
        idArray: idArray,
        isFolder: true,
        currentOpenState: open,
      })
    );
  };

  const itemClick = (event, item, index) => {
    event.stopPropagation();
    dispatch(
      selectFolderItem({
        item: item,
        idArray: [...idArray, index],
        isFolder: false,
      })
    );
  };

  const deleteClick = (event, locationIdArray, isFolder, name) => {
    event.stopPropagation();
    setOpenDelete(true);
    setDeleteInfo({ idArray: locationIdArray, isFolder: isFolder, name: name });
  };

  const newRole = (event) => {
    event.stopPropagation();
    setOpenCreate(true);
  };

  const closeCreate = (event, create, nameInput, isFolder) => {
    event.stopPropagation();
    setOpenCreate(false);
    if (create) {
      dispatch(
        saveNewRole({
          name: nameInput === "" ? "New Role" : nameInput,
          at: idArray,
          isSelectedItemFolder: true,
        })
      );
      setOpen(true);
    }
  };

  const closeDelete = (confirm, currentDeleteInfo) => {
    setOpenDelete(false);
    if (confirm) dispatch(deleteItem(currentDeleteInfo));
  };

  useEffect(() => {
    if (
      JSON.stringify(selectedItem.idArray.slice(0, -1)) ===
      JSON.stringify(idArray)
    ) {
      setOpen(true);
    }
  }, [selectedItem, idArray]);

  useEffect(() => {
    const contentMatch = searchInFolder(props.item, searchFilter);
    setOpen(contentMatch && searchFilter !== "");
    setHideFolder(
      props.item.type !== "root" &&
      !folderName.toLowerCase().includes(searchFilter.toLowerCase()) &&
      !contentMatch
    )
  }, [searchFilter]);

  return (
    <>
      {" "}
      {!hideFolder && (
        <div id={`folder${props.item.id}`} className={classes.folderContainer}>
          {props.item.type !== "root" && (
            <div
              onClick={folderClick}
              className={`${classes.folder} ${
                selectedItem.item === props.item ? classes.selectedItem : ""
              }`}
              style={{ paddingLeft: 16 + 20 * (idArray.length - 1) + "px" }}
            >
              <div className={classes.arrowButton}>
                <span
                  className={`${
                    open ? classes.openArrow : classes.closedArrow
                  } ${active && classes.highlightText}`}
                >
                  ◥
                </span>
              </div>
              <div className={classes.folderLabel}>
                <span
                  className={`${open ? "icon-folder-open" : "icon-folder"} ${classes.folderImage} ${
                    active && classes.activeIcon
                  }`}
                ></span>
                <div className={classes.itemLabel}>
                  <span>{folderName}</span>
                  <div className={classes.itemOptions}>
                    {checkPermission(
                      currentResourcePermissions,
                      "create-role"
                    ) && (
                      <span
                        onClick={newRole}
                        className={`icon-role-plus ${classes.roleIcon}`}
                      ></span>
                    )}
                    {checkPermission(
                      currentResourcePermissions,
                      "delete-folder"
                    ) && (
                      <span
                        onClick={(event) => {
                          deleteClick(event, idArray, true, folderName);
                        }}
                        className={`icon-delete ${classes.deleteIcon}`}
                      ></span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={`${
              open || root ? classes.folderItemsOpen : classes.folderItemsClosed
            } ${classes.folderItems}`}
          >
            {folders.length > 0 || roles.length > 0 ? (
              <>
                {folders.map((item, index) => {
                  return (
                    <FolderComponent
                      key={index}
                      item={item}
                      idArray={[...idArray, item.id]}
                    />
                  );
                })}
                {roles.map((item, index) => {
                  let showRole = item.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase()) || folderName.toLowerCase().includes(searchFilter.toLowerCase());
                  return (
                    showRole && (
                      <div
                        id={`role${item.id}`}
                        onClick={(event) => {
                          itemClick(event, item, item.id);
                        }}
                        className={`${classes.folderItem} ${
                          selectedItem.item === item ? classes.selectedItem : ""
                        }`}
                        key={index}
                        style={{ paddingLeft: 16 + 20 * idArray.length + "px" }}
                      >
                        <span
                          className={`icon-role ${classes.userImage} ${
                            selectedItem.item === item && classes.activeIcon
                          }`}
                        ></span>
                        <div className={classes.itemLabel}>
                          <span>{item.name}</span>
                          <div className={classes.itemOptions}>
                            {checkPermission(
                              currentResourcePermissions,
                              "delete-role"
                            ) && (
                              <span
                                onClick={(event) => {
                                  deleteClick(
                                    event,
                                    [...idArray, item.id],
                                    false,
                                    item.name
                                  );
                                }}
                                className={`icon-delete ${classes.deleteIcon}`}
                              ></span>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </>
            ) : (
              folders.length < 1 &&
              roles.length < 1 &&
              props.item.type !== "root" && (
                <i
                  className={`${classes.emptyPlaceholder} ${
                    selectedItem.item === props.item
                      ? classes.selectedPlaceholder
                      : ""
                  }`}
                  style={{ paddingLeft: 16 + 20 * idArray.length + "px" }}
                >
                  This folder is empty
                </i>
              )
            )}
          </div>
        </div>
      )}
      <CreationDialog
        open={openCreate}
        onClose={closeCreate}
        isFolder={false}
      />
      <ConfirmDelete
        open={openDelete}
        onClose={(confirm) => closeDelete(confirm, deleteInfo)}
        message={
          "Are you sure you want to delete " +
          (deleteInfo.isFolder ? 'folder "' : 'role "') +
          deleteInfo.name +
          '"?'
        }
      />
    </>
  );
};

export default FolderComponent;