import React from "react";

export default class ErrorBoundary extends React.Component{
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log('error at', window.location.pathname, error, info);
    // window.location.href = `/error?origin=${window.location.pathname}`
  }

  render() {
    if (this.state.hasError) {
      return '';
    }
    return this.props.children;
  }
}