import React, { useContext, useState, useCallback, useEffect } from "react";
import UserRegisterContext from "../store/user-register-context";
import UserWeek from "./UserWeek";
import UserList from "./UserList";
import useFilteredUserGroups from "../hooks/useFilteredUserGroups";
import { alphabetSort } from "../../utils/utils";

const WeekViewContainer = () => {
  const userRegisterCtx = useContext(UserRegisterContext);
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const {
    isFilterOpen,
    filterUsers,
    searchQuery,
    filteredUserGroups,
    filteredEmployeesBySearch,
    handleFilterToggle,
    handleSearchChange,
  } = useFilteredUserGroups(userRegisterCtx.currentWeekData);

  const setOverflowHidden = useCallback(() => {
    const rootElement = document.getElementById("htmlHead");
    if (userRegisterCtx.currentWeekData.length > 0) {
      rootElement.style.overflowY = "auto";
    } else {
      rootElement.style.overflowY = "hidden";
    }
  }, [userRegisterCtx.currentWeekData.length]);

  const countUsers = useCallback(() => {
    let count = 0;
    if (userRegisterCtx.currentWeekData) {
      userRegisterCtx.currentWeekData.forEach((group) => {
        count = count + group.employees.length;
      });
      setNumberOfUsers(count);
    }
    setOverflowHidden();
  }, [userRegisterCtx.currentWeekData, setOverflowHidden]);

  useEffect(() => {
    countUsers();
  }, [countUsers]);

  return (
    <>
      <UserList
        filterUsers={filterUsers}
        searchQuery={searchQuery}
        filteredUserGroups={filteredUserGroups}
        filteredEmployeesBySearch={filteredEmployeesBySearch}
      />
      <UserWeek
        numberOfUsers={numberOfUsers}
        isFilterOpen={isFilterOpen}
        searchQuery={searchQuery}
        handleFilterToggle={handleFilterToggle}
        handleSearchChange={handleSearchChange}
      />
    </>
  );
};

export default WeekViewContainer;