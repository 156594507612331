import React, { useCallback, useState } from "react";
import classes from "./AssignedUsers.module.scss"
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent"
import SearchBar from "../UI/SearchBar";
import { defaultRoleColor, getContrastColor } from "../../utils/utils";

const AssignedUsers = ({ users, role }) => {
  const [open, setOpen] = useState(false);
  const userList = users?.filter(u => 
    u.roles.some(r => r.role.id === role.id)).map(user => (
      { ...user, 
        search: `${user.name ?? ''} ${user.lastName ?? ''}`.toLowerCase(),
        shortName:`${user.name?.slice(0,1) ?? ''}${user.lastName?.slice(0,1) ?? ''}` 
      }
    )) ?? [];
  const [filterQuery, setFilterQuery] = useState('');

  const onClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  }

  const openDialog = (event) => {
    event.stopPropagation();
    setOpen(true);
  }

  const search = useCallback((value) => {
    setFilterQuery(value.toLowerCase());
  }, [setFilterQuery]);

  return (
    <>
      <button onClick={openDialog} className={classes.viewInfoBtn}>
        <span className="icon-information1"></span>
        <span className={classes.buttonLabel}>View Info</span>
      </button>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <div className={classes.dialogContent}>
            <div className={classes.title}>
              <span>{role.name ? `Users with "${role.name}" role`:'Assigned Users'}</span>
              <span onClick={onClose} className={`icon-close ${classes.closeIcon}`}></span>
            </div>
            {userList.length > 9 && <SearchBar onChange={search} />}
            <div className={`${classes.userList} ${userList.length > 9 ? classes.searchEnabled: ''}`}>
              {userList.map((user, index) => user.search.includes(filterQuery) &&
                <div className={classes.user} key={index}>
                  <div className={classes.avatar} style={{backgroundColor: user.employee?.roleColor ?? defaultRoleColor, color:getContrastColor(user.employee?.roleColor ?? defaultRoleColor)}}>
                    <span className={classes.shortName}>{user.shortName ?? 'WW'}</span>
                  </div>
                  <span>{`${user.name ?? ''} ${user.lastName ?? ''}`}</span>
                </div>
              )}
            </div>
            {userList.length < 1 && <span className={classes.placeholder}>{`No users with "${role.name ?? 'this'}" role`}</span>}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AssignedUsers;