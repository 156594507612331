import React, { useEffect, useRef, useState } from "react";
import classes from "./OverflowList.module.scss";
import { Tooltip } from "@mui/material";
import { getContrastColor } from "../../utils/utils";

const OverflowList = ({ items, containerId }) => {
  const itemRefs = useRef([]);
  const [maxItems, setMaxItems] = useState(items.length);
  const [visibleFlag, setVisibleFlag] = useState(false);

  useEffect(() => {
    const getEmSize = () => {
      const element = document.getElementById("userCard");
      if (element) {
        const fontSize = window
          .getComputedStyle(element)
          .getPropertyValue("font-size");
        return parseFloat(fontSize);
      } else {
        return 13.6;
      }
    };
    const updateMaxItems = () => {
      if (itemRefs.current.length === 0) {
        return;
      }
      const containerElement = document.getElementById(containerId);
      if (!containerElement) return;
      const emSize = getEmSize();
      const gapWidth = 0.4 * emSize;
      const lastItemWidth = 2.3 * emSize + gapWidth;
      let totalWidth = lastItemWidth;
      let newItemCount = 0;
      for (const ref of itemRefs.current) {
        if (!ref.current) continue;
        const itemWidth = ref.current.getBoundingClientRect().width;
        totalWidth += itemWidth + gapWidth;
        if (totalWidth <= containerElement.clientWidth) {
          newItemCount++;
        } else {
          break;
        }
      }
      setMaxItems(newItemCount);
    };
    updateMaxItems();
    setVisibleFlag(true);
  }, [items, containerId]);

  return (
    <div
      className={classes.userList}
      style={{ visibility: visibleFlag ? "visible" : "hidden" }}
    >
      {items.length > 0 ? (
        items.map((item, index) => {
          const itemRef = React.createRef();
          itemRefs.current[index] = itemRef;
          const color = item.color ?? item.roleColor ?? "#1a1a1a";
          if (index < maxItems) {
            return (
              <div
                key={index}
                id="userCard"
                className={classes.userCard}
                style={{
                  backgroundColor: color,
                  color: getContrastColor(color),
                }}
                ref={itemRef}
              >
                {`${item.userName}`}
              </div>
            );
          } else if (index === maxItems) {
            return (
              <Tooltip
                key={index}
                title={
                  <div key={index} className={classes.tooltip}>
                    {items.slice(maxItems).map((user, j) => (
                      <div key={j}>{`${user.userName}`}</div>
                    ))}
                  </div>
                }
                placement="bottom"
                arrow
                PopperProps={{
                  popperOptions: {
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "-10px, 0px"
                      },
                    },
                  },
                }}
              >
                <div
                  id="userCard"
                  className={`${classes.userCard} ${classes.usersOverflow}`}
                >
                  +{items.length - maxItems}
                </div>
              </Tooltip>
            );
          } else {
            return null;
          }
        })
      ) : (
        <div className={`${classes.userCard} ${classes.placeholder}`}>
          This crew is empty
        </div>
      )}
    </div>
  );
};

export default OverflowList;
