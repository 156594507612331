import { useDispatch, useSelector } from "react-redux";
import { fetchAircrafts, selectAircrafts } from "../../features/aircraftsSlice";
import { useEffect } from "react";

const useFetchAirCrafts = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(fetchAircrafts())
  },[])
}

export default useFetchAirCrafts;