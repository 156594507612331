import React from "react";
import classes from "./Placeholder.module.scss";

const Placeholder = (props) => {
  return(
    props.list?.length > 0 ?
    props.children :
    <span className={classes.placeholder}>
      {props.placeholder ?? ""}
    </span>
  )
}

export default Placeholder;
