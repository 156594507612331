import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  toastOpen,
  closeToast,
  toastMessage,
  toastType,
  toastDuration,
} from "../../features/toastNotificationSlice";

export default function ToastNotification() {
  const open = useSelector(toastOpen);
  const message = useSelector(toastMessage);
  const type = useSelector(toastType);
  const duration = useSelector(toastDuration);
  const dispatch = useDispatch();

  let toastBackground = [type].map((t) => {
    switch (t) {
      case "success":
        return { backgroundColor: "#00ca9a" };
      case "info":
        return { backgroundColor: "#4b9ce0" };
      case "warning":
        return { backgroundColor: "#f9882e" };
      case "error":
        return { backgroundColor: "#FF7B7B" };
      default:
        return { backgroundColor: "#4b9ce0" };
    }
  })[0];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeToast());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={type}
        sx={{
          ...toastBackground,
          width: "100%",
          fontSize: "0.9rem",
          fontFamily: "Poppins",
        }}
        variant="filled"
      >
        <pre>{message}</pre>
      </Alert>
    </Snackbar>
  );
}