import { useCallback, useEffect } from "react";
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";
import { getAllocatedShifts } from "../services/allocatedShifts";
import { WEEK, groupByProperty, indexBy, objectMap, shortNextSaturday, shortPreviousSunday } from "../../utils/utils";
import useSites from "./useSites";
import { getRequiredShifts } from "../services/requiredShiftsService";
import { allLocations } from "../../features/currentDataSlice";
import useShiftForm from "./useShiftForm";

// const emptyInfo = {
//   requiredShifts: null,
//   weekDays: Array.from({ length: 7 }, (_, i) => ({weekDay: i+1, requiredShifts: ''}))
// }

const useRequiredShifts = ({ locationId, locationSites, locationSchedules }) => {
  const dispatch = useDispatch();
  const [requiredShiftInfo, setRequiredShiftInfo] = useState(objectMap( indexBy(locationSites, 'id'), (site) => site.schedules));


  const updateDay = useCallback((siteId, scheduleId, dayIdx, newValue) => {
    setRequiredShiftInfo(prev => {
      let updated = prev[siteId] ?? {[scheduleId]: {requiredShifts: '', weekDays: WEEK.map(day => ({requiredShifts: ''}))}};
      updated[scheduleId].weekDays[dayIdx] = { ...updated[scheduleId].weekDays[dayIdx], requiredShifts: newValue };
      return {...prev, [siteId]:updated};
    })
  }, [setRequiredShiftInfo]);

  const updateSchedule = useCallback((siteId, scheduleId, newValue) => {
    // setRequiredShiftInfo(prev=>{
    //   let updated = prev[siteId];
    //   updated[scheduleId].requiredShifts = newValue;
    //   return {...prev, [siteId]:updated};
    // })
  });

  useEffect(()=>{
  },[requiredShiftInfo])

  // const idxSchedules = indexBy(schedules, 'id');
  // useEffect(() => {
  //   const requests = schedules.map(schedule => getRequiredShifts(locationId, site.id, schedule.id))
  //   Promise.all(requests).then(responses => {
  //     if (responses.every(response => response.success)) {
  //       setRequiredShiftInfo(responses.reduce(
  //         (idxObj, response) => ({
  //           ...idxObj,
  //           [response.scheduleId]: response.load ?? {
  //             requiredShifts:null, site, scheduleTime: idxSchedules[response.scheduleId], weekDays:  Array.from({ length: 7 }, (_, i) => ({weekDay: i+1, requiredShifts: ''}))
  //           }
  //         })
  //         , {}
  //       ));
  //     } else {
  //       dispatch(showToast({ type: 'warning', message: 'Something went wrong while retrieving required shifts' }));
  //     }
  //   });
  // }, [locationId, site, schedules]);


  return {requiredShiftInfo, updateDay, updateSchedule};

}

export default useRequiredShifts;