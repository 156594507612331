import { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getWeekDays,
  getCurrentWeekData,
  getCurrentWeekDataFromMonthYear,
  transformDateTimeZone,
  getWeekInfo
} from "../services/dateTimeService";
import UserRegisterContext from "./user-register-context";
import { logOut } from "../../features/userSlice";
import { getAllocatedShifts } from "../services/allocatedShifts";
import { getScheduleTimes } from "../services/userRegisterService";
import { setLoading } from "../../features/loadingSlice";
import { restartPermissions } from "../../features/resourcePepermissionsSlice";
import {
  updatecurrentWeekId,
  setSelectedLocationState,
  selectedLocationState,
  selectedMonthState,
  selectedYearState,
  setSelectedMonthState,
  setSelectedYearState,
  setloadedLocations
} from "../../features/currentDataSlice";
import { addUserToCosmosDB } from "../../features/cosmosdbSlice";
import { HOME_ID } from "../services/global";
import { getNextSaturday, getPreviousSunday, shortNextSaturday, shortPreviousSunday } from "../../utils/utils";

const useLogout = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logOut());
    dispatch(restartPermissions());
  };
  return handleLogout;
};

const UserRegisterProvider = (props) => {
  const [selectedWeek, setSelectedWeek] = useState({});
  const [usersRegisters, setUsersRegisters] = useState([]);
  const [allocatedShiftsData, setAllocatedShiftsData] = useState({});
  const [scheduleTimes, setScheduleTimes] = useState([]);
  const [transferUsers, setTransferUsers] = useState([]);
  const handleLogout = useLogout();
  const dispatch = useDispatch();

  //CURRENT DATA FROM SLICE
  const selectedLocationSlice = useSelector(selectedLocationState);
  const selectedMonthSlice = useSelector(selectedMonthState);
  const selectedYearSlice = useSelector(selectedYearState);

  async function fetchAllocatedShiftsData(location, startDate, endDate) {
    const data = await getAllocatedShifts(location.id, startDate, endDate);
    if (data) {
      setAllocatedShiftsData(data);
    }
  }

  async function fetchScheduleTimes() {
    const data = await getScheduleTimes();
    if (data) {
      setScheduleTimes(data.load);
    }
  }

  const fetchCurrentWeekData = useCallback(
    async (location, startDate, endDate, year, month, pageId) => {
      try {
        if (location.id && startDate && endDate) {
          dispatch(setLoading(true));
          fetchAllocatedShiftsData(location, startDate, endDate);
          localStorage.setItem('lastViewed', startDate);
          localStorage.setItem('lastLocation', location.id);
          let data = await getCurrentWeekData(
            location.id,
            startDate,
            endDate
          );
          data = {...data, initialDate:startDate, finalDate:endDate};
          if (data) {
            if (data.initialDate && data.finalDate) {
              transformFetchDate(data, location, year.id, month.id, pageId);
            }
          }
          dispatch(setLoading(false));
        }
      } catch (err) {
        console.log(err);
        dispatch(setLoading(false));
      }
    },
    [dispatch]
  );

  const fetchWeekDataFromYearMonth = async (location, yearObj, monthId, pageId) => {
    try {
      const year = parseInt(yearObj.label, 10);
      if (location.id && year && monthId) {
        let [startDate, endDate] = getFirstSundayAndNextSaturday(monthId, year);
        localStorage.setItem('lastViewed', startDate);
        localStorage.setItem('lastLocation', location.id);
        dispatch(setLoading(true));
        fetchAllocatedShiftsData(location, startDate, endDate);
        const data = await getCurrentWeekData(
          parseInt(location.id),
          startDate,
          endDate
        );
        if (data) {
          if (data.initialDate && data.finalDate) {
            transformFetchDate(data, location, yearObj.id, monthId, pageId);
          }
        }
        dispatch(setLoading(false));
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const transformFetchDate = (data, location, yearId, monthId, pageId) => {
    const formattedInitialDate = transformDateTimeZone(
      data.initialDate,
      location.timeZone
    );
    const formattedFinalDate = transformDateTimeZone(
      data.finalDate,
      location.timeZone
    );
    const { months, days, shortNameMonth } = getWeekInfo(
      formattedInitialDate,
      formattedFinalDate
    );
    const week = {
      status: data.status,
      masterId: data.masterId,
      startDate: formattedInitialDate,
      endDate: formattedFinalDate,
      month: months,
      day: days,
      week: getWeekDays(formattedInitialDate),
      shortNameMonth
    };
    setSelectedWeek(week);

    //cosmo db testing
    dispatch(updatecurrentWeekId(data.masterId));

    
      setUsersRegisters(
        data?.transfers?.length > 0 ?
        [{id:'transfer', groupName: "Users Transfers", employees: data.transfers ?? []}, ...(data?.groups ?? [])]
        :
        (data?.groups ?? [])
      );
    
    if (data?.transfers?.length > 0) {
      setTransferUsers([]);
    } else {
      setTransferUsers([]);
    }

    //send data to cosmos DB
    dispatch(
      addUserToCosmosDB({
        pageId,
        locationId: location.id,
        weekId: pageId === 0 ? data.masterId : 0,
        monthId: monthId,
        year: yearId,
      })
    );
  };

  const changeSelectedLocation = (location, isMonthView, pageId) => {
    dispatch(setSelectedLocationState(location));
    const previousSunday = getPreviousSunday();
    const nextSaturday = getNextSaturday();
    if (!isMonthView) {
      fetchCurrentWeekData(
        location,
        (selectedWeek?.startDate?.toISOString() ?? previousSunday.toISOString()).slice(0, 10),
        (selectedWeek?.endDate?.toISOString() ?? nextSaturday.toISOString()).slice(0, 10),
        selectedYearSlice,
        selectedMonthSlice,
        pageId
      );
    } else {
      //fetch service for month-view
    }
  };

  const changeSelectedYear = (year, isMonthView, pageId) => {
    dispatch(setSelectedYearState(year));
    if (!isMonthView) {
      fetchWeekDataFromYearMonth(
        selectedLocationSlice,
        year,
        selectedMonthSlice.id,
        pageId
      );
    } else {
      //fetch data from month view
    }
  };

  const resetData = async () => {
    dispatch(setSelectedLocationState({}));
    dispatch(setloadedLocations([]));
    setAllocatedShiftsData([]);
    setTransferUsers([]);
    setScheduleTimes([]);
    setUsersRegisters([]);
  }


  const changeSelectedMonth = (month, isMonthView, pageId) => {
    dispatch(setSelectedMonthState(month));
    if (!isMonthView) {
      fetchWeekDataFromYearMonth(
        selectedLocationSlice,
        selectedYearSlice,
        month.id,
        pageId
      );
    } else {
      //fetch data for month view
    }
  };

  const changeSelectedWeek = (date) => {
    const stringStartDate = date.startDate.toISOString().slice(0, 10);
    const stringEndDate = date.endDate.toISOString().slice(0, 10);
    fetchCurrentWeekData(
      selectedLocationSlice,
      stringStartDate,
      stringEndDate,
      selectedYearSlice,
      selectedMonthSlice,
      HOME_ID
    );
    const week = {
      masterId: date.masterId,
      status: date.status,
      startDate: date.startDate,
      endDate: date.endDate,
      month: date.month,
      day: date.day,
      week: getWeekDays(date.startDate),
    };
    setSelectedWeek(week);
  };

  const userRegisterContext = {
    selectedWeek: {
      status : selectedWeek.status,
      masterId: selectedWeek.masterId,
      startDate: selectedWeek.startDate,
      endDate: selectedWeek.endDate,
      monthString: selectedWeek.month,
      dayString: selectedWeek.day,
      week: selectedWeek.week,
      shortNameMonth: selectedWeek.shortNameMonth
    },
    allocatedShiftsData: allocatedShiftsData,
    scheduleTimes: scheduleTimes,
    currentWeekData: usersRegisters,
    transferUsers: transferUsers,
    changeWeek: changeSelectedWeek,
    changeMonth: changeSelectedMonth,
    changeLocation: changeSelectedLocation,
    changeYear: changeSelectedYear,
    fetchWeekData: fetchCurrentWeekData,
    resetData: resetData,
    fetchWeekDataFromYearMonth: fetchWeekDataFromYearMonth,
    fetchScheduleTimes: fetchScheduleTimes,
  };

  return (
    <UserRegisterContext.Provider value={userRegisterContext}>
      {props.children}
    </UserRegisterContext.Provider>
  );
};

export default UserRegisterProvider;

function getFirstSundayAndNextSaturday(month, year) {
  // Create a new date object with the given month and year
  const dateString = new Date(`${year}-${month.toString().padStart(2, 0)}-01`).toISOString().slice(0,10);
  return [shortPreviousSunday(dateString), shortNextSaturday(dateString)];

  // Find the first Sunday in the month
  // while (date.getDay() !== 0) {
  //   date.setDate(date.getDate() + 1);
  // }
  // const firstSunday = date.getDate();

  // // Find the following Saturday
  // date.setDate(date.getDate() + 6);
  // const nextSaturday = date.getDate();

  // // Format the dates as strings in the format (YYYY-MM-DD)
  // const firstSundayString = `${date.getFullYear()}-${(date.getMonth() + 1)
  //   .toString()
  //   .padStart(2, "0")}-${firstSunday.toString().padStart(2, "0")}`;

  // const nextSaturdayString = `${date.getFullYear()}-${(date.getMonth() + 1)
  //   .toString()
  //   .padStart(2, "0")}-${nextSaturday.toString().padStart(2, "0")}`;

  // Return the dates as strings
  // return [firstSundayString, nextSaturdayString];
}