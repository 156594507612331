import React, { useEffect, useState } from "react";
import classes from "./FolderList.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  selectedRoleItem,
  fetchFolders,
  roleManagementFolders,
  saveNewFolder,
  saveNewRole,
  filterFolders,
  newItemState,
} from "../../features/rolesSlice";
import FolderComponent from "./FolderComponent";
import CreationDialog from "./CreationDialog";
import {
  resourcePermissions,
  checkPermission,
} from "../../features/resourcePepermissionsSlice";

const FolderList = () => {
  const currentResourcePermissions = useSelector(
    resourcePermissions("role-administration")
  );
  const foldersTree = useSelector(roleManagementFolders);
  const selectedItem = useSelector(selectedRoleItem);
  const [searchFilter, setSearchFilter] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isFolder, setIsFolder] = useState(true);
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFolders());
    setSearchFilter("");
  }, [dispatch]);

  const createNewFolder = (event) => {
    event.stopPropagation();
    setIsFolder(true);
    setOpenDialog(true);
  };

  const createNewRole = (event) => {
    event.stopPropagation();
    setIsFolder(false);
    setOpenDialog(true);
  };

  const closeDialog = (event, create, nameInput, isFolder) => {
    event.stopPropagation();
    setOpenDialog(false);
    if (create) {
      isFolder
        ? dispatch(
            saveNewFolder({
              name: nameInput === "" ? "New Folder" : nameInput,
              at: selectedItem.idArray,
              isSelectedItemFolder: selectedItem.isFolder,
            })
          )
        : dispatch(
            saveNewRole({
              name: nameInput === "" ? "New Role" : nameInput,
              at: selectedItem.idArray,
              isSelectedItemFolder: selectedItem.isFolder,
            })
          );
    }
  };

  const searchChange = (event) => {
    setSearchFilter(event.target.value);
  };

  useEffect(()=>{
    dispatch(filterFolders(searchFilter));
  }, [searchFilter, dispatch])

  const newItem = useSelector(newItemState);

  useEffect(()=>{
    if (newItem.id !== null && newItem.isFolder!==null){
      let element = document.getElementById(`${newItem.isFolder ? 'folder':'role'}${newItem.id}`);
      if (element) element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [newItem]);

  return (
    <>
      <div className={classes.foldersHeader}>
        <div className={classes.inputField}>
          <input
            className={classes.customInput}
            placeholder="Search"
            onChange={searchChange}
            value={searchFilter}
          />
          <span className={`icon-search ${classes.searchIcon}`}></span>
        </div>
        <div className={classes.foldersSubHeader}>
          <span className={classes.foldersLabel}>Role Folders</span>
          <div className={classes.buttonsContainer}>
            {checkPermission(currentResourcePermissions, "create-folder") && (
              <button
                className={classes.headerButton}
                onClick={createNewFolder}
              >
                <span className="icon-folder-plus"></span>
                New Folder
              </button>
            )}
            {checkPermission(currentResourcePermissions, "create-role") && (
              <button className={classes.headerButton} onClick={createNewRole}>
                <span
                  className={`icon-role-plus ${classes.newRoleIcon}`}
                ></span>
                New Role
              </button>
            )}
          </div>
        </div>
        <CreationDialog
          open={openDialog}
          onClose={closeDialog}
          isFolder={isFolder}
        />
      </div>
      <div className={classes.foldersBody}>
        <FolderComponent
          item={{
            name: "",
            type: "root",
            open: true,
            roles: [],
            folders: foldersTree,
          }}
          idArray={[]}
        />
      </div>
    </>
  );
};

export default FolderList;
