import React, { useCallback, useEffect, useState } from "react";
import classes from "./SortableList.module.scss"
import { useDispatch } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";
import { relocateItem } from "../../utils/utils";
import { setLoading } from "../../features/loadingSlice";


const defaultUpdate = async () => {
  return Promise.resolve({ success: true, message: "Couldn't update the order" });
}

const defaultRender = (item) => <div>{item}</div>

const SortableList = ({ items = [], render = defaultRender, updateService = defaultUpdate }) => {
  const [itemsShown, setItemsShown] = useState(items);
  const [itemsBackup, setItemsBackup] = useState(items);
  const [dropIndex, setDropIndex] = useState(null);
  const [dragIndex, setDragIndex] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setItemsShown(items);
    setItemsBackup(items);
  }, [items])

  const dragOver = useCallback((event, index, item) => {
    event.preventDefault();
    if (dragIndex !== null) setDropIndex(index)
  }, [setDropIndex, dragIndex]);

  const drag = useCallback((event, index) => {
    event.preventDefault();
    setDragIndex(index);
  }, [setDragIndex]);

  const updateItems = useCallback(async () => {
    setItemsBackup(itemsShown);
    setDropIndex(null);
    setDragIndex(null);
    dispatch(setLoading(true));
    dispatch(showToast({type: 'info', message: 'Updating crew orders...'}));
    const updateResponse = await updateService(itemsBackup, itemsShown);
    dispatch(setLoading(false));
    if (updateResponse.success !== true) {
      dispatch(showToast({ type: 'warning', message: updateResponse?.message ?? "Couldn't update the crew order" }));
    }
  }, [setItemsBackup, itemsShown, itemsBackup, dispatch, updateService])

  useEffect(() => {
    if (typeof (dragIndex) === 'number' && typeof (dropIndex) === 'number') {
      setItemsShown(relocateItem(itemsBackup, dragIndex, dropIndex));
    }
  }, [itemsBackup, dragIndex, dropIndex, setItemsShown]);

  return (
    <div onDragOver={(event) => event.preventDefault()}>
      <ul className={classes.sortableList} onDragOver={(event) => event.preventDefault()} onDragEnter={(event) => event.preventDefault()}>
        {itemsShown.map((item, key) =>
          <li
            key={key}
            className={key === dropIndex ? classes.dropPlaceholder : ''}
            draggable="true"
            onDrag={(event) => drag(event, key)}
            onDragOver={(event) => dragOver(event, key, item)}
            onDragEnd={(event) => updateItems(event)}
          >
            <div className={classes.item}>
              {render(item)}
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}

export default SortableList;