
import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { getLeavesAdmin } from "../services/employeePermissionService";
import { logOut } from "../../features/userSlice";
import { restartPermissions } from "../../features/resourcePepermissionsSlice";
import UserRegisterContext from "../store/user-register-context";

const useLogout = () => {
  const dispatch = useDispatch();
  const userRegisterCtx = useContext(UserRegisterContext)
  const handleLogout = () => {
    dispatch(logOut());
    userRegisterCtx.resetData();
    dispatch(restartPermissions());
  };
  return handleLogout;
};

const useLeavesAdmin = () => {
  const [leaves, setLeaves] = useState([]);
  const [sickLeaves, setSickLeaves] = useState([]);
  const [trainingLeaves, setTrainingLeaves] = useState([]);
	const [travelLeaves, setTravelLeaves] = useState([]);
  const handleLogout = useLogout();

  const fetchLeaves = async () => {
    try {
      const dataLoad = await getLeavesAdmin();
      if (dataLoad.load) {
        const leavesLoad = [];
        const sickLeavesLoad = [];
        const trainingLeavesLoad = [];
				const travelLeavesLoad = [];
        for (const key in dataLoad.load) {
          if (dataLoad.load[key].type.toUpperCase() === "M") {
            leavesLoad.push({
              ...dataLoad.load[key]
            });
          }
          if (dataLoad.load[key].type.toUpperCase() === "S") {
            sickLeavesLoad.push({
              ...dataLoad.load[key]
            });
          }
          if (dataLoad.load[key].type.toUpperCase() === "T") {
            trainingLeavesLoad.push({
              ...dataLoad.load[key]
            });
          }
					if (dataLoad.load[key].type.toUpperCase() === "A") {
            travelLeavesLoad.push({
              ...dataLoad.load[key]
            });
          }
      }
        setLeaves(leavesLoad);
        setSickLeaves(sickLeavesLoad);
        setTrainingLeaves(trainingLeavesLoad);
				setTravelLeaves(travelLeavesLoad);
      }
      if (dataLoad.status === 403) {
        handleLogout();
      }
    } catch (error) {
      console.log(error.message);
    }
  };


  useEffect(() => {
    if (leaves.length === 0) {
      fetchLeaves();
    }
  }, []);

  return {
    leaves,
    sickLeaves,
    trainingLeaves,
		travelLeaves,
    fetchLeaves
  };

};

export default useLeavesAdmin;
