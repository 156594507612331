import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import classes from "./ConfirmDelete.module.scss";

export default function ConfirmDelete(props) {
  const message = props.message ?? "Are you sure you want to delete?";

  const onClose = (event, confirm) => {
    event.stopPropagation();
    props.onClose(confirm);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={(event) => {
          onClose(event, false);
        }}
        PaperProps={{
          style: {
            background: "#1A1A1A",
          },
        }}
      >
        <DialogContent>
          <div className={classes.dialog}>
            <div>{message}</div>
            <div className={classes.buttons}>
              <button
                className={`${classes.dialogButton} ${classes.cancelButton}`}
                onClick={(event) => onClose(event, false)}
              >
                Cancel
              </button>
              <button
                className={classes.dialogButton}
                onClick={(event) => onClose(event, true)}
              >
                Delete
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}