import React from "react";
import Dropdown from "../UI/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { allLocations, currentMonthIdState, currentYearIdState, selectedLocationState, selectedMonthState, selectedYearState } from "../../features/currentDataSlice";
import { fetchCurrentMonthView } from "../../features/monthViewSlice";
import UserRegisterContext from "../store/user-register-context";
import { addUserToCosmosDB } from "../../features/cosmosdbSlice";
import { useContext } from "react";
import { fetchCrewOptions } from "../../features/filterSlice";
import { CREW_CONFIGURATION_ID } from "../services/global";


const LocationDropDown = ({pageId, global, onChange, defaultId, outlined}) => {
  const selectedLocation = useSelector(selectedLocationState);
  const locations = useSelector(allLocations);
  const dispatch = useDispatch();
  const userRegisterCtx = useContext(UserRegisterContext);
  const changeSelectedLocation = (location) => {
    if (global) userRegisterCtx.changeLocation(location, true, pageId);
    if (onChange) onChange(location);
    if (pageId === CREW_CONFIGURATION_ID) dispatch(fetchCrewOptions(location.id));
  };

  return (
    <Dropdown
      className={"locationDropdownComponent"}
      outlined={outlined}
      icon={"icon-location"}
      iconClass={"location"}
      defaultValue={locations.find(loc => loc.id === defaultId) ?? selectedLocation}
      options={locations}
      onClick={changeSelectedLocation}
      showUserInfo={false}
      showSearch={true}
      contentHeight={'50%'}
      displayProperty={"label"}
    ></Dropdown>
  );
}

export default LocationDropDown;