import React, { Fragment, useContext } from "react";
import classes from "./EmployeeList.module.scss";
import UserCard from "../Users/UserCard";
import { useDispatch, useSelector } from "react-redux";
import { employeesState, selectEmployeeHandler } from "../../features/shiftPatternSlice";
import useFilteredUserGroups from "../hooks/useFilteredUserGroups";
import UserRegisterContext from "../store/user-register-context";
import Checkbox from "../UI/Checkbox";

const EmployeeList = ({ search }) => {
  const employees = useSelector(employeesState);
  const filterUsers = employees;
  const dispatch = useDispatch();

  const toggleGroup = (newValue, userGroup) => {
    for (let employee of userGroup.employees) {
      if (Boolean(newValue) ^ Boolean(employee.selected)) {
        dispatch(selectEmployeeHandler(employee))
      }
    }
  }

  const selectAlltoggle = (newValue) => {
    for (let userGroup of filterUsers) {
      toggleGroup(newValue, userGroup);
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.selectAll}><Checkbox checked={filterUsers.every(group=> group.employees.every(employee=>employee.selected))} justify={"right"} whiteBorder={true} onChange={(newValue) => selectAlltoggle(newValue)}>Select All</Checkbox></div>
      {filterUsers.length > 0 &&
        filterUsers.map((userGroup) => {
          const groupEmployees = userGroup.employees.filter(employee => employee?.userName?.toLowerCase()?.includes(search));
          return (groupEmployees.length > 0 &&
            <Fragment key={userGroup.id}>
              <div className={classes.groupContainer}>
                <Checkbox checked={userGroup.employees.every(emp => emp.selected)} onChange={(newValue) => toggleGroup(newValue, userGroup)}>
                  <span className={classes.groupName}>{`${userGroup.groupName}${userGroup.site ? ` - ${userGroup.site}` : ''}`}</span>
                </Checkbox>
              </div>
              {groupEmployees.map((employee, index) => {
                return (
                  <div
                    key={employee.id}
                    className={classes.contentRowContainer}
                  >
                    <UserCard
                      className={"shiftPatternContainer"}
                      key={employee.id}
                      employee={employee}
                      showEmployeeCard={false}
                      showfilterChecked={true}
                      hideSkills={true}
                    />
                  </div>
                );
              })}
            </Fragment>
          );
        })}
    </div>
  );
};

export { EmployeeList };
