import React, { Fragment, useCallback, useEffect, useState } from "react";
import classes from './SiteShifts.module.scss';
import useRequiredShifts from "../hooks/useRequiredShifts";
import { objectMap, strToNum, sumArray } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { cleanSite, selectSiteRequiredShifts, updateDayRequired, updateScheduleRequired } from "../../features/requiredShiftsSlice";

const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const DayInput = ({ value, onChange, error }) => {

  const countChange = (e) => {
    onChange(strToNum(e.target.value));
  }

  return (
    <input onChange={countChange} value={value} className={`${(typeof (value) === 'number' && value > 0) ? (error ? classes.error : classes.highlight) : ''} ${classes.dayInput}`}></input>
  );
}

const SiteShifts = ({ site }) => {
  const timeSlots = site.schedules;
  const hasTimeSlots = timeSlots?.length > 0;

  const dispatch = useDispatch();

  const requiredInfo = useSelector(selectSiteRequiredShifts(site.id));

  const totalRequired = sumArray(Object.values(requiredInfo ?? {}).map(s => s.requiredShifts));  
  const summary = objectMap((requiredInfo ?? {}), schedule => sumArray(schedule.weekDays?.map(day=>day.requiredShifts) ?? []));
  const totalAllocated = sumArray(Object.values(summary));
  const slotError = (slotId) => requiredInfo?.[slotId]?.requiredShifts < summary[slotId];

  const cleanSiteInfo = (siteId) => {
    dispatch(cleanSite({siteId}));
    // !pending
    // updateInfo(prev => objectMap(prev, (schedule) => ({ ...schedule, requiredShifts: '', weekDays: schedule.weekDays.map(day => ({ ...day, requiredShifts: '' })) })))
  }

  const updateDay = (site, scheduleTime, dayIdx, newValue) => {
    dispatch(updateDayRequired({site, scheduleTime, dayIdx, newValue}))
  }

  const onScheduleChange = (e, site, scheduleTime) => {
    dispatch(updateScheduleRequired({site, scheduleTime, newValue:strToNum(e.target.value)}));
    // updateInfo(prev => ({ ...prev, [scheduleId]: { ...prev[scheduleId], requiredShifts: value } }))
  }

  return (
    <div className={classes.site}>
      <div className={classes.siteHeader}>
        <div className={classes.iconTitle}>
          <i className="icon-site" />
          <h1>{site.label}</h1>
        </div>
        {hasTimeSlots && <div className={classes.siteSummary}>
          <h2>Total Shifts: {totalRequired}</h2>
          <h2 className={`${classes.greenColor} ${totalAllocated > totalRequired ? classes.errortxt : ''}`}>Allocated: {totalAllocated}</h2>
          <button onClick={()=>cleanSiteInfo(site.id)}>
            <i className="icon-clean" />
          </button>
        </div>}
      </div>
      {hasTimeSlots ?
        <div className={classes.siteContent}>
          <h4>Available shifts by work schedule</h4>
          {days.map((day, i) => <h3 key={i}>{day}</h3>)}
          {timeSlots.map((slot, k) =>
            <Fragment key={k}>
              <input onChange={(e) => onScheduleChange(e, site, slot)} value={requiredInfo?.[slot.id]?.requiredShifts} />
              <div className={classes.slot}>
                <div className={classes.slotTitle} title={slot.label}>
                  <i className="icon-check" />
                  <h5>{slot.label}</h5>
                </div>
                {typeof (requiredInfo?.[slot.id]?.requiredShifts) === 'number' &&
                  <span className={`${classes.greenColor} ${slotError(slot.id) ? classes.errortxt : ''}`}>Allocated {summary[slot.id]} of {requiredInfo?.[slot.id]?.requiredShifts}</span>
                }
              </div>
              {days.map((d, j) => <DayInput error={slotError(slot.id)} onChange={(n) => updateDay(site, slot, j, n)} key={j} value={requiredInfo?.[slot.id]?.weekDays?.[j]?.requiredShifts ?? ''} />)}
            </Fragment>
          )}
        </div> :
        <div className={classes.placeholder}>
          <h6>No active schedules in site</h6>
        </div>
      }
    </div>
  );
}

export default SiteShifts;