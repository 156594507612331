import React, { useEffect, useState } from "react";
import classes from "./DayCard.module.scss";
import { useDispatch } from "react-redux";
import { selectedDayHandler, updateDayCardSite, updatedDayCardSchedule } from "../../features/shiftPatternSlice";
import Dropdown from "../../components/UI/Dropdown";
import useShiftForm from "../hooks/useShiftForm";
import ScheduleLabel from "../Users/ScheduleLabel";
import PopOverDropdown from "../UI/PopOverDropdown";

const DayCard = ({ dayData, sites, schedules, currentLocation }) => {

  const { shiftState, locationSites, locationSchedules, updateShift } = useShiftForm({ shift: null, currentLocation });
  const siteSchedules = locationSchedules?.[dayData?.site?.id] ?? [];

  const dispatch = useDispatch();

  const siteDefaultSchedule = (site) => ((locationSchedules?.[site?.id] ?? [])[0] ?? null)

  const selectedDayToggle = () => {
    if (!dayData.status) {
      const defaultSite = locationSites[0] ?? null;
      const defaultSchedule = siteDefaultSchedule(defaultSite);
      dispatch(selectedDayHandler({ ...dayData, site: defaultSite, scheduleTime: defaultSchedule }));

    } else {
      dispatch(selectedDayHandler(dayData));
    }
  };

  const onScheduleHandler = (schedule) => {
    dispatch(updatedDayCardSchedule({ cardId: dayData.day, schedule: schedule }));
  }

  const onSiteHandler = (site) => {
    const newSchedule = siteDefaultSchedule(site);
    dispatch(updateDayCardSite({ cardId: dayData.day, site: site, scheduleTime: newSchedule }));
  }

  useEffect(() => {
  }, [dayData]);

  return (
    <div
      className={`${!dayData.status
        ? classes.dayCardContainer
        : `${classes.dayCardContainer} ${classes.selectedItemcontainer}`
        }`}
    >
      <div className={classes.day}>
        <label className={classes.container}>
          <input
            type="checkbox"
            checked={dayData.status}
            onChange={() => selectedDayToggle()}
          />
          <span className={classes.checkmark}></span>
          Day {dayData.day}
        </label>
      </div>
      {!dayData.status && (
        <div className={classes.infoText}>
          <span className={`icon-information1 ${classes.infoIcon}`}></span>
          <span>Select Day</span>
        </div>
      )}
      {dayData.status && (
        <div className={classes.infoText}>
					<PopOverDropdown
						options={locationSites}
						defaultValue={dayData.site}
						onClick={onSiteHandler}
						disabled={!(locationSites?.length > 0)}
						disabledPlaceholder={<h6>No active sites in location</h6>}
            displayProperty={"name"}
					>
					</PopOverDropdown>

          <PopOverDropdown
            options={siteSchedules}
            icon={"icon-check"}
            defaultValue={dayData.scheduleTime}
            onClick={onScheduleHandler}
            disabled={!(siteSchedules?.length > 0) && !dayData.scheduleTime}
            disabledPlaceholder={<h6>No active schedules in site</h6>}
            displayProperty={"label"}
          ></PopOverDropdown>
        </div>
      )}
    </div>
  );
};

export { DayCard };
