import React from 'react';
import { useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import classes from './WeekShiftSummary.module.scss';
import userRegisterContext from "../store/user-register-context";
import { useContext, useRef } from "react";
import { selectedLocationState, selectedYearState } from '../../features/currentDataSlice';
import { getContrastColor } from '../../utils/utils';


function getDialogMaxHeight(element) {
  let topDialogPosition = element.getBoundingClientRect().top + element.getBoundingClientRect().height;
  let maxHeight = window.innerHeight - 43 - topDialogPosition;
  return maxHeight + 'px';
}

const WeekShiftSummary = () => {
  const userRegisterCtx = useContext(userRegisterContext);
  const [open, setOpen] = React.useState(false);
  const elementRef = useRef(null);
  let dialogMaxHeight = (elementRef.current) ? getDialogMaxHeight(elementRef.current) : '400px';
  const selectedLocation = useSelector(selectedLocationState);
  const selectedYear = useSelector(selectedYearState);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <button onClick={handleOpen} className={classes.siteAndShiftBtn} ref={elementRef}>
        Information by site and shift type
        <span className={open ? `icon-up ${classes.upIcon}` : `icon-down ${classes.downIcon}`}></span>
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionProps={{
          timeout: 1000,
          transitiontimingfunction: 'ease',
        }}
        PaperProps={{
          style: {
            position: 'absolute',
            right: '21.4%',
            top: '4.5em',
            width: '300px',
            height: 'min-content',
            maxHeight: dialogMaxHeight,
            background: '#1a1a1a',
          },
        }}>
        <DialogTitle
          style={{
            padding: 0,
            fontFamily: 'Poppins',
            fontWeight: 'normal'
          }}
        >
          <div className={classes.siteAndShiftDate}>
            Week {userRegisterCtx.selectedWeek?.dayString} {userRegisterCtx.selectedWeek?.monthString}, {selectedYear?.label}
          </div>
          <hr className={classes.verticalLine}></hr>

          {(userRegisterCtx && userRegisterCtx.allocatedShiftsData && userRegisterCtx.allocatedShiftsData.requiredShifts && userRegisterCtx.allocatedShiftsData.requiredShifts.length === 0) && (
            <div className={classes.emptyShiftAndSites}>
              No information available
            </div>
          )}

        </DialogTitle>
        <DialogContent
          style={{
            padding: 0,
          }}>

          {userRegisterCtx.allocatedShiftsData?.requiredShifts?.map((item, index) => {
            let siteName = selectedLocation?.sites?.find((site) => site.id === item.site)?.label ?? '';
            let siteCode = selectedLocation?.sites?.find((site) => site.id === item.site)?.siteCode ?? '';
            let scheduleTime = userRegisterCtx.scheduleTimes?.find(time => time.id === item.time)?.label ?? ' ';
            let color = userRegisterCtx.scheduleTimes?.find(time => time.id === item.time)?.color;
            return (
              <div key={index} className={classes.siteAndShiftCard}>
                <div className={classes.siteAndShiftCardLeftContainer}>
                  <div className={`${classes.siteAndShiftLeftCard}`} style={{ backgroundColor: color, "color": getContrastColor(color) }}>
                    <div className={classes.siteAndShiftLeftCardHeader}>
                      <div className="icon-check" />
                      <div>{scheduleTime[0] + siteCode}</div>
                    </div>
                    <div className={`${classes.siteAndShiftCardText} ${classes.cardName}`}>{scheduleTime + ' ' + siteName}</div>
                  </div>

                  <div className={classes.shiftAllocatedText}>
                    <div className={classes.siteAndShiftCardText}>Shifts</div>
                    <div className={classes.siteAndShiftCardText}>Allocated</div>
                  </div>
                </div>

                <div className={classes.shiftAllocatedNumbers}>
                  <div className={classes.siteAndShiftCardText}>{item.required}</div>
                  <div className={`${classes.siteAndShiftCardText} ${(item.allocated<item.required)? classes.incomplete:classes.complete}`}>{item.allocated}</div>
                </div>
              </div>
            );
          })}

        </DialogContent>
      </Dialog>
    </>
  )
}
export default WeekShiftSummary;
