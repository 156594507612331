import Cookies from "js-cookie";

import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";

export const getToken = () => Cookies.get("token");

export async function publishWeek(masterId, newStatus) {
  const token = getToken();
  if (!token) {
    throw new Error("Token not found");
  }

  const params = new URLSearchParams();
  params.append("masterId", masterId);
  params.append("status", newStatus);
  const url = `${API_BASE_URL}/changue-schedule-status?${params.toString()}`;
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseCode === 200) {
      return { ...data, success: true, newStatus };
    } else {
      console.log("error", data);
      return { ...data, success: false };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Something went wrong changing the week status.",
    };
  }
}