import React, { useEffect, useState } from 'react';
import classes from './RoleInfo.module.scss';
import { useSelector, useDispatch } from "react-redux";
import { selectedRoleItem, editSelectedItem, selectRadioOption, locations, actions, deleteItem, functionalities } from '../../features/rolesSlice';
import { resourcePermissions, checkPermission } from '../../features/resourcePepermissionsSlice';
import { role } from '../../features/userSlice';
import ConfirmDelete from './ConfirmDelete';
import { showToast } from '../../features/toastNotificationSlice';
import { defaultRoleColor, roleLabels } from '../../utils/utils';

const RoleInfo = () => {

  const defaultRoleSelection = (options) => {
    if (options.filter(option => option.selected).length < 1) {
      return options.map(option => {
        if (option.name === 'Basic') option.selected = true;
        return option;
      });
    } else {
      return options
    }
  }
  const currentResourcePermissions = useSelector(resourcePermissions('role-administration'));
  const selectedItem = useSelector(selectedRoleItem);
  const locationsArray = useSelector(locations);
  const actionsArray = useSelector(actions);
  const resourcesArray = useSelector(functionalities);
  const isFolder = selectedItem.isFolder;
  const [nameInput, setNameInput] = useState(selectedItem.item.name ?? '');
  const [descriptionInput, setDescriptionInput] = useState(selectedItem.item.description ?? '');
  const [roleOptions, setRoleOptions] = useState(defaultRoleSelection([
    { name: roleLabels['regular_user'], 
      selected: selectedItem.item.type === 'regular_user', 
      value: 'regular_user' 
    }, 
    { name: roleLabels['lead'], 
      selected: selectedItem.item.type === 'lead',
      value: 'lead' 
    }, 
    { name: roleLabels['admin'], 
      selected: selectedItem.item.type === 'admin', 
      value: 'admin' 
    }
  ]));
  const [openDelete, setOpenDelete] = useState(false);
  const [colorInput, setColorInput] = useState(selectedItem.item.color ?? defaultRoleColor);
  const dispatch = useDispatch();
  const currentUserRole = useSelector(role);

  const isRoleHigher = () => {
    let currentType = currentUserRole?.type;
    let editType = selectedItem.item?.type;
    return (currentType === 'admin') || (currentType === 'lead' && editType !== 'admin') || (currentType === 'regular_user' && editType === 'regular_user');
  }

  useEffect(() => {
    setRoleOptions((prevRoleOptions) => {
      let updatedOptions = defaultRoleSelection(prevRoleOptions.map(item => { item.selected = (selectedItem.item.type === item.value); return item }));
      return updatedOptions;
    });
  }, [selectedItem, dispatch]);

  useEffect(() => {
    dispatch(selectRadioOption(roleOptions));
  }, [roleOptions, dispatch])

  useEffect(() => {
    setNameInput(selectedItem.item.name ?? '');
    setDescriptionInput(selectedItem.item.description ?? '');
    setColorInput(selectedItem.item.color ?? defaultRoleColor)
  }, [selectedItem])

  const roleOptionChange = (event, option) => {
    event.stopPropagation();
    let updatedOptions = roleOptions.map(item => { item.selected = (item.name === option.name); return item });
    setRoleOptions(updatedOptions);
    dispatch(selectRadioOption(updatedOptions));

  }

  const saveChanges = (event) => {
    event.stopPropagation();
    dispatch(showToast({ type: 'info', message: 'saving...' }));
    let editedItem = { ...selectedItem.item };
    editedItem.name = nameInput;
    editedItem.description = descriptionInput;
    editedItem.color = colorInput;
    if (!isFolder) {
      editedItem.type = roleOptions.find(option => option.selected).value;
      editedItem.roleLocations = (editedItem.type === 'admin') ? [] :
        editedItem.roleLocations.map(l => l.location).filter(a => !locationsArray.some(b => b.id === a.id)).concat(locationsArray.filter(location => location.enabled)).map(location => { return { location: location } });
      editedItem.actions = editedItem.type === 'admin' ? [] :((editedItem.type === 'regular_user') ?
        editedItem.actions.filter(a => !actionsArray.some(b => b.id === a.id)).concat(actionsArray.filter(action => action.enabled)) :
        actionsArray);
      editedItem.admin = editedItem.type === 'admin';
      editedItem.resources =  editedItem.type === 'admin' ? [] : resourcesArray.filter(r => editedItem.actions.some(a => a.resourceKey === r.key));
    }
    dispatch(editSelectedItem({ editedItem: editedItem, isFolder: isFolder, idArray: selectedItem.idArray }));
  }

  const deleteSelectedItem = () => {
    dispatch(deleteItem({ idArray: selectedItem.idArray, isFolder: isFolder }));
  }

  const isOptionAllowed = (option) => {
    let currentType = currentUserRole?.type;
    let optionValue = option.value;
    return (currentType === 'admin') || (currentType === 'lead' && optionValue !== 'admin') || (currentType === 'regular_user' && optionValue === 'regular_user');
  }

  const noSelection = () => {
    return (Object.keys(selectedItem.item).length === 0);
  }

  const deletePermission = (((isFolder && checkPermission(currentResourcePermissions, 'delete-folder'))
    || (!isFolder && checkPermission(currentResourcePermissions, 'delete-role'))
  ) && isRoleHigher()) && !noSelection();

  const editPermission = (((isFolder && checkPermission(currentResourcePermissions, 'edit-folder'))
    || (!isFolder && checkPermission(currentResourcePermissions, 'edit-role'))
  ) && isRoleHigher()) && !noSelection();

  const closeDelete = (confirm) => {
    setOpenDelete(false);
    if (confirm) deleteSelectedItem();
  }

  const openColorPicker = () => {
    document.getElementById('roleColorInput')?.click();
  }

  return (
    <div onClick={(event) => event.stopPropagation()} className={classes.fillSpace}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.roleTitle}>
            <span className={(isFolder) ? `icon-folder ${classes.folderIcon}` : `icon-role ${classes.roleIcon}`}></span>
            <span className={classes.roleLabel}>
              <span>{selectedItem.item.name ?? (isFolder ? 'Folder Name' : 'Role Name')}</span>
              {noSelection() && <span className={classes.noSelection}>{` (Select Role or Folder to edit)`}</span>}
            </span>
          </div>
          <div className={classes.buttonsContainer}>
            {deletePermission &&
              <button onClick={(event) => { event.stopPropagation(); setOpenDelete(true) }} className={classes.headerButton}>
                <span className='icon-delete'></span>
              </button>
            }
            {editPermission &&
              <button onClick={saveChanges} className={classes.headerButton}>
                <span className='icon-save'></span>
                Save
              </button>
            }
          </div>
        </div>
        {!isFolder && <div className={`${classes.typeRadioGroup} ${editPermission ? '' : classes.disabled}`}>
          {roleOptions.map((option, index) => {
            return (
              <div onClick={(event) => { if (isOptionAllowed(option)) roleOptionChange(event, option) }} className={`${classes.radioButton} ${(option.selected) && classes.selectedRadioOption} ${!isOptionAllowed(option) ? classes.disabled : ''}`} key={index}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" width="20px" height="20px">
                  <circle cx="10" cy="10" r="9" fill="#ffffff" stroke={option.selected ? '#00ca9a' : '#cccccc'} strokeWidth="1"></circle>
                  <circle cx="10" cy="10" r="6" fill={option.selected ? '#00ca9a' : '#ffffff'}></circle>
                </svg>
                <div className={classes.radioButtonLabel}>{option.name}</div>
              </div>
            );
          })}
        </div>}
        <div className={classes.singleRowFields}>
          <div className={classes.field}>
            <div className={classes.nameLabel}>{(isFolder) ? 'Folder Name' : 'Role Name'}</div>
            <input
              className={classes.nameInput}
              value={nameInput}
              onChange={(event) => setNameInput(event.target.value)}
              placeholder={(isFolder) ? 'Enter folder name...' : 'Enter role name...'}
              disabled={!editPermission}
            />
          </div>
          {!isFolder && <div className={classes.colorField}>
            <span className={classes.nameLabel}>Role Color</span>
            <span className={`${classes.colorSquare} ${!editPermission ? classes.disabledPicker : ''}`} style={{ backgroundColor: colorInput }} onClick={openColorPicker}></span>
            <input
              id="roleColorInput"
              value={colorInput}
              className={classes.colorInput}
              onChange={(event) => setColorInput(event.target.value)}
              disabled={!editPermission}
              type='color'
              style={{ position: 'absolute', visibility: 'hidden', zIndex: -1 }}
            />
          </div>}
        </div>
        {!isFolder && <div className={classes.field}>
          <div className={classes.nameLabel}>{(isFolder) ? 'Folder Description' : 'Role Description'}</div>
          <textarea
            className={classes.descriptionInput}
            value={descriptionInput}
            onChange={(event) => setDescriptionInput(event.target.value)}
            placeholder={(isFolder === 'folder') ? 'Enter folder description...' : 'Enter role description...'}
            disabled={!editPermission}
          />
        </div>}

      </div>
      <ConfirmDelete open={openDelete} onClose={closeDelete} message={'Are you sure you want to delete ' + (isFolder ? 'folder "' : 'role "') + selectedItem.item.name + '"'} />
    </div>
  );
}

export default RoleInfo;