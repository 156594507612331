import React, { useRef, useState } from "react";
import classes from "./ForgotPassword.module.scss";
import forgotPassImg from "../../assets/images/forgot_password.png";
import zeusLogo from "../../assets/images/zeus_logo.png";
import { sendEmailForgotPassword } from "../services/userRegisterService";
import InformationDialog from "../UI/InformationDialog";

const ForgotPassword = () => {
  const emailRef = useRef(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [message, setMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = emailRef.current.value
    const response = await sendEmailForgotPassword(email);
    if (response.responseCode === 200) {
      setMessage("An email message with a link to change your password has been sent to your box");
      setInfoDialogOpen(true);
    } else {
      setMessage(`Unfortunately, the email address '${email}' you provided is not recognized in our database. Please verify its accuracy and try again. If you continue to experience issues, feel free to contact our support team for assistance.`);
      setInfoDialogOpen(true);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <span className={classes.title}>Recover Password</span>
        <img src={forgotPassImg} alt="forgotpassword" />
        <span className={classes.info}>
          To recover your password, we need you to provide us with your email
          address associated with your account. Once you enter your email, we
          will send you the necessary instructions to reset your password.
        </span>
      </div>
      <div className={classes.loginContainer}>
        <img src={zeusLogo} alt="zeusLogo" />
        
        <form className={classes.LoginForm} onSubmit={(e) => handleSubmit(e)}>
        <span className={classes.emailText}>Enter your register email address</span>
          <input
            ref={emailRef}
            id="emailInput"
            className={`${classes.inputContainer} ${classes.userAdminIcon}`}
            type="email"
            placeholder="Email"
            required
          />
          <button
            type="submit"
            id="submitButton"
            className={`${classes.buttons} ${classes.submitBtn}`}
          >
            Recover Password
          </button>
        </form>
      </div>
      {infoDialogOpen && 
        <InformationDialog
          message={message}
          onCancel={() => setInfoDialogOpen(false)}
        />}
    </div>
  );
};

export default ForgotPassword;