import React, { useState, useEffect } from "react";
import classes from "./ShiftPatternContainer.module.scss";
import { ShiftPattern } from "./ShiftPattern";
import Header from "../standard/Header";
import MainTopBar from "../standard/MainTopBar";
import { checkAccess } from "../services/userRegisterService";

const ShiftPatternContainer = () => {
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(()=>{
    async function checkPageAccess() {
      let access = await checkAccess('pattern', true);
      setHasAccess(access);
      if(!access) window.location.href = "/home";
    }
    checkPageAccess();
  }, []);

  return ( hasAccess &&
    <div className={classes.headerMainContainer}>
      <div className={classes.headerTopNoMarginBottom}>
        <ShiftPattern />
      </div>
      <Header
        title={"Shift Pattern Creation"}
        showPublishButton={false}
        showRole={true}
        showCalendarState={false}
      />
      <MainTopBar />
    </div>
  );
};

export default ShiftPatternContainer;
