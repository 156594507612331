import React, { useState, useEffect } from "react";
import Header from "../standard/Header";
import classes from "./adminUsers.module.scss";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SwitchButton from "../UI/switchButton";
import { setLoading } from "../../features/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  saveUsers,
  getEmployees,
  getCountries,
  getStates,
  getCities,
  getRoles,
} from "../services/userAdministrationService";
import RoleUser from "./userRole";
import InformationDialog from "../UI/InformationDialog";
import {
  resourcePermissions,
  checkPermission,
  fetchResourcePermissions,
} from "../../features/resourcePepermissionsSlice";
import DeleteConfirmationDialog from "../UI/DeleteConfirmationDialog";
import { checkAccess } from "../services/userRegisterService";
import { getContrastColor, testSkills } from "../../utils/utils";
import { showToast } from "../../features/toastNotificationSlice";
import UserAvatar from "../UI/UserAvatar";
import FormSelect from "../UI/FormSelect";
import useCountryCodes from "../hooks/useCountryCodes";
import UserInformation from "../Users/UserInformation";
import { useCallback } from "react";
import useFetchAirCrafts from "../hooks/useFetchAirCrafts";
import { allLocations } from "../../features/currentDataSlice";

const UserAdministration = () => {
  const dispatch = useDispatch();
  const loadedLocations = useSelector(allLocations);

  //Fetch resource Permissions
  const currentResourcePermissions = useSelector(
    resourcePermissions("user-administration")
  );

  useEffect(() => {
    dispatch(fetchResourcePermissions("user-administration"));
  }, [dispatch]);

  // change password permission
  const [passwordPermission, setPasswordPermission] = useState(false);
  useEffect(() => {
    (async function () {
      let permission = await checkAccess('set-user-password');
      setPasswordPermission(permission);
    })()
  }, [])

  // Bring users data from service
  const [users, setUsers] = useState([]);

  const [usersBackup, setUsersBackup] = useState([]);

  const [location, setLocation] = useState('');

  //roles to assign
  const [rolesToAssign, setRolesToAssign] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const response = await getRoles();
        if (response) {
          setRolesToAssign(response);
        }
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [setRolesToAssign])

  useEffect(() => {
    async function fetchUsers() {
      try {
        dispatch(setLoading(true));
        const fetchedUsers = await getUsers();
        if (Array.isArray(fetchedUsers)) {
          setUsers(fetchedUsers);
          setUsersBackup(fetchedUsers);
          dispatch(setLoading(false));
        } else {
          console.error("Invalid users data:", fetchedUsers);
          dispatch(setLoading(false));
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        dispatch(setLoading(false));
      }
    }
    fetchUsers();
  }, [dispatch]);
  // Filter users with search bar
  const [searchUser, setSearchUser] = useState("");
  const filteredUsers = users.filter((user) => {
    const fullName = `${user.name} ${user.lastName || ""}`.toLowerCase();
    const email = user.email.toLowerCase();
    const searchQuery = searchUser.toLowerCase();
    const searchWords = searchQuery.split(" ");
    let code = user.employee?.relationCode.toLowerCase() ?? '';
    return (
      searchWords.every((word) => fullName.includes(word)) ||
      email.includes(searchQuery)
      || code.includes(searchQuery)

    );
  });
  // Bring employees data from service
  const [employees, setEmployees] = useState([]);

  const fetchEmployees = useCallback(() => {
    (async function () {
      try {
        const fetchedEmployees = await getEmployees();
        if (Array.isArray(fetchedEmployees)) {
          setEmployees(fetchedEmployees);
        } else {
          console.error("Invalid employees data:", fetchedEmployees);
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    })();
  }, [setEmployees]);

  useEffect(() => {
    fetchEmployees();
  }, []);
  // Bring countries data from service
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    async function fetchCountries() {
      try {
        const fetchedCountries = await getCountries();
        if (Array.isArray(fetchedCountries)) {
          setCountries(fetchedCountries);
        } else {
          console.error("Invalid countries data:", fetchedCountries);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }
    fetchCountries();
  }, []);

  // Bring states data from service taking into account the selected country
  const [states, setStates] = useState([]);
  async function fetchStates(selectedCountry) {
    try {
      const fetchStates = await getStates(selectedCountry);
      if (Array.isArray(fetchStates)) {
        setStates(fetchStates);
      } else {
        console.error("Invalid states data:", fetchStates);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  }
  // Bring cities data from service taking into account the selected state
  const [cities, setCities] = useState([]);
  async function fetchCities(selectedState) {
    try {
      const fetchCities = await getCities(selectedState);
      if (Array.isArray(fetchCities)) {
        setCities(fetchCities);
      } else {
        console.error("Invalid cities data:", fetchCities);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  }
  // Click users card to show the data in the inputs, highlight each border card and change the button and header info.
  const [selectedUserId, setselectedUserId] = useState(null);
  const [editIdValue, setEditId] = useState(null);

  const [selectedEmployee, setSelectedEmployee] = useState();

  function getLocationName(locationId) {
    let foundLoc = loadedLocations.find(loc => loc.id === locationId);
    if (!foundLoc) {
      return '';
    }
    return `${foundLoc.name} (${foundLoc.locationCode})`
  }

  function getEmployeelocation(employeeId) {
    let foundEmployee = employees.find(emp => emp.id === employeeId);
    return (getLocationName(foundEmployee?.locationId))
  }

  const handleEditUser = (user) => {
    if (user) {
      setSelectedEmployee(user);
      const editEmployee = user.employee?.id ?? null;
      if (editEmployee) {
        setLinkEmployee(true);
        setEmployee(editEmployee);
        setLocation(getLocationName(user.employee?.locationId ?? -1));
      } else {
        setLinkEmployee(false);
        setEmployee(-1);
        setLocation('')
      }
      setName(user.name ?? '');
      setLastName(user.lastName ?? '');
      setAddress(user.address ?? '');
      setCountry(user.country ?? '');
      if (user.country) fetchStates(user.country);
      setState(user.state ?? '');
      if (user.state) fetchCities(user.state);
      setCity(user.city);
      setPostalCode(user.postalCode ?? '');
      setPhoneIndicative(user.phoneIndicative ?? '');
      setPhoneNumber(user.phoneNumber ?? '');
      setEmail(user.email ?? '');
      setIsActive(user.active ?? '');
      setselectedUserId(user.id);
      setEditId(user.id);
      setPassword('');
    } else {
    }
  };

  // Show enabled or disabled toggle button for linking an employee with their respective background.
  const [linkEmployee, setLinkEmployee] = React.useState(false);

  const toggleButton2 = (newValue) => {
    setLinkEmployee(newValue);
    if (!newValue) {
      setEmployee(-1);
      setLocation('');
    }
  };

  // Create User Button, clean all inputs and change button and header info.
  const handleCreateUser = () => {
    setselectedUserId(null);
    setEditId(null);
    setLinkEmployee(false);
    setEmployee(-1);
    setLocation('');
    setName("");
    setLastName("");
    setAddress("");
    setCountry("");
    setState("");
    setCity("");
    setPostalCode("");
    setEmail("");
    setPhoneIndicative("");
    setPhoneNumber("");
    setIsActive(false);
    setSelectedEmployee({ role: [] })
  };

  // States and events for each input of the right panel.
  const [employee, setEmployee] = useState(-1);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedEmployeeDropdownIndex, setSelectedEmployeeDropdownIndex] =
    useState(0);
  const handleChangeEmployee = (selectedEmployeeId) => {
    setEmployee(selectedEmployeeId);
    if (selectedEmployeeId === -1) {
      setLinkEmployee(false);
      if (!selectedUserId || !editIdValue) {
        handleCreateUser();
      }
    }
    if (name && lastName) {
      if (selectedEmployeeId !== -1) {
        setShowConfirmationDialog(true);
      }
    } else {
      fillDataUserHandler(selectedEmployeeId);
    }
  };

  const onConfirmInfoDialogHandler = (employeeId) => {
    fillDataUserHandler(employeeId);
    setShowConfirmationDialog(false);
  }

  const onCancelInfoDialogHandler = () => {
    setShowConfirmationDialog(false);
  }

  const fillDataUserHandler = (employeeId) => {
    const user = employees.find(emp => emp.id === employeeId);
    if (user) {
      setName(user.name);
      setLastName(user.lastName);
      setAddress("");
      setCountry("");
      setState("");
      setCity("");
      setPostalCode("");
      setEmail(user.email);
      setPhoneIndicative("");
      setPhoneNumber("");
      setLocation(getLocationName(user?.locationId ?? -1))
    } else {
      setselectedUserId(null);
      setEditId(null);
      setLinkEmployee(false);
      setEmployee("");
      setLocation("");
      setName("");
      setLastName("");
      setAddress("");
      setCountry("");
      setState("");
      setCity("");
      setPostalCode("");
      setEmail("");
      setPhoneIndicative("");
      setPhoneNumber("");
      setIsActive(false);
    }
  }

  const [name, setName] = React.useState("");
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const [lastName, setLastName] = React.useState("");
  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };

  const [address, setAddress] = React.useState("");
  const handleChangeAdress = (event) => {
    setAddress(event.target.value);
  };

  const [country, setCountry] = React.useState("");
  const handleChangeCountry = (event) => {
    const selectedCountry = event.target.value;
    if (selectedCountry === "") {
      setState("");
      setCity("");
    }
    setCountry(event.target.value);
    let country = countries.find(c => c.id === event.target.value);
    if (country) {
      let phoneCode = countryCodes.find(c => c.name.toLowerCase() === country.name?.toLowerCase())?.phoneCode
      if (phoneCode) setPhoneIndicative(phoneCode);
    }
    fetchStates(selectedCountry);
  };

  const [state, setState] = React.useState("");
  const handleChangeState = (event) => {
    const selectedState = event.target.value;
    if (selectedState === "") {
      setCity("");
    }
    setState(event.target.value);
    fetchCities(selectedState);
  };

  const [city, setCity] = React.useState("");
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };

  const [postalCode, setPostalCode] = React.useState("");
  const handleChangePostalCode = (event) => {
    setPostalCode(event.target.value);
  };

  const [email, setEmail] = React.useState("");
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = React.useState("");
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  }

  const [phoneIndicative, setPhoneIndicative] = React.useState("");
  const countryCodes = useCountryCodes();
  const handleChangePhoneIndicative = (newValue) => {
    setPhoneIndicative(newValue);
  };

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value.replace(/\D/g, ''));
  };

  // Show enabled or disabled toggle button for active users with their respective background.
  const [isActive, setIsActive] = React.useState(false);
  const toggleButton = (newValue) => {
    setIsActive(newValue);
  };

  const [userRole, setUserRole] = useState([]);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const addRolesToUser = (roles) => {
    const formatRoles = [];
    if (roles.length > 0) {
      roles.forEach((el, index) => {
        formatRoles.push({
          priority: index + 1,
          role: el,
        });
      });
    } else {
    }
    setUserRole(formatRoles);
  };

  const closeInfoDialog = () => {
    setShowInfoDialog(false);
  };

  const handleSave = async () => {
    let userData = {
      name: name,
      lastName: lastName,
      address: address,
      country: country,
      state: state,
      city: city,
      postalCode: postalCode,
      email: email,
      phoneIndicative: phoneIndicative,
      phoneNumber: phoneNumber,
      active: isActive,
      employee: employee === -1 ? null : { id: employee },
      roles: userRole,
    };
    if (password?.length > 0) {
      userData = { ...userData, password: password }
    }
    if (editIdValue !== null) {
      userData.id = editIdValue;
    }
    try {
      let response = await saveUsers(userData);
      if (response !== null) {
        if (response.responseCode === 200) {
          setNewUser(response.load.id);
          dispatch(showToast({ type: 'success', message: userData.id ? 'User successfully updated' : 'User created successfully' }));
        } else {
          dispatch(showToast({ type: 'warning', message: response.message ?? 'Something went wrong at user creation', duration: 6000 }))
        }
      } else {
        dispatch(showToast({ type: 'warning', message: 'Something went wrong at user creation' }))
        throw Error("user update failed");
      }
      fetchEmployees();
      // Refresh the filtered users
      const fetchedUsers = await getUsers();
      if (Array.isArray(fetchedUsers)) {
        setUsers(fetchedUsers);
        setUsersBackup(fetchedUsers);
      } else {
        console.error("Invalid users data:", fetchedUsers);
      }
      handleEditUser({ ...response.load, roles: response.load.roles.map(roleItem => ({ ...roleItem, role: rolesToAssign.find(r => r.id === roleItem.role.id) })) });
    } catch (error) {
      console.log("Error:", error);
      let previousUser = users.find(usr => usr.id === selectedUserId);
      if (previousUser) handleEditUser(previousUser);
    }
  };

  const inputsPermission =
    (editIdValue != null &&
      checkPermission(currentResourcePermissions, "edit-user")) ||
    (editIdValue == null &&
      checkPermission(currentResourcePermissions, "create-user"));

  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    async function checkPageAccess() {
      let access = await checkAccess('user-administration', true);
      setHasAccess(access);
      if (!access) window.location.href = "/home";
    }
    checkPageAccess();
  }, []);

  useEffect(() => {
    setUserRole(selectedEmployee?.roles ?? []);
  }, [selectedEmployee])

  // auto scroll on create logic
  const [newUser, setNewUser] = useState(-1);

  useEffect(() => {
    if (newUser !== -1) {
      let userToScroll = document.getElementById(`userInfoCard${newUser}`);
      if (userToScroll) {
        userToScroll.scrollIntoView({ block: "center", behavior: "smooth" });
        setNewUser(-1);
      }
    }
  }, [users, newUser]);

  useFetchAirCrafts();

  return (hasAccess &&
    <>
      <div className={classes.window}>
        <Header
          title={"User Administration"}
          showPublishButton={false}
          showRole={true}
          showCalendarState={false}
        />
        <div className={classes.containers}>
          <div className={classes.leftContainer}>
            <div className={classes.leftContainerHeader}>
              <div className={classes.searchInput}>
                <input
                  placeholder="Search"
                  className={`${classes.inputUser}`}
                  value={searchUser}
                  onChange={(e) => {
                    setSearchUser(e.target.value);
                  }}
                ></input>
                <span className={`icon-search ${classes.searchIcon}`}></span>
              </div>
              {checkPermission(currentResourcePermissions, "create-user") && (
                <button
                  className={classes.newUserButton}
                  onClick={handleCreateUser}
                >
                  <span className={`icon-role-plus ${classes.roleIcon}`}></span>
                  <div>New User</div>
                </button>
              )}
            </div>
            <div className={classes.cardsContainer}>
              {filteredUsers.length === 0 ? (
                <div className={classes.emptyUsers}>
                  There are no users available.
                </div>
              ) : (
                filteredUsers.map((item, index) => (
                  <div
                    key={index}
                    className={`${classes.userCard} ${selectedUserId === item.id ? classes.selected : ""
                      }`}
                    onClick={() => handleEditUser(item)}
                    id={`userInfoCard${item.id}`}
                  >
                    <div className={classes.leftCardInfo}>
                      <UserAvatar
                        employee={{
                          ...item, roleColor: item?.employee?.roleColor,
                          skills: item?.employee?.skills ?? [],
                          userName: `${item?.name.replaceAll(" ", '')} ${item?.lastName ?? ''}`
                        }}
                        index={index}
                        hideSkills={false}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.topcardInfo}>
                          <span className={classes.userName}>
                            {item.name +
                              " " +
                              (item.lastName ? item.lastName : "")}
                          </span>
                          <span className={classes.userNumber}>{item?.employee?.relationCode ?? ''}</span>
                          <span className={classes.userId}>
                            {item?.employee?.license ? ` - ${item.employee.license}` : ''}
                          </span>
                        </div>
                        <div className={classes.bottomCardInfo}>
                          <div className={classes.bottomCardInfo}>
                            <span
                              className={`icon-email ${classes.emailIcon}`}
                            ></span>
                            <div className={classes.userEmailAndPhone}>
                              {item.email}
                            </div>
                          </div>

                          {item.phoneIndicative && item.phoneNumber && (
                            <div className={classes.bottomCardInfo}>
                              <span
                                className={`icon-phone ${classes.phoneIcon}`}
                              ></span>
                              <div className={classes.userEmailAndPhone}>
                                {item.phoneIndicative + " " + item.phoneNumber}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className={classes.rightContainer}>
            <div className={classes.rightContainerHeader}>
              <div className={classes.headerInfo}>
                {editIdValue != null ? (
                  <>
                    <span className={`icon-draft ${classes.editIcon}`}></span>
                    <div className={classes.editText}>
                      Edit User Information
                    </div>
                  </>
                ) : (
                  <div className={classes.editText}>Create New User</div>
                )}
              </div>
              <div className={classes.headerInfoBtn}>
                {checkPermission(
                  currentResourcePermissions,
                  "assign-roles"
                ) && (
                    <RoleUser
                      addRolesToUser={addRolesToUser}
                      selectedEmployee={selectedEmployee}
                      assignedRoles={userRole}
                      roleOptions={rolesToAssign}
                      roleUsers={usersBackup}
                    />
                  )}
                {inputsPermission && (
                  <button
                    className={`${classes.roleAndSaveButton} ${!name || !lastName || !isEmailValid
                      ? classes.disabled
                      : ""
                      }`}
                    onClick={handleSave}
                    disabled={!name || !lastName || !email}
                  >
                    {editIdValue != null ? "Save" : "Create"}
                  </button>
                )}
              </div>
            </div>
            <div
              className={`${classes.linkToEmployeeContainer} ${linkEmployee ? classes.active : classes.inactive
                }`}
            >
              <div className={classes.linkToEmployeeButton}>
                <div className={classes.linkEmployeeText}>Link to employee</div>
                <SwitchButton
                  defaultChecked={linkEmployee}
                  onChange={toggleButton2}
                  disabled={!inputsPermission}
                />
              </div>
              <div className={classes.employeeSelectInfo}>
                <div className={classes.selectEmployeeContainer}>
                  <div className={classes.selectEmployeeText}>
                    Select Employee
                  </div>
                  <FormSelect
                    options={employees}
                    optionLabel={(option) => <UserInformation user={{ ...option, userName: option.employeeName }} size="small" showSkillsUnderShortName={false} />}
                    optionValue={(option) => option.id}
                    value={employee}
                    handleChange={handleChangeEmployee}
                    sortBy={['name', 'lastName']}
                    searchBy={['name', 'lastName', 'name&lastName']}
                    disabled={!linkEmployee || !inputsPermission}
                    noneValue={-1}
                    askConfirmation={{
                      when: name && lastName,
                      message: "Do you want to set new information for user?",
                      onConfirm: onConfirmInfoDialogHandler,
                      onCancel: onCancelInfoDialogHandler
                    }}
                  />
                </div>
                <span className={location?.length > 0 ? classes.show : classes.hide} >Location: {`${location}`}</span>
              </div>
            </div>
            <div>
              {/* Name and last name inputs */}
              <div className={classes.inputsLabels}>
                <div className={classes.label}>
                  {" "}
                  Name <span className={classes.mandatory}>*</span>
                </div>
                <div className={classes.label}>
                  Last Name <span className={classes.mandatory}>*</span>
                </div>
              </div>
              <div className={classes.inputsContainer}>
                <input
                  className={classes.input2}
                  value={name}
                  onChange={handleChangeName}
                  disabled={!inputsPermission}
                ></input>
                <input
                  className={classes.input2}
                  value={lastName}
                  onChange={handleChangeLastName}
                  disabled={!inputsPermission}
                ></input>
              </div>
              {/* Address input*/}
              <div className={classes.inputsLabels}>
                <div className={classes.label}>Address</div>
              </div>
              <div className={classes.inputsContainer}>
                <input
                  className={classes.input2}
                  value={address}
                  onChange={handleChangeAdress}
                  disabled={!inputsPermission}
                ></input>
              </div>
              {/* Country and state/province inputs*/}
              <div className={classes.inputsLabels}>
                <div className={classes.label}>Country</div>
                <div className={classes.label}>State/Province</div>
              </div>
              <div className={classes.inputsContainer}>
                <Select
                  value={country}
                  onChange={handleChangeCountry}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    padding: "8px",
                    height: "3em",
                    borderRadius: "7px",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                  disabled={!inputsPermission}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countries.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  disabled={!inputsPermission}
                  value={state}
                  onChange={handleChangeState}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    padding: "8px",
                    height: "3em",
                    borderRadius: "7px",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {states.map((states) => (
                    <MenuItem key={states.id} value={states.id}>
                      {states.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {/* City, postal code and email inputs*/}
              <div className={classes.fourInputsRow}>
                <div className={classes.field}>
                  <div className={classes.label}>City</div>
                  <Select
                    disabled={!inputsPermission}
                    value={city}
                    onChange={handleChangeCity}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      padding: "8px",
                      height: "3em",
                      borderRadius: "7px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {cities.map((cities) => (
                      <MenuItem key={cities.id} value={cities.id}>
                        {cities.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={classes.field}>
                  <div className={classes.label}>Postal Code</div>
                  <input
                    className={classes.input1}
                    value={postalCode}
                    onChange={handleChangePostalCode}
                    disabled={!inputsPermission}
                  ></input>
                </div>
                <div className={classes.field}>
                  <div className={classes.label}>Email<span className={classes.mandatory}>*</span>
                  </div>
                  <input
                    className={classes.input1}
                    value={email}
                    onChange={handleChangeEmail}
                    disabled={!inputsPermission}
                  ></input>
                </div>

              </div>
              {/* Phone indicative and Phone Numbers inputs*/}
              <div className={classes.inputsLabels}>
                <div className={classes.threeInputs}>
                  <div className={classes.label}>Phone Indicative</div>
                  <div className={classes.label}>Phone Number</div>
                </div>
                <div className={classes.label}></div>
              </div>
              <div className={classes.inputsContainer}>
                <div className={classes.threeInputs}>
                  <FormSelect
                    options={countryCodes}
                    optionLabel={(option) => `${option.phoneCode} (${option.name})`}
                    optionValue={(option) => option.phoneCode}
                    selectedOptionLabel={(option) => option.phoneCode}
                    value={phoneIndicative}
                    handleChange={handleChangePhoneIndicative}
                    sortBy={['name']}
                    searchBy={['name', 'phoneCode']}
                    disabled={!inputsPermission}
                  />
                  <input
                    className={classes.input3}
                    value={phoneNumber}
                    onChange={handleChangePhoneNumber}
                    disabled={!inputsPermission}
                  ></input>
                </div>
                <div
                  className={`${classes.activeContainer} ${isActive ? classes.active : classes.inactive
                    }`}
                >
                  <div>Active</div>
                  <SwitchButton
                    defaultChecked={isActive}
                    onChange={toggleButton}
                    disabled={!inputsPermission}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInfoDialog && (
        <InformationDialog
          message={"please add at least one role to user"}
          onCancel={closeInfoDialog}
        />
      )}
    </>
  );
};



export default UserAdministration;
