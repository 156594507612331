import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./ApplyPatternModal.module.scss";
import Modal from "../../components/UI/Modal";
import { RangeDateInputCalendar } from "../calendar/RangeDateInputCalendar";
import { EmployeeList } from "../employee/EmployeeList";
import userRegisterContext from "../store/user-register-context";
import {
  setInitialStateEmployees,
  selectedEmployees,
  selectedPatternState,
  applyShiftPattern
} from "../../features/shiftPatternSlice";
import { selectedLocationState } from "../../features/currentDataSlice";
import AppliedPatterns from "./AppliedPatterns";
import InitialAndFinalDate from "../calendar/InitialAndFinalDate";
import { Popover } from "@mui/material";
import Menu from "../standard/Menu";
import SearchBar from "../UI/SearchBar";

const ApplyPatternModal = ({ onClose }) => {
  const [startDate, setStartDate] = useState({ date: null, formattedDate: "" });
  const [endDate, setEndDate] = useState({ date: null, formattedDate: "" });
  const [resetDates, setResetDates] = useState(false);
  const [isListVisible, setListVisible] = useState(false);
  const userRegisterCtx = useContext(userRegisterContext);
  const selectedEmployess = useSelector(selectedEmployees);
  const selectedPattern = useSelector(selectedPatternState);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const employeeListRef = useRef(null);
  const selectedLocation = useSelector(selectedLocationState);

  const handleInputFocus = () => {
    setListVisible(true); // Show the list when input is focused
  };

  const handleInputBlur = (event) => {
    // Check if the click happened outside of the EmployeeList component
    if (
      employeeListRef.current &&
      !employeeListRef.current.contains(event.target) &&
      inputRef.current !== event.target
    ) {
      setListVisible(false); // Hide the list when input loses focus and click is outside the component
    }
  };

  useEffect(() => {
    dispatch(setInitialStateEmployees(userRegisterCtx.currentWeekData));

    // Attach event listener to the document for detecting clicks
    document.addEventListener("click", handleInputBlur);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleInputBlur);
    };
  }, []);

  const closeModalHandler = () => {
    onClose();
  };

  const initialDateHandler = (date) => {
    setStartDate(date);
  }

  const endDateHandler = (date) => {
    setEndDate(date);
  }


  const resetFields = () => {
    dispatch(setInitialStateEmployees(userRegisterCtx.currentWeekData));
    setResetDates(true);
  }

  useEffect(() => {
    if (resetDates === true) {
      setResetDates(false);
    }
  }, [resetDates, setResetDates])

  const applyPatternHandler = () => {
    const patternData = {
      finalDate: endDate.date,
      initialDate: startDate.date,
      employees: selectedEmployess,
      shiftPatternId: selectedPattern.id,
      locationId: selectedLocation.id
    }
    dispatch(applyShiftPattern(patternData));
    resetFields();
  }

  const enableApply = useCallback(() => {
    return startDate.date !== null && endDate.date !== null && selectedEmployess.length > 0
  }, [startDate, endDate, selectedEmployess]);

  const onSaveDateHandler = (day, type) => {
    if (type === 0) setStartDate(day);
    if (type === 1) setEndDate(day);
  };

  const [search, setSearch] = useState('');
  const searchEmp = useCallback((value) => {
    setSearch(value.toLowerCase());
  }, [setSearch]);

  useEffect(() => {
  }, [search])

  const searchMemo = useMemo(
    () => <SearchBar onChange={searchEmp} />,
    [search, searchEmp]
  );

  return (
    <Modal className={"shiftPatternContainer"} onClose={onClose} contentClassName={classes.modalContent}>
      <div className={classes.applyPatternContent}>
        <div className={classes.applyPatternContainer}>
          <div className={classes.header}>
            <span className={classes.title}>Apply Pattern</span>
          </div>
          <div className={classes.leftContainer}>
            <InitialAndFinalDate theme="light" onSaveDateHandler={onSaveDateHandler} resetDates={resetDates} />
            <div className={classes.employeeContainer}>
              <div className={classes.employee}>
                <span className={`icon-users ${classes.usersIcon}`}></span>
                <span className={classes.employeeText}>Employees</span>
              </div>
              <Menu
                buttonElement={searchMemo}
              >
                <div className={classes.employeeList}>
                  <EmployeeList search={search} />
                </div>
              </Menu>
            </div>
            <div className={classes.selectedEmployeeContainer}>
              <span className={`icon-user-shift-pattern ${classes.usersIcon}`}></span>
              <span className={classes.text}>Selected Employees</span>
            </div>
            <div className={classes.employees}>
              <div>
                {selectedEmployess.length > 0 &&
                  selectedEmployess.map((employee) => {
                    return (
                      <span key={employee.id}>
                        {employee.userName}
                      </span>
                    );
                  })}
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <button onClick={applyPatternHandler} disabled={!enableApply()} className={classes.applyPatternBtn}>Apply Pattern</button>
            </div>
          </div>

        </div>
        <div className={classes.appliedPatternsContainer}>
          <AppliedPatterns onClose={closeModalHandler} patternId={selectedPattern.id} />
        </div>
      </div>
    </Modal>
  );
};

export { ApplyPatternModal };