import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";
import { getAllLocations } from "./dropdownService";

export const getToken = () => Cookies.get("token");

export async function fetchGroups(locationId) {
  const token = getToken();
  let params = new URLSearchParams();
  params.append("locationId", locationId);
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/get-employee-groups?${params.toString()}`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseCode === 200) {
      return data.load;
    } else {
      console.log("error", data);
      return null;
    }
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function saveCrew(crew) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/save-employee-group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(crew)
    });
    const data = await response.json();
    if (data.responseCode === 200) {
      return data.load;
    } else {
      console.log("error", data);
      return Promise.reject(data.message);
    }
  } catch (error) {
    console.log(error.message);
    return Promise.reject(error.message);
  }
}

export async function deleteGroup(groupId) {
  const token = getToken();
  let params = new URLSearchParams();
  params.append("groupId", groupId);
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/delete-employee-group?${params.toString()}`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseCode === 200) {
      return {success: true, data:data.load};
    } else {
      console.log("error", data);
      return {success:false, message: data.message};
    }
  } catch (error) {
    console.log(error.message);
    return {success:false, message: "Unable to delete the group. Please try again later."};
  }
}