import React, { useEffect, useState } from "react";
import classes from "./AdminLocations.module.scss";
import Header from "../standard/Header";
import List from "./List";
import FormNewLocation from "./FormNewLocation";
import useLocationForm from "../hooks/useLocationForm";
import { useDispatch, useSelector } from "react-redux";
import {
  allSites,
  allTimeZones,
  setShowErrorPage,
  setSites,
  setTimeZones,
} from "../../features/currentDataSlice";
import { checkAccess, getSites } from "../services/userRegisterService";
import { alphabetSort } from "../../utils/utils";
import useSchedules from "../hooks/useSchedules";

const AdminLocations = () => {
  const {
    selectedLocation,
    filteredData,
    selectLocationHandler,
    addNewLocationHandler,
    deleteLocationHandler,
    handleSearch,
    saveLocationHandler,
    getStates,
    getCities,
    deleteSiteHandler,
  } = useLocationForm();

  const timeZones = useSelector(allTimeZones);
  const [americaTZ, setAmericaTZ] = useState(timeZones);
  const loadedSites = useSelector(allSites);
  const [Allsites, setAllSites] = useState([]);
  const [AllsitesCopy, setAllSitesCopy] = useState([]);
  const dispatch = useDispatch();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(()=>{
    async function checkPageAccess() {
      let access = await checkAccess('admin-locations', true);
      setHasAccess(access);
      if(!access) window.location.href = "/home";
    }
    checkPageAccess();
  }, []);

  const setSitesHandler = () => {
    const updatedSites = loadedSites.map((site) => ({
      ...site,
      isActive: site.isActive ?? false,
      isEditing: false,
    }));
    setAllSites(updatedSites);
    setAllSitesCopy(updatedSites);
  };

  const getSitesHandler = async () => {
    try {
      const sites = await getSites();
      if (sites.responseCode === 200) {
        dispatch(setSites(sites.load));
      } else {
        dispatch(setShowErrorPage(true));
      }
    } catch (error) {
      dispatch(setShowErrorPage(true));
    }
  };

  const [schedules, reloadSchedules] = useSchedules();

  const getTimeZonesHandler = () => {
    const moment = require("moment-timezone");
    const timezones = moment.tz.names();
    const timezoneObj = [];
    timezones.forEach((timezone, index) => {
      timezoneObj.push({
        id: index,
        name: timezone,
      });
    });
    dispatch(setTimeZones(timezoneObj));
  };

  useEffect(() => {
    setSitesHandler();
  }, [loadedSites]);

  useEffect(() => {
    setAmericaTZ(timeZones);
  }, [timeZones]);

  useEffect(() => {
    if (loadedSites.length !== 0) setSitesHandler();
    else {
      getSitesHandler();
    }
    if (timeZones.length === 0) getTimeZonesHandler();
  }, []);

  return (hasAccess &&
    <>
      <div className={classes.body}>
        <Header
          title={"Location Creation"}
          showPublishButton={false}
          showRole={true}
          showCalendarState={false}
        />
        <div className={classes.containers}>
          <div className={classes.leftContainer}>
            <List
              filteredData={alphabetSort({array: filteredData, properties: ['value', 'label']})}
              selectedItem={selectedLocation}
              selectLocationHandler={selectLocationHandler}
              addNewLocationHandler={addNewLocationHandler}
              deleteLocationHandler={deleteLocationHandler}
              handleSearch={handleSearch}
            ></List>
          </div>
          <div className={classes.rightContainer}>
            <FormNewLocation
              location={selectedLocation}
              saveLocationHandler={saveLocationHandler}
              deleteSiteHandler={deleteSiteHandler}
              Allsites={Allsites}
              schedules={schedules}
              reloadSchedules={reloadSchedules}
              setAllSites={setAllSites}
              AllsitesCopy={AllsitesCopy}
              setAllSitesCopy={setAllSitesCopy}
              americaTZ={americaTZ}
            ></FormNewLocation>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLocations;