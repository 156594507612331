import React, { useEffect, useState } from "react";
import classes from "./ShiftPattern.module.scss";
import { ShiftPatternList } from "./ShiftPatternList";
import { CreateNewShift } from "./CreateNewShift";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchShiftPatterns,
	selectedPatternState,
	shiftPatternList,
	clearShiftPatternComponent,
	saveUpdateShiftPattern,
	showDialogState,
	showDialogHandler,
	deleteShiftPatternAction
} from "../../features/shiftPatternSlice";
import { ApplyPatternModal } from "./ApplyPatternModal";
import InformationDialog from "../UI/InformationDialog";
import { resourcePermissions, fetchResourcePermissions, checkPermission } from "../../features/resourcePepermissionsSlice";
import { selectedLocationState } from "../../features/currentDataSlice";

const ShiftPattern = () => {
	const patternsList = useSelector(shiftPatternList);
	const [openNewShift, setOpenNewShift] = useState(false);
	const [showApplyPatternModal, setShowApplyPatternModal] = useState(false);
	const selectedShiftPattern = useSelector(selectedPatternState);
	const showDialog = useSelector(showDialogState)
	const dispatch = useDispatch();
	const currentResourcePermissions = useSelector(resourcePermissions('pattern'));
	const selectedLocation = useSelector(selectedLocationState);

	const openNewShiftHandler = (location) => {
		dispatch(clearShiftPatternComponent(location));
	};

	useEffect(() => {
		if (selectedShiftPattern === null) {
			setOpenNewShift(true)
		} else {
			setOpenNewShift(false)
		};
	}, [selectedShiftPattern])



	const applyPatternToggle = () => {
		setShowApplyPatternModal(!showApplyPatternModal);
	};

	useEffect(() => {
		dispatch(fetchShiftPatterns());
		dispatch(fetchResourcePermissions('pattern'))
	}, [dispatch]);

	const saveNewShiftHandler = (pattern) => {
		dispatch(saveUpdateShiftPattern(pattern));
	}

	const dialogHandler = () => {
		dispatch(showDialogHandler({ state: false, message: "", code: -1 }));
		if (showDialog.code === 200 && showApplyPatternModal) {
		}
	}

	const onDeleteShiftPattern = (patternId) => {
		setOpenNewShift(false);
		dispatch(deleteShiftPatternAction(patternId));
		openNewShiftHandler(selectedLocation);
		// dispatch(clearShiftPatternComponent());
	}

	useEffect(() => {
		openNewShiftHandler(selectedLocation);
	}, [selectedLocation])

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				{/* <div className={classes.newShiftContainer}>
          <span
            className={`icon-user-shift-pattern ${classes.userIcon}`}
          ></span>
          <span className={classes.title}>Shift Patterns</span>
          {checkPermission(currentResourcePermissions, 'create-pattern') && <button onClick={openNewShiftHandler}>+ New Shift Pattern</button>}
        </div>
        {(selectedShiftPattern !== null) && (checkPermission(currentResourcePermissions, 'apply-pattern')) &&
          <button onClick={applyPatternToggle} className={classes.applyBtn}>
            Apply Pattern
          </button>
        } */}

			</div>
			<div className={classes.body}>
				<div className={classes.patternList}>
					<div className={classes.newShiftContainer}>
						<div className={classes.pageTitle}>
							<span
								className={`icon-user-shift-pattern ${classes.userIcon}`}
							></span>
							<span className={classes.title}>Shift Patterns</span>
						</div>
						{checkPermission(currentResourcePermissions, 'create-pattern') && <button onClick={()=>openNewShiftHandler(selectedLocation)}>+ New Shift Pattern</button>}
					</div>
					{patternsList && <ShiftPatternList patterns={patternsList} />}
				</div>
				<div className={classes.newPattern}>
					{(openNewShift === true || selectedShiftPattern !== null) && (
						<CreateNewShift
							selectedShiftPattern={selectedShiftPattern}
							onSave={saveNewShiftHandler}
							onDeleteShiftPattern={onDeleteShiftPattern}
							applyPatternToggle={applyPatternToggle}
						/>
					)}
				</div>
			</div>
			{showApplyPatternModal && (
				<ApplyPatternModal onClose={applyPatternToggle}></ApplyPatternModal>
			)}
			{showDialog.state && (
				<InformationDialog message={showDialog.message} onCancel={dialogHandler} ></InformationDialog>
			)}
		</div>
	);
};

export { ShiftPattern };
