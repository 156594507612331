import React, { useEffect, useState } from 'react';
import classes from './RoleManagement.module.scss';
import Header from '../standard/Header';
import FolderList from './FolderList';
import RoleInfo from './RoleInfo';
import RoleActions from './RoleActions';
import { useDispatch } from "react-redux";
import { fetchResourcePermissions } from "../../features/resourcePepermissionsSlice";
import { checkAccess } from '../services/userRegisterService';
import { clearSelectedItem } from '../../features/rolesSlice';


const RoleManagement = () => {

  const dispatch = useDispatch();
  const clearSelection = (event) => {
    dispatch(clearSelectedItem());
  }
  
  useEffect(() => {
    dispatch(fetchResourcePermissions('role-administration'));
  }, [dispatch]);

  const [hasAccess, setHasAccess] = useState(false);

  useEffect(()=>{
    async function checkPageAccess() {
      let access = await checkAccess('role-administration', true);
      setHasAccess(access);
      if(!access) window.location.href = "/home";
    }
    checkPageAccess();
  }, []);

  return (hasAccess && 
    <div onClick={clearSelection} className={classes.window}>
      <Header title={'Role Creation'} showPublishButton={false} showRole={true} showCalendarState={false} />
      <div className={classes.roleManagement}>
        <div className={classes.folders}>
          <FolderList />
        </div>
        <div className={classes.role} onClick={(event)=>event.stopPropagation()}>
          <RoleInfo />
        </div>
        <div className={classes.actions} onClick={(event)=>event.stopPropagation()}>
          <RoleActions />
        </div>
      </div>
    </div>
  );

}

export default RoleManagement
