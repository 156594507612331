import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./Header.module.scss";
import HamburgerMenu from "./HamburgerMenu";
import { resourcePermissions, checkPermission } from "../../features/resourcePepermissionsSlice";
import { activeUserState } from "../../features/userSlice";
import { useContext } from "react";
import UserRegisterContext from "../store/user-register-context";
import { publishWeek } from "../services/publishService";
import { showToast } from "../../features/toastNotificationSlice";
import { PAGE_IDS } from "../services/global";
import LocationDropDown from "./LocationDropdown";
import { setLoading } from "../../features/loadingSlice";
import { addWeek, dropWeek, selectPendingWeeks } from "../../features/publishSlice";

const Header = (props) => {
  const userRegisterCtx = useContext(UserRegisterContext);
  const dispatch = useDispatch();
  const currentResourcePermissions = useSelector(resourcePermissions('week-view'));
  const activeUser = useSelector(activeUserState)
  const title = props.title ?? 'Calendar Administrator'
  const showPublishButton = props.showPublishButton ?? true;
  const showRole = props.showRole ?? false;
  const showCalendarState = props.showCalendarState ?? true;
  const pendingWeeks = useSelector(selectPendingWeeks);
  const [isWeekPending, setIsWeekPending] = useState(false);

  useEffect(() => {
    let weekStart = userRegisterCtx?.selectedWeek?.startDate?.toISOString()?.split('T')?.[0] ?? 'no-week';
    setIsWeekPending(pendingWeeks.includes(weekStart));
  }, [pendingWeeks, userRegisterCtx]);

  function getHeaderIcon(title) {
    switch (title) {
      case 'Calendar Administrator':
        return 'icon-calendar';
      case 'Role Creation':
        return 'icon-role';
      case 'ZEUS':
        return '';
      case 'error':
        return '';
      case 'Location Creation':
        return 'icon-location';
      case 'Shift Pattern Creation':
        return 'icon-user-shift-pattern';
      case 'Leaves Configuration':
        return 'icon-leave-admin';
      case 'Crew Configuration':
        return 'icon-users'
      default:
        return 'icon-calendar';
    }
  }

  const publishClick = () => {
    (async function () {
      dispatch(addWeek(userRegisterCtx.selectedWeek.startDate?.toISOString()?.split('T')?.[0] ?? 'n'))
      try {
        const response = await publishWeek(userRegisterCtx.selectedWeek.masterId, statusSwap[userRegisterCtx.selectedWeek.status]);
        if (response.success) {
          response.newStatus === "publish" ?
            dispatch(showToast({ type: 'success', message: "Week schedule published successfully", duration: 5000 }))
            :
            dispatch(showToast({ type: 'info', message: "Week schedule was unpublished", duration: 5000 }));
          userRegisterCtx.changeWeek(userRegisterCtx.selectedWeek);
        } else {
          dispatch(showToast({ type: 'warning', message: response.message ?? "Something went wrong during the week's publication.", duration: 'keep-open' }));
        }
      } catch (error) {
        dispatch(showToast({ type: 'warning', message: "Something went wrong during the week's publication." }));
      }
      dispatch(dropWeek(userRegisterCtx.selectedWeek.startDate?.toISOString()?.split('T')?.[0] ?? 'n'))
      // dispatch(setLoading(false));
    })();
  }

  return (<>
    <div id="header" className={classes.headerBody}>
      <div className={classes.headerIcons}>
        <HamburgerMenu></HamburgerMenu>
        <span className="icon-app-logo"></span>
        <span className={getHeaderIcon(title)} />
      </div>
      <div className={classes.mainTitle}>{title !== "error" ? title : ""}</div>

      <div className={classes.userNameContainer}>
        {activeUser.name}
        {showRole &&
          <>
            <span>/</span>
            <span className={classes.highlightText}>Administrator</span>
          </>
        }
        <div className={classes.userIconContainer}>
          <span className="icon-user"></span>
        </div>
      </div>
      {checkPermission(currentResourcePermissions, 'publish') && showCalendarState && <div className={classes.state}>
        <span className={classes.sateTitle}>State:</span>
        <span className="icon-draft" />
        <span className={classes.stateInfo}>({statusLabel[userRegisterCtx?.selectedWeek?.status] ?? '...'})</span>
      </div>}
      {checkPermission(currentResourcePermissions, 'publish') && showPublishButton &&
        <button className={`${classes.publishBtn} ${classes[userRegisterCtx?.selectedWeek?.status]}`} onClick={publishClick} disabled={isWeekPending}>
          <span className="icon-flag" />
          {buttonLabel[userRegisterCtx?.selectedWeek?.status] ?? 'Publish Schedule'}
        </button>}
    </div>
    {isWeekPending && <div className={classes.publishBanner}><span>{progressLabel[userRegisterCtx?.selectedWeek?.status] ?? 'Publishing in progress...'}</span> <i className="icon-refresh" /></div>}
  </>
  );
};

const progressLabel = {
  draft: "Publishing in progress…",
  publish: "Unpublishing in progress…"
}

const statusSwap = {
  draft: "publish",
  publish: "draft"
}

const buttonLabel = {
  draft: "Publish Schedule",
  publish: "Unpublish"
}

const statusLabel = {
  draft: 'draft',
  publish: 'published'
}

export default Header;
