import React from "react";
import classes from "./UserRoleCard.module.scss";
import { useDrag } from "react-dnd";
import AssignedUsers from "./AssignedUsers";
import { getRoleLabel } from "../../utils/utils";

const UserRoleCard = ({ role, isDroppedItem, removeRoleItem, roleUsers }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "UserRoleCard", // Specify the type for the drag item
    item: role, // The data that will be available in the drop function when this item is dropped
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const removeItem = () => {
    removeRoleItem(role)
  }

  return (
    <div ref={drag} className={classes.roleCard}>
      {!isDroppedItem && <span className={`icon-move ${classes.iconMove}`}></span>}
      <div className={classes.cardInfo}>
        <span className={classes.title}>{role.name}</span>
        <span className={classes.info}>
          <span className={classes.type}>Role Type:</span>
          <span>{getRoleLabel(role)}</span>
        </span>
        {role.resources?.length > 0 && (
          <span className={classes.info}>
            <span className={classes.resourcetitle}>Resources:</span>
            {role.resources.map((resource, i) => (
              <span className={classes.resource} key={i}>{resource.name},</span>
            ))}
          </span>
        )}
        {role.roleLocations?.length > 0 && role.type && (
          <span className={classes.info}>
            <span className={classes.resourcetitle}>Locations:</span>
            <span className={classes.resource}>{locationsShown(role)}</span>
          </span>
        )}
      </div>
      <AssignedUsers users={roleUsers} role={role} />
      {isDroppedItem && (
        <button onClick={removeItem} className={classes.deleteBtn}>
          <span className="icon-delete"></span>
        </button>
      )}
    </div>
  );
};

const locationsShown = (role) => {
  switch (role.type) {
    case "regular_user":
      return role?.roleLocations?.map((location) => location.location?.locationCode ?? '').join(', ') ?? '';
    case "lead":
      return role?.roleLocations?.map((location) => location.location?.locationCode ?? '').join(', ') ?? '';
    case "admin":
      return "All";
    default:
      return "";
  }
}

export default UserRoleCard;
