import Cookies from "js-cookie";

import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";

export const getToken = () => Cookies.get("token");

export async function getAllocatedShifts(locationId, initialDate, finalDate) {
  const token = getToken();
  let params = new URLSearchParams();
  params.append("locationId", locationId);
  params.append("initialDate", initialDate);
  params.append("finalDate", finalDate);
  const url =
    API_BASE_URL + "/get-required-allocated-shifts?" + params.toString();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return null;
  }
}