import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allLocations,
  setShowErrorPage,
  setSites,
  setloadedLocations,
} from "../../features/currentDataSlice";
import {
  deleteLocation,
  deleteSite,
  saveLocation,
} from "../services/locationAdminService";
import { getAllLocations } from "../services/dropdownService";
import { setLoading } from "../../features/loadingSlice";
import { getSites } from "../services/userRegisterService";
import { showToast } from "../../features/toastNotificationSlice";

const useLocationForm = () => {
  const locations = useSelector(allLocations);
  const [loadedLocationshook, setloadedLocationshook] = useState(locations);
  const [selectedLocation, setSelectedLocation] = useState({
    value: "",
    label: "",
    sites: [],
  });
  const [filteredData, setfilteredData] = useState(locations);
  const dispatch = useDispatch();

  const deleteSiteHandler = async (siteId, locationPreviousSites) => {
    try {
      dispatch(setLoading(true));
      const response = await deleteSite(siteId);
      if (response.load) {
        const loadSites = await getSites();
        if (loadSites.load) dispatch(setSites(
          loadSites.load.map(location => ({
            ...location,
            enabled:
              locationPreviousSites.find(previousLocation => previousLocation.id === location.id)?.enabled ?? false
          }))
        ));
        dispatch(setLoading(false));
        return ({ success: true });
      } else {
        dispatch(setLoading(false));
        return ({ success: false });
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error.message);
      return ({ success: false });
    }
  }

  const selectLocationHandler = (item) => {
    setSelectedLocation(item);
  };


  const deleteLocationHandler = async (itemId, deleteItemFromList = false) => {
    if (deleteItemFromList) {
    } else {
      try {
        dispatch(setLoading(true));
        const response = await deleteLocation(itemId);
        if (response.responseCode === 200) {
          const updatedLocations = await getAllLocations();
          if (updatedLocations) {
            dispatch(setloadedLocations(updatedLocations));
          }
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error.message);
      }
    }
  };

  const addNewLocationHandler = () => {
    setSelectedLocation({
      value: "",
      label: "",
      sites: [],
    });
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (value) => {
    setSearchQuery(value.toLowerCase());
  };

  useEffect(() => {
    setfilteredData(locations.filter(location => `${location.value.toLowerCase()} ${location.label.toLowerCase()}`.includes(searchQuery)));
  }, [searchQuery, locations])

  const saveLocationHandler = async (location) => {
    try {
      dispatch(setLoading(true));
      const response = await saveLocation(location);
      if (response.responseCode === 200) {
        const updatedLocations = await getAllLocations();
        if (updatedLocations) {
          dispatch(setloadedLocations(updatedLocations));
        }
        setSelectedLocation(prev => updatedLocations.find(loc => loc.id === response.load?.id) ?? response?.load ?? prev);
        dispatch(showToast({ type: 'success', message: location?.id !== response.load?.id ? 'Location created' : 'Location updated' }));
        dispatch(setLoading(false));
      } else {
        dispatch(showToast({ type: 'warning', message: 'Something went wrong during location update' }));
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ type: 'warning', message: 'Something went wrong during location update' }))
      console.log(error);
    }
  };

  useEffect(() => {
    if (locations.length > 0) {
      setloadedLocationshook(locations);
    }
  }, [locations]);

  useEffect(() => {
    addNewLocationHandler();
  }, []);

  return {
    selectedLocation,
    loadedLocationshook,
    filteredData,
    selectLocationHandler,
    addNewLocationHandler,
    deleteLocationHandler,
    handleSearch,
    saveLocationHandler,
    deleteSiteHandler
  };
};

export default useLocationForm;