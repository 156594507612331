import React, { useEffect, useRef, useState } from "react";
import classes from "./OverflowList.module.scss";
import { Tooltip } from "@mui/material";
import { getContrastColor } from "../../utils/utils";

const OverflowList2 = ({ items, containerId }) => {
 const maxItems = 1;
  useEffect(() => {
  }, [items, containerId]);

  return (
    <div
      className={classes.userList}
    >
      {items.length > 0 ? (
        items.map((item, index) => {
          // const itemRef = React.createRef();
          // itemRefs.current[index] = itemRef;
          const color = item.color ?? item.roleColor ?? "#1a1a1a";
          if (index < maxItems) {
            return (
              <div
                key={index}
                id="userCard"
                className={classes.userCard}
                style={{
                  backgroundColor: color,
                  color: getContrastColor(color),
                }}
                
              >
                {`${item.userName}`}
              </div>
            );
          } else if (index === maxItems) {
            return (
              <Tooltip
                key={index}
                title={
                  <div key={index} className={classes.tooltip}>
                    {items.slice(maxItems).map((user, j) => (
                      <div key={j}>{`${user.userName}`}</div>
                    ))}
                  </div>
                }
                placement="bottom"
                arrow
                PopperProps={{
                  popperOptions: {
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "-10px, 0px"
                      },
                    },
                  },
                }}
              >
                <div
                  id="userCard"
                  className={`${classes.userCard} ${classes.usersOverflow}`}
                >
                  +{items.length - maxItems}
                </div>
              </Tooltip>
            );
          } else {
            return null;
          }
        })
      ) : (
        <div className={`${classes.userCard} ${classes.placeholder}`}>
          This crew is empty
        </div>
      )}
    </div>
  );
};

export default OverflowList2;
