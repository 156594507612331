import React from "react";
import LoadingComponent from "../UI/Loading";
import { useEffect } from "react";
const MsLogin = () => {

  useEffect(()=>{
  },[])

  return(
    <LoadingComponent/>
  );
}

export default MsLogin;