import React from "react";
import classes from './SiteSchedules.module.scss';

const SiteSchedules = ({ schedules, enabledSchedules, overflowThreshold = 2 }) => {
  const siteSchedules = schedules?.filter(schedule => enabledSchedules.has(schedule.id)) ?? [];
  const overflow = siteSchedules.length - overflowThreshold;
  return (
    <div className={classes.siteSchedules}>
      <div className={classes.scheduleIndicator}>
        <i className="icon-check-schedule" />
        <span>Schedule</span>
      </div>
      {siteSchedules.length > 0 ?
        <div className={classes.scheduleList}>
          {siteSchedules?.map((schedule, index) => index < overflowThreshold &&
            <span className={classes.schedulePill} key={index}>{`${schedule.startTime}-${schedule.endTime}`}</span>
          )}
          {overflow > 0 && <span className={classes.overflown}>{`+${siteSchedules.length - overflowThreshold}`}</span>}
        </div>
        :
        <span>A Schedule has not been assigned yet</span>
      }
    </div>
  );
}

export default SiteSchedules;